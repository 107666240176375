@import "../1-tools/variables";

.assess-card {
	width: 100%;
	height: 132px;
	border-radius: 15px;
	box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.2);
	// border: solid 0.5px $dark-green;
	// background-color: $pure-white;
	position: relative;
	display: flex;
	align-items: center;
	margin-top: 50px;
	margin-bottom: 30px;
	padding-left: 16%;
	overflow: hidden;
	background-color: rgba(226, 255, 245, 0.31);
	backdrop-filter: blur(20px);
	border: none;

	&::after {
		content: "";
		position: absolute;
		z-index: -1;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		border-radius: 15px;
		border: 1.5px solid transparent;
		background: radial-gradient(100% 300% at 1.24% 0%, rgba(#f9f9f9, 0.6) 0%, rgba(#fff, 0.6) 100%);
		-webkit-mask: radial-gradient(100% 300% at 1.24% 0%, #f9f9f9 0%, #fff 100%) content-box,
			radial-gradient(100% 300% at 1.24% 0%, #f9f9f9 0%, #5b5d63 100%);
		-webkit-mask-composite: destination-out;
		mask-composite: exclude;
	}

	@include media-breakpoint-down(md) {
		padding-left: 6%;
		// margin-top: -14%;
		height: 120px;
		border: none;
		box-shadow: 0 7px 13px 0 rgba(1, 161, 134, 0.4);
	}

	@include media-breakpoint-down(sm) {
		height: auto;
		padding: 1rem;
		min-height: 130px;
	}
	@media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
		padding-left: 6%;
		// margin-top: -14%;
		box-shadow: 0 7px 13px 0 rgba(1, 161, 134, 0.4);
	}
	@media screen and (max-width: 360px) {
		// margin-top: -20%;
		box-shadow: 0 7px 13px 0 rgba(1, 161, 134, 0.4);
	}

	.vector-assessment-card {
		position: absolute;
		top: -5%;
		left: -3%;
		height: 100%;
		background-color: transparent;
		@include media-breakpoint-down(sm) {
			border-radius: 0px;
			border-top-right-radius: 15px;
			border-bottom-right-radius: 15px;
			left: auto;
			right: 0px;
		}
	}

	.assess-text {
		font-family: $montserrat-bold;
		font-size: $font-size-14;
		color: $lightest-green;
		position: relative;
	}

	.level {
		font-family: $montserrat-bold;
		font-size: $font-size-22;
		margin-top: 0.3rem;
		margin-bottom: 0.3rem;
		color: $pure-white;
		position: relative;
		@media screen and (max-width: 320px) {
			font-size: $font-size-20;
		}
	}
	.questions {
		opacity: 0.9;
		font-family: $opensans-regular;
		font-size: $font-size-14;
		color: $pure-white;
		margin-right: 0.8rem;
		display: flex;
		align-items: center;

		svg {
			height: 1rem;
			margin-right: 0.5rem;
			path {
				fill: $dark-green;
			}
		}
		.review-score-svg {
			height: 1.4rem;
		}

		.review-score-svg-quiz-desktop {
			height: 35%;
		}
		.last-attempt-quiz-score {
			font-family: $montserrat-bold;
			font-size: 17px;
			// color: $pure-black;
		}
	}

	.time {
		opacity: 0.9;
		font-family: $opensans-regular;
		font-size: $font-size-14;
		color: $dark-blue;
		margin-left: 0.8rem;
		display: flex;
		align-items: center;

		svg {
			height: 1rem;
			margin-right: 0.5rem;
			opacity: 0.6;
			path {
				fill: $dark-green;
			}
		}
	}

	.assessment-lock-icon-mobile {
		position: absolute;
		top: 14px;
		right: 20px;
		height: 1.8rem;
		@include media-breakpoint-down(sm) {
			height: 1.7rem;
		}
		path {
			fill: $disabled-color;
		}
	}

	.begin-assess-btn {
		width: 200px;
		height: 46px;
		border-radius: 12px;
		font-size: 18px;
		position: absolute;
		right: 4%;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: $gold;
		color: $dark-blue;

		@media screen and (max-width: 640px) {
			display: none;
		}

		@media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
			display: none;
		}

		@media only screen and (min-width: 1024px) and (max-width: 1024px) and (orientation: portrait) {
			display: block;
			width: 180px;
			height: 45px;
			font-size: $font-size-16;
		}
		&:disabled {
			cursor: not-allowed;
			box-shadow: none;
			background-color: $disabled-color;
		}
		.assessment-lock-icon {
			margin-right: 0.5rem;
			height: 1.6rem;
		}
	}

	.review-btn-div {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		height: 100%;
		position: absolute;
		right: 4%;
	}

	.review-btn {
		padding: 8px 36px;
		background: $gold;
		font-family: $montserrat-bold;
		font-size: $font-size-18;
		border-radius: 12px;
		box-shadow: none;
		color: $dark-blue;
	}

	.review-btn-mob {
		padding: 0px 0px;
		background: $gold;
		font-family: $montserrat-bold;
		font-size: 15px;
		border-radius: 12px;
		box-shadow: none;
	}
	.retake-assessment-link {
		color: $dark-blue;
		text-decoration: none;
		margin-top: 14px;
		font-family: $montserrat-semi;
		font-weight: 600;
		font-size: $font-size-14;
	}

	.btn-move-left {
		right: 8%;
	}

	.question-related-img {
		position: absolute;
		bottom: 0px;
		right: 5px;
		width: 45px;
		padding: 5px 5px;
		&:hover {
			cursor: pointer;
		}
		svg {
			fill: black;
		}
	}
}

// REVIEW TYPE SPECIFIC STYILING
.assess-card.review {
	border-color: $medium-green;
	box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.2);

	@include media-breakpoint-down(md) {
		border: 0.5px solid $medium-green;
	}
	@include media-breakpoint-down(sm) {
		box-shadow: 0px 14px 26px 0px #cfcfcf;
	}

	.assess-text {
		position: relative;
		color: $pure-black;
		font-family: $montserrat-semi;
		.last-assessment-date {
			color: $medium-green;
			font-family: $montserrat-bold;
		}
	}

	.questions {
		font-size: $font-size-16;
		font-family: $montserrat-semi;
		font-weight: 500;
	}

	@include media-breakpoint-down(sm) {
		height: auto;
		flex-direction: column;
		padding: 1rem;
		align-items: flex-start;

		.review-btn-div {
			position: relative;
			align-items: flex-start;
			width: 100%;
			right: auto;
			margin-top: 20px;
			.review-btn {
				padding: 10px 76px;
				border-radius: 12px;
			}
		}
		.assess-text {
			font-size: $font-size-12;
		}
		.vector-assessment-card {
			border-radius: 0px;
			border-top-right-radius: 15px;
			border-bottom-right-radius: 15px;
			left: auto;
			right: 0px;
			height: 85%;
			width: 20%;
		}
		.level {
			margin-top: 0px;
			margin-bottom: 0px;
			font-size: 18px;
		}
	}
}

.review-link {
	text-decoration: underline;
	cursor: pointer;
}

.assessment-batch {
	color: #fff;
	display: inline-flex;
	font-size: 11px;
	background-color: $medium-green;
	padding: 1px 6px;
	margin-left: 14px;
	border-radius: 4px;
}

@import '../styles/1-tools/variables';

.error-page {
	text-align: center;
	background-color: #F1F1F0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	min-height: 100vh;
	width: 100%;
	.error-gif{
		@media screen and(max-width:360px) {max-height: 300px;}
	}
	.btn-fill{
		font-size: 16px;
		@media screen and(max-width:360px) {font-size: 14px;}
	}
}

.loader-main-div {
	@extend .error-page;
	background-color: #fff;
	.loader-image{
		width: 440px;
		height: 390px;
		@media screen and(max-width:767px) {
			width: 80%;
			height: auto;
		}
	}
}

.loader-text {
	text-align: center;
	margin-top: 3vh;
	font-weight: 900;
	font-family: $montserrat-semi;
	font-size: 22px;
	@media screen and(max-width:767px) {
		font-size: 19px;
	}
	@media screen and(max-width:320px) {
		font-size: 16px;
	}
}

.error-msg {
	text-align: center;
	font-size: 23px;
	margin-left: 10px;
	margin-right: 10px;
	@media screen and(max-width:700px) {
		font-size: 18px;
	}
}

// DEFAULT CARD STYILING
.card-component {
	.card-video-div {
		p {
			@include media-breakpoint-down(md) {
				visibility: visible;
			}
		}
	}

	.card-content {
		.next-content-card-vector-right {
			@include media-breakpoint-down(md) {
				height: 4rem;
				right: -10px;
			}
		}
		// .card-save-icon-div {
		// 	@include media-breakpoint-down(md) {
		// 		display: none;
		// 	}
		// }
		.card-option-icon-div {
			@include media-breakpoint-down(sm) {
				min-height: 16px;
			}
		}

		.card-topic {
			@include media-breakpoint-down(md) {
				font-size: $font-size-20;
				width: 95%;
			}
			@include media-breakpoint-down(sm) {
				font-size: 17px;
			}
			@media screen and (max-width: 320px) {
				font-size: 16px;
			}
		}
		.card-description {
			@include media-breakpoint-down(md) {
				width: 100%;
				font-size: $font-size-14;
			}
		}
		.content-card-elapsed-time-div {
			@include media-breakpoint-down(md) {
				margin-left: 0px;
			}
		}
		.mark-as-complete-div {
			@include media-breakpoint-down(md) {
				margin-left: 0px;
				// -- make it 10px when elapsed time is enabled
				font-size: 13px;
			}
		}

		.share-modal {
			@include media-breakpoint-down(md) {
				top: auto;
				width: 145px;
				top: 30%;
				left: auto;
				right: 5.3rem;
			}
		}

		.share-modal.next {
			@include media-breakpoint-down(md) {
				top: auto;
				right: 5.5%;
			}
			@include media-breakpoint-down(sm) {
				right: 10%;
			}
		}
	}

	.content-card-options-div {
		.option-menu-item {
			.mac-tooltip {
				@include media-breakpoint-down(md) {
					font-size: 13px;
					margin-right: 10%;
				}
				@include media-breakpoint-down(sm) {
					top: 55px;
					margin-left: 10px;
				}
			}

			.did-not-understand {
				position: relative;
			}

			.did-not-understand > .mac-tooltip {
				@include media-breakpoint-down(md) {
					top: 10px;
					margin-left: -10px;
				}
				@include media-breakpoint-down(sm) {
					top: -30px;
					width: max-content;
					margin-top: 0px;
				}
			}
		}
	}

	@include media-breakpoint-down(md) {
		&:hover {
			box-shadow: none !important;
			.card-content {
				box-shadow: 0 10px 10px 10px rgba(1, 161, 134, 0.2) !important;
			}
		}
	}
}

.card-component.upcoming {
	@include media-breakpoint-down(md) {
		margin-top: 45px;
		.content-card-options-div {
			padding-top: 6%;
			background-color: transparent;
			pointer-events: none;
			.option-menu-item {
				pointer-events: auto;
				width: 12%;
			}
			.option-menu-item:first-child {
				width: 14%;
			}
			&.show-options {
				transform: translateX(10%);
			}
		}

		.card-wrapper {
			background-color: transparent;
			pointer-events: none;
			&.show {
				transform: translateX(-24%);
			}
		}
	}

	@include media-breakpoint-down(sm) {
		margin-top: 40px;
		margin-bottom: 30px;

		.content-card-options-div {
			padding-top: 23%;
			background-color: transparent;
			.option-menu-item {
				width: 20%;
				svg {
					height: 1.8rem;
				}

				.discussion > .mac-tooltip {
					top: auto;
					left: auto;
					bottom: -25px;
					right: 20px;
				}
			}
			.option-menu-item:first-child {
				width: 23%;
			}
			&.show-options {
				transform: translateX(10%);
			}
		}
		.card-wrapper {
			background-color: transparent;

			&.show {
				transform: translateX(-40%);
				.card-content.ongoing {
					overflow: visible;
					.card-video-time-details.top {
						// TOGGLE FOR ELAPSED TIME
						transform: translate(242px, -112px);
						// transform: translate(208px, -118px);

						& > .content-card-elapsed-time-div {
							padding: 3px 5px;
							font-size: 10px;
						}
						& > .mark-as-complete-div {
							.mark-as-complete-icon {
								margin-right: 0px;
							}
							// change font size to 10px after enabling elapsed time
							font-size: 13px;
						}
					}
				}
			}
		}
	}
	// VIDEO DIVISION FOR ONGOING CARD
	.card-video-div.ongoing {
		@include media-breakpoint-down(sm) {
			height: 105px;
		}
		@media screen and (max-width: 320px) {
			height: 100px;
		}
	}

	// CONTENT DIVISION FOR ONGOING CONTENT CARD
	.card-content.ongoing {
		@include media-breakpoint-down(md) {
			margin-top: 8%;
			// padding-top: 15%;
			.card-video-time-details.top {
				width: fit-content;
				transform: translate(0px);
				transition: transform 0.4s;
			}
		}
		@include media-breakpoint-down(sm) {
			margin-top: 16%;

			.card-video-time-details.top {
				margin-top: 30px;
			}
		}
		// IphoneX, MotoG, Nexus
		// @media screen and (max-width: 375px) {
		// 	margin-top: 30px;
		// }

		@media screen and (max-width: 320px) {
			padding-left: 0.5rem;
			padding-right: 0.5rem;
			padding-top: 22%;
			.card-video-time-details.top {
				flex-direction: column;
				align-items: baseline;
				.content-card-elapsed-time-div {
					padding: 3px 7px;
				}
			}
		}
	}

	// VIDEO DIVISION FOR UPCOMING CARD
	.card-video-div {
		@include media-breakpoint-down(md) {
			pointer-events: auto;
			width: 27%;
			top: 0px;
			margin-left: 3%;
			height: 115px;
		}

		@include media-breakpoint-down(sm) {
			height: 105px;
			width: 60%;
			margin-left: 4.5%;
		}

		// @media only screen and (min-width: 360px) and (max-width: 375px) and (orientation: portrait) {
		// 	height: 100px;
		// }
		@media screen and (min-width: 380px) and (max-width: 420px) {
			top: 20px;
		}

		@media screen and (max-width: 320px) {
			height: 100px;
		}

		p {
			@include media-breakpoint-down(md) {
				visibility: visible;
				font-size: 13px;
				svg {
					height: 1rem;
					width: 1rem;
				}
				svg.article-icon path {
					stroke: transparent;
				}
			}
		}
	}

	// CONTENT DIVISION FOR UPCOMING CARD
	.card-content {
		.next-content-card-vector-right {
			@include media-breakpoint-down(md) {
				display: none;
			}
		}

		@include media-breakpoint-down(md) {
			margin-top: 5%;
			padding-left: 1.5rem;
			margin-left: 0px;
			width: 100%;
			min-height: auto;
			pointer-events: auto;

			.card-description {
				font-size: 11px;
				width: 90%;
			}
			.card-video-time-details.top {
				@include media-breakpoint-down(md) {
					min-height: 20px;
					margin-top: 35px;
					.video-icon,
					.time-text {
						display: none;
					}
				}
				@include media-breakpoint-down(sm) {
					min-height: 18px;
					margin-top: 0px;
				}
				@media screen and (max-width: 360px) {
					.content-card-elapsed-time-div {
						font-size: 11px;
					}
				}
			}
			.card-option-icon-div {
				min-height: 20px;
				margin-bottom: 20px;
			}
			.card-share-icon-div {
				margin-top: auto;
			}
			svg {
				height: 1.3rem;
			}
		}

		@include media-breakpoint-down(sm) {
			margin-top: 23%;
			padding-left: 1rem;
			min-height: 150px;
			box-shadow: 0 7px 13px 0 rgba(1, 161, 134, 0.4);
			.card-option-icon-div {
				margin-bottom: 0px;
			}
			// .card-topic {
			// 	margin-bottom: 14px;
			// }
			svg {
				height: 1rem;
			}
			svg.card-save-icon {
				height: 1.2rem;
				max-width: 1.2rem;
			}
		}
		// IphoneX, MotoG, Nexus
		@media screen and (max-width: 375px) {
			margin-top: 23%;
		}
	}

	.revised-con {
		@media screen and (max-width: 375px) {
			margin-top: 30%;
		}

		@include media-breakpoint-down(md) {
			margin-top: 15%;
			padding-left: 1.5rem;
			margin-left: 0px;
			width: 100%;
			min-height: auto;
			pointer-events: auto;
		}

		@include media-breakpoint-down(sm) {
			margin-top: 29%;
			padding-left: 1rem;
			min-height: 150px;
			box-shadow: 0 7px 13px 0 rgba(1, 161, 134, 0.4);
		}
	}
}

.card-component.upcoming.completed {
	@include media-breakpoint-down(md) {
		.content-card-options-div {
			padding-top: 0%;
			&.show-options {
				transform: translateX(10%);
			}
		}
	}
	// VIDEO DIVISION FOR UPCOMING COMPLETED STAGE CARD
	.card-video-div {
		@include media-breakpoint-down(md) {
			height: 60px;
			width: 45%;
			margin-left: 0px;
			border-radius: 8px;
			.card-video-time-details {
				visibility: hidden;
			}
			.topic-completed-card-div .topic-completed-card-text {
				font-size: 18px;
				font-family: $montserrat-regular;
				svg {
					height: 18px;
				}
			}
		}

		@include media-breakpoint-down(sm) {
			width: 65%;
			height: 50px;
			.topic-completed-card-div .topic-completed-card-text {
				font-size: $font-size-16;
			}
			.topic-completed-card-div .topic-completed-card-text svg {
				height: 16px;
			}
		}
		@media screen and (min-width: 380px) and (max-width: 420px) {
			top: 0px;
		}
	}

	// CONTENT DIVISION FOR UPCOMING COMPLETED STAGE CARD
	.card-content {
		@include media-breakpoint-down(md) {
			border-radius: 15px;
			margin-top: 0px;
			justify-content: flex-end;
			.card-topic {
				font-size: 20px;
				margin-top: 40px;
			}
		}
		@include media-breakpoint-down(sm) {
			.card-topic {
				font-size: 18px;
				// margin-top: 60px;
			}
		}
		@media screen and (max-width: 320px) {
			.card-topic {
				font-size: 15px;
			}
		}

		.card-option-icon-div,
		.card-share-icon-div {
			@include media-breakpoint-down(md) {
				top: 1rem;
			}
		}
		svg {
			&.card-option-icon {
				@include media-breakpoint-down(md) {
					height: 1rem;
				}
			}

			&.card-save-icon {
				visibility: hidden;
			}

			&.card-share-icon {
				@include media-breakpoint-down(md) {
					height: 16px;
				}
			}
		}
	}
}

// UPCOMING ONGOING CARD SPECIFIC STYLES
.card-component.upcoming.ongoing {
	@include media-breakpoint-down(md) {
		margin-top: 45px;
		.content-card-options-div {
			padding-top: 8%;
			&.show-options {
				transform: translateX(10%);
			}
		}
		.card-wrapper {
			&.show {
				transform: translateX(-41%);
			}
		}
	}
	@include media-breakpoint-down(sm) {
		.content-card-options-div {
			padding-top: 16%;
		}
	}
	@media screen and (max-width: 320px) {
		.content-card-options-div {
			padding-top: 16%;
		}
	}
}

// NEXT CARD SPECIFIC STYLING
.card-component.next {
	@include media-breakpoint-down(md) {
		box-shadow: 0 12px 24px 0 #b3b5bc;
		flex-direction: column;
		padding-left: 0px;
		height: auto;
		// min-height: 160px;

		.content-card-options-div > .option-menu-item {
			width: 13%;
		}
		.card-wrapper.show {
			transform: translate(-26%);
		}
	}
	@include media-breakpoint-down(sm) {
		height: auto;
		padding-left: 0px;

		.content-card-options-div {
			background-color: transparent;
			.option-menu-item {
				width: 20%;
				svg {
					height: 1.8rem;
				}
			}
			.option-menu-item:first-child {
				width: 23%;
			}

			// .did-not-understand > .mac-tooltip {
			// @include media-breakpoint-down(md) {
			// 	top: 0px;
			// }

			// @include media-breakpoint-down(md) {
			// 	top: -29px;
			// }
			// }

			.discussion {
				background-color: rgba($dark-green, 0.8);
			}
		}
		.card-wrapper {
			&.show {
				transform: translateX(-40%);
				// transform: translate(-40%, 10%);
			}
		}
	}

	// VIDEO DIVISION FOR NEXT CARD
	.card-video-div {
		@include media-breakpoint-down(md) {
			border-radius: 50%;
			position: absolute;
			left: -1.3rem;
			top: -1.3rem;
			width: 30%;
			height: 80%;
			p {
				visibility: hidden;
			}
		}

		@include media-breakpoint-down(sm) {
			width: 40%;
			height: 65%;
		}
		&::after {
			@include media-breakpoint-down(lg) {
				border-top-left-radius: 8px;
			}
		}

		.content-card-video-image {
			@include media-breakpoint-down(md) {
				border-radius: 50%;
			}
		}
	}

	// CONTENT DIVISION FOR NEXT CARD
	.card-content {
		@include media-breakpoint-down(md) {
			flex-basis: auto;
			height: auto;
			padding: 1rem 0.5rem 1rem 1rem;

			// .card-share-icon-div {
			// 	margin-bottom: 0px;
			// 	margin-top: 0px;
			// }
			.card-option-icon-div {
				margin-top: 0px;
				svg.card-share-icon {
					display: none;
				}
			}
			.card-share-icon-div {
				margin: auto 7px 0px 0px;
			}
			svg.card-option-icon {
				height: 1rem;
			}
			svg.card-share-icon {
				height: 1.2rem;
			}
			.card-video-time-details.top {
				visibility: hidden;
			}
			.card-video-time-details.bottom {
				margin-top: 0.5rem;
				display: flex;
				svg {
					height: 1rem;
					width: 1rem;
					// path{stroke: none;}
				}
				.time-text {
					font-size: 13px;
				}
			}
			.card-topic {
				font-size: 20px;
				font-family: $montserrat-bold;
				font-weight: 700;
			}
			.card-description {
				font-size: $font-size-12;
				width: 90%;
				opacity: 0.8;
			}
		}

		@include media-breakpoint-down(sm) {
			padding: 0.5rem;
			.card-option-icon-div {
				padding-top: 7px;
			}
			.card-share-icon-div {
				margin-bottom: 7px;
			}
			.card-topic {
				font-size: 18px;
				margin-top: 0px;
			}
		}

		@media screen and (max-width: 320px) {
			.card-topic {
				font-size: 16px;
			}
		}
	}

	// CONTENT DIVISION FOR NEXT CARD - ONGOING
	.card-content.ongoing {
		@include media-breakpoint-down(md) {
			padding-top: 50px;
			.card-option-icon-div {
				position: absolute;
				top: 14px;
				right: 7px;
			}
			.card-topic {
				margin-bottom: 0px;
			}

			// -------------	TOGGLE FOR ELAPSED TIME	---------------
			.card-video-time-details.top {
				visibility: visible;
				& > .video-icon,
				& > .time-text {
					display: none;
				}
				& > .mark-as-complete-div {
					background-color: rgba(55, 56, 56, 0.4);
					border-radius: 4px;
					padding: 2px;
				}
			}

			// .card-video-time-details.top {
			// 	visibility: visible;
			// 	& > .video-icon,
			// 	& > .mark-as-complete-div {
			// 		display: none;
			// 	}
			// 	& > .time-text {
			// 		opacity: 0.9;
			// 		font-weight: 400;
			// 	}
			// 	& > .content-card-elapsed-time-div {
			// 		font-size: 14px;
			// 		padding-left: 10px;
			// 		position: absolute;
			// 		top: 0px;
			// 		left: 0px;
			// 		width: 100%;
			// 		height: 44px;
			// 		justify-content: start;
			// 	}
			// }

			// --------------------------------------------------

			.card-video-time-details.bottom {
				& > .time-text {
					font-weight: 400;
					opacity: 0.7;
				}

				& > .mark-as-complete-div {
					font-family: $montserrat-bold;
					font-size: 13px;
					display: inline-flex;
					justify-content: center;
					align-items: center;
					color: $pure-white;
					margin-left: 14px;
					cursor: pointer;
					transition: all 0.2s;
					.mark-as-complete-icon {
						margin-right: 3px;
						height: 12px;
						path {
							fill: $pure-white;
							stroke: $dark-blue;
						}
					}
					&:hover,
					&:active {
						background: rgba(58, 57, 56, 0.2);
						box-shadow: 0.5px 0.5px 4px rgba(0, 0, 0, 0.6);
						border-radius: 2px;
					}
				}
			}
		}
		@media screen and (max-width: 360px) {
			.card-video-time-details.bottom {
				& > .time-text {
					font-size: 11px;
				}
				& > .mark-as-complete-div {
					font-size: 11px;
				}
			}
		}
	}
}

@import '../1-tools/variables';

.streak-card-lg {
	height: 400px;
	width: 100%;
	border-radius: 11px;
	box-shadow: 0 16px 27px 0 rgba(0, 0, 0, 0.2);
	background-color: $pure-white;
	position: relative;
	display: flex;
	align-items: center;
	flex-direction: column;

	.streak-orange-bg {
		position: absolute;
		bottom: 0px;
		width: 100%;
		border-radius: 11px;
		box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.1);
		background-image: linear-gradient(to top, #f99b4b, #e46122);
		padding-top: 2.1rem;
		padding-bottom: 1.4rem;
		justify-content: space-between;
		display: flex;
		padding-left: 1rem;
		padding-right: 1rem;
		flex-wrap: wrap;
	}

	.streak-icon-style {
		top: 5%;
		position: relative;
		width: 48%;
		height: 48%;
	}
	.streak-icon-flame {
		position: absolute;
		width: 30%;
		height: 30%;
		top: 13%;
	}

	.streak-text {
		font-size: $font-size-20;
		position: relative;
		margin-top: 2rem;
		font-family: $montserrat-bold;
		letter-spacing: 3.86px;
		color: #425063;
		font-weight: bold;
		margin-bottom: 0px;
	}

	.streak-text-motivation {
		text-align: center;
		position: relative;
		color: #425063;
		letter-spacing: 1.5px;
		font-size: $font-size-16;
		font-family: $montserrat-regular;
	}

	.per-day-streak {
		display: inline-flex;
		flex-direction: column;
		align-items: center;
	}

	.streak-text-days {
		color: $pure-white;
		font-size: $font-size-12;
		margin-top: 7px;
		margin-bottom: 0px;
	}

	.streak-icon-tick {
		height: 1.4rem;
	}
}

.streak-card-pill-md {
	height: 60px;
	width: 170px;
	flex-basis: 50%;
	background: linear-gradient(to right, #ff9433 0%, #e67338 1000%);
	box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2);
	border-radius: 10px;
	margin-left: 1rem;
	display: flex;
	align-items: center;

	svg {
		height: 60%;
		width: 35%;
	}
	.streak-text {
		font-size: $font-size-14;
		font-family: $montserrat-regular;
		color: $pure-white;
	}
	.streak-text-motivation {
		font-size: $font-size-10;
		font-family: $montserrat-regular;
		color: $pure-white;
	}

	@media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
		height: 60px;
		// width: 280px;
		.streak-text {
			font-size: $font-size-18;
		}
		.streak-text-motivation {
			font-size: $font-size-14;
		}
	}

	@media only screen and (max-width: 360px) {
		height: 60px;
		// width: 150px;
		margin-left: 0.3rem;
		.streak-text {
			font-size: $font-size-12;
		}
		.streak-text-motivation {
			font-size: $font-size-8;
		}
	}
}

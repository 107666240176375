// GENERIC STYLINGS
.modal {
	.modal-content {
		border: 0 solid black;
		// border-bottom: 4px solid $lime-green;
		width: auto;
		border-radius: 15px;
		background: transparent;
		@media screen and (max-width: 380px) {
			margin-top: 30px;
		}
	}
}
.modal-button {
	justify-content: center;
}

.modal-body {
	padding: 1rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	overflow-y: auto;
	background-color: $pure-white;
	flex-wrap: wrap;
}

.congrat-modal {
	img {
		width: 100%;
	}
}

.modal-dialog {
	align-items: center;
	justify-content: center;
	display: flex;
	@media (min-width: 576px) {
		max-width: 1000px !important;
	}
}

.modal-close-div {
	position: absolute;
	right: 16px;
	cursor: pointer;
	top: 14px;
	svg {
		width: 18px;
	}
	@media screen and (max-width: 380px) {
		right: 12px;
		top: 7px;
		svg {
			width: 14px;
		}
	}
}

.modal-video-close-btn:active,
.modal-video-close-btn:focus {
	outline: none;
}

.modal-btn {
	overflow: hidden;
	position: relative;
	padding: 7px 22px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: white;
	font-size: 20px;
	font-family: $montserrat-semi;
	border-radius: 12px;
	box-shadow: 0 6px 20px 0 rgba(48, 55, 78, 0.4);
	background-color: $medium-green;
	cursor: pointer;
	border: none;
	margin-right: 7px;
	margin-left: 7px;
	transition: all 0.3s;
	@media screen and (max-width: 767px) {
		min-height: 45px;
		margin-left: auto;
		margin-top: 7px;
		margin-bottom: 7px;
		font-size: 16px;
	}
	&:hover {
		box-shadow: 0px 6px 20px 0 rgba(48, 55, 78, 0.7);
	}
	&:active,
	&:focus {
		outline: none;
	}
}

.modal-img {
	height: 300px;
	border-radius: 6px;
	@media screen and (max-width: 767px) {
		height: 200px;
	}
	@media screen and (max-width: 380px) {
		height: 180px;
	}
}
.modal-btn-div {
	display: flex;
	margin-top: 20px;
	@media screen and (max-width: 767px) {
		flex-direction: column;
		margin-top: 7px;
	}
}

// ONBOARDING MODAL
.icon-onboarding-completion {
	height: 180px;
	width: 300px;
}
.onboarding-completion-main-text {
	font-family: $opensans-bold;
	font-size: $font-size-30;
	color: #30364e;
	margin-top: 10px;
	@media screen and (max-width: 767px) {
		font-size: $font-size-20;
	}
}
.onboarding-completion-sub-text {
	font-size: 16px;
	font-family: $opensans-regular;
	font-weight: 300;
	padding: 1.5%;
	color: black;
	@media screen and (max-width: 767px) {
		font-size: 14px;
	}
}

// MAJOR MINOR MODAL (ONBOARDING MODAL)
.major-minor-modal-options-card {
	margin: 10px;
	max-width: 320px;
	@media screen and (max-width: 768px) {
		padding: 10px 14px !important;
	}
	@media screen and (max-width: 767px) {
		max-width: 100% !important;
		margin: 6px 0px !important;
	}
	& > .main-text {
		@media screen and (max-width: 380px) {
			font-size: 15px !important;
		}
	}
}

// PRICE MODAL
.price-modal-btn-div {
	margin-top: 20px;
	@media screen and (max-width: 767px) {
		flex-direction: column;
		margin-top: 7px;
	}
}
.price-modal-img {
	max-height: 350px;
	border-radius: 6px;
	@media screen and (max-width: 767px) {
		height: 230px;
	}
	@media screen and (max-width: 380px) {
		height: 180px;
	}
}
.price-modal-head {
	@extend .onboarding-completion-main-text;
	margin-bottom: 10px;

	@media screen and (max-width: 380px) {
		margin-bottom: 10px;
		font-size: 20px;
		font-family: $montserrat-bold;
		font-weight: bold;
	}
}
.price-modal-description {
	font-family: $opensans-bold;
	color: #999999;
	font-size: 16px;

	@media screen and (max-width: 380px) {
		font-size: 13px !important;
	}
}
.price-modal-upgrade-btn {
	@extend .modal-btn;
	padding: 10px 50px;
	background: linear-gradient(285deg, #13a0bd 8%, #086788 78%);
	@media screen and (max-width: 767px) {
		width: 100%;
	}
}
.price-modal-purchase-btn {
	@extend .modal-btn;
	padding: 10px 36px;
	@media screen and (max-width: 767px) {
		width: 100%;
	}
	& > a,
	& > a:hover {
		text-decoration: none;
		color: #fff;
	}
}

// WELCOME MODAL
.welcome-modal-head {
	@extend .onboarding-completion-main-text;
	font-size: 30px;
	@media screen and (max-width: 767px) {
		font-size: 22px;
		line-height: 29px;
	}
	@media screen and (max-width: 360px) {
		font-size: 20px;
	}
}
.welcome-modal-btn {
	@extend .modal-btn;
	margin-top: 14px;
	width: 100%;
	height: 20%;
	white-space: nowrap;
}
.welcome-modal-pill {
	text-align: center;
	margin: 0px 10px;
	padding: 0px 10px;
	display: inline-block;
	justify-content: center;
	align-items: center;
	width: auto;
	font-size: 17px;
	color: white;
	border-radius: 12px;
	cursor: text !important;
	background-image: linear-gradient(285deg, #13a0bd 8%, #086788 78%);
	margin-right: 0px;
	@media screen and (max-width: 767px) {
		font-size: 13px;
	}
}

.three-days-modal-single-btn {
	width: auto;
	margin-top: 5px;
	padding: 10px 20px;
}
.welcome-modal {
	@media screen and (max-width: 767px) {
		position: absolute;
		height: 100%;
		top: 20px;
		bottom: 20px;
	}
}

.welcome-modal-gif {
	min-height: 250px;
	border-radius: 4px;
	@media screen and (max-width: 500px) {
		width: 125%;
		min-height: 160px;
		margin-left: -7%;
	}
}

.bold-text {
	font-family: $opensans-bold;
	font-weight: 800;
}

.welcome-modal-one-week-gif {
	margin: 0px 50px;
	@media screen and (max-width: 767px) {
		margin: 0px;
	}
}
.welcome-modal-lastweek-gif {
	@media screen and (max-width: 500px) {
		width: 250px;
	}
}

// REFERRAL MODAL
.referral-modal-body {
	@media screen and (max-width: 767px) {
		padding: 10px !important;
	}
}
.referral-modal-close-btn-div {
	right: 18px;
	position: absolute;
	top: 0px;
	height: fit-content;
	svg {
		cursor: pointer;
		width: 16px;
		fill: #fff;
		@media screen and (max-width: 767px) {
			width: 13px;
		}
		@media screen and (max-width: 360px) {
			width: 11px;
		}
	}
	@media screen and (max-width: 360px) {
		right: 0px;
		padding: 6px 14px;
	}
}

.dayweekplan-modal-cross {
	top: 8px;
	@media screen and (max-width: 600px) {
		top: 2px;
	}
}
.referral-modal-header {
	height: auto;
	object-fit: contain;
	border-radius: 15px;
	box-shadow: 0 12px 24px 0 rgba(179, 181, 188, 0.3);
	background-color: #30374e;
	border-bottom-left-radius: 0px;
	border-bottom-right-radius: 0px;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	background-repeat: no-repeat;
	background-position: top;
	background-position-x: right;
	display: flex;
	align-items: center;
	justify-content: center;
	color: $pure-white;
	font-size: 33px;
	font-family: $montserrat-semi;
	min-height: 70px;
	@media screen and (max-width: 767px) {
		font-size: 24px;
		align-items: flex-end;
		padding-bottom: 5px;
		min-height: 60px;
	}
	@media screen and (max-width: 360px) {
		font-size: 18px;
		min-height: 40px;
	}
}
.referral-modal-description {
	text-align: justify;
	padding-left: 15px;
	& > span {
		color: #3a4b67;
	}
	@media screen and (max-width: 767px) {
		padding-left: 0px;
		font-size: 13px;
	}
	@media screen and (max-width: 360px) {
		font-size: 12px !important;
	}
}
.refer-link-title {
	margin-top: 15px;
	@media screen and (max-width: 991px) {
		margin-top: 10px;
		font-size: 16px !important;
	}
}
.refer-link-div {
	margin-bottom: 10px;
	margin-top: 5px;
	& > .refer-link-copy-img {
		position: relative;
		cursor: pointer;
		display: inline-block;
		margin-left: 10px;
		img {
			height: 30px;
		}
	}
}
.refer-input {
	border: 1px solid grey;
	padding: 4px;
	height: 40px;
	display: inline-block;
	align-items: center;
	font-size: 16px;
	width: 330px;
	overflow: hidden;
	border-radius: 5px;
	font-family: $opensans-regular;
	@media screen and (max-width: 767px) {
		width: 280px;
		height: 30px;
		font-size: 13px;
	}
	@media screen and (max-width: 360px) {
		width: 240px;
	}
}
.referral-gif {
	height: 250px;
	width: inherit;
	border-radius: 4px;
	@media screen and (max-width: 767px) {
		height: 200px;
		margin-bottom: 10px;
	}
	@media screen and (max-width: 380px) {
		height: 170px;
	}
	@media screen and (max-width: 360px) {
		height: 110px;
	}
}
.refer-img {
	width: 150px;
	height: 100px;
	margin-top: 1rem;
	@media screen and (max-width: 767px) {
		width: 100px;
		height: 70px;
		margin-top: 0.5rem;
	}
	@media screen and (max-width: 360px) {
		height: 50px;
	}
}
.refer-text {
	font-size: 18px;
	color: #30374e;
	margin-bottom: 0px !important;
	@media screen and (max-width: 767px) {
		font-size: 13px;
	}
}
.refer-friend-img-mob {
	width: 21px;
	margin-right: 20px;
	padding-left: 1px;
}
.SocialMediaShareButton {
	cursor: pointer;
	padding: 5px;
	& > div {
		cursor: pointer;
	}
	& > div > svg {
		border-radius: 6px;
	}
	&:active,
	&:focus {
		outline: none;
	}
}

// SNS MODAL
.sns-modal-head {
	@extend .onboarding-completion-main-text;
	@media screen and (max-width: 767px) {
		font-size: $font-size-24;
	}
	@media screen and (max-width: 320px) {
		font-size: $font-size-20;
	}
}
.sns-modal-description {
	@extend .onboarding-completion-sub-text;
	@media screen and (max-width: 767px) {
		text-align: justify;
	}
}
.sns-modal-continue-btn {
	padding: 9px 35px;
	@media screen and (max-width: 767px) {
		padding: 5px 24px;
	}
	&:active,
	&:focus {
		outline: none;
	}
}
.sns-modal-img {
	height: 180px;
}

// VERSION UPDATE MODAL
.verupdate-modal-btn {
	@extend .modal-btn;
	margin: 0px;
	margin-right: 15px;
}

// LOCKED MODAL
.locked-modal-logout-btn {
	position: absolute;
	cursor: pointer;
	width: 25px;
	right: 16px;
	top: 14px;
	@media screen and (max-width: 500px) {
		top: 65px;
	}
}
.locked-modal-description {
	@extend .price-modal-description;
	@media screen and (max-width: 360px) {
		line-height: 18px;
	}
}
.locked-modal-img {
	@media screen and (max-width: 360px) {
		max-height: 160px;
	}
	@media screen and (max-width: 320px) {
		max-height: 130px;
	}
}
.locked-modal-btn {
	@media screen and (max-width: 360px) {
		margin-bottom: 0px;
	}
	@media screen and (max-width: 320px) {
		font-size: 13px;
	}
	a {
		text-decoration: none;
		color: white;
	}
}
.locked-modal-head {
	@media screen and (max-width: 320px) {
		font-size: 16px;
		line-height: 18px;
	}
}

// ACHIEVE MODAL
.achieve-modal-card-container {
	& > .onboarding-interest .interest-card {
		min-width: 230px;
		margin: 10px;
	}
	@media screen and (max-width: 767px) {
		flex-direction: column;
		& > .onboarding-interest .interest-card {
			margin-bottom: 0px !important;
		}
	}
}

// PWA INSTALL MODAL
.pwa-install-modal {
	min-height: 85vh;
	.modal-content {
		width: 100%;
		background-color: transparent;
	}
	.chrome-icon {
		height: 16px;
	}
	.chrome-menu-icon {
		height: 14px;
		margin: auto 3px;
		& > path {
			fill: #888;
		}
	}
	.pwa-gif {
		width: 60%;
		border-radius: 4px;
	}
	.pwa-ol > li {
		font-weight: 500;
		margin: 10px auto;
		text-align: start;
	}
	.btn-icon > .tick-icon {
		height: 10px;
	}
}

// ======================================

.crossOfModal {
	position: absolute;
	top: 8px;
	right: 15px;
	cursor: pointer;

	@media screen and (max-width: 769px) {
		font-size: 20px;
		position: inherit;
		display: block;
		margin-left: auto;
		margin-right: 13px;
		margin-top: 10px;
		width: 20px;
	}

	@media screen and (max-width: 320px) {
		width: 15px;
	}
}

.modal-title {
	font-family: Montserrat !important;
	font-size: 34px !important;
	font-weight: bold !important;
	color: #ffffff;
	margin-top: 10px;

	@media screen and (max-width: 400px) {
		font-size: 28px !important;
	}
}

// .locked-free-access-modal {
// 	height: 500px;
// }

.locked-modal-body {
	position: relative;
}

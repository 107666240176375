@import '../1-tools/variables';

.btn-surface {
	color: white !important;
	background-color: #1c8976 !important;
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12),
		0 1px 1px 0 rgba(0, 0, 0, 0.14);
  font-family: $opensans-bold;
  border-radius: 5px;
  width: 100%;
  padding: 5px;
  border: none;

  &:disabled{
    opacity: 0.65;
    cursor: not-allowed;
  }
  &:active, &:focus{
    border: none;
    outline: none;
  }
}

.button-rounded {
	border-radius: 22px;
	color: #4d5367;
	background-color: #fff;
	border: 1px solid #666;
	cursor: pointer;
	padding-top: 5px;
	padding-bottom: 5px;
	font-size: 16px;
	transition: 0.5s;
	&:hover {
		transition: 0.5s;
		box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.2);
	}
}

.button-rounded-white {
	@extend .button-rounded;
	color: #333;
	background-color: #fff;
}

.rounded-btn-primary {
	background-color: #086788;
	border-radius: 25px;
	color: #fff;
	font-size: 16px;
	border: 0;
	font-family: $opensans-bold;
	// max-width: 150px;
	padding-bottom: 5px;
	padding-top: 5px;
	cursor: pointer;
	outline: none;
	transition: 0.5s;
	&:hover {
		transition: 0.5s;
		// box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.2);
		box-shadow: 0 0px 3px 3px rgba(0, 0, 0, 0.3);
	}
}

.guide-button {
	background-color: #00a287;
}

.icon-back-arrow {
	height: 20px;
	width: 20px;
}

.btn-back-blue {
	color: #3f3d56;
	text-decoration: none;
	&:hover {
		text-decoration: none;
		color: #3f3d56;
	}
}


// -------------------------	V2 BUTTON STYLES	-------------------------

// BUTTON PRIMARY
.primary-btn {
  color: $white;
  font-family: $montserrat-semi;
  padding: 0.4rem;
  min-width: 8rem;
  transition: all 0.4s;
  border-radius: 8px;
  box-shadow: $shadow-default-4;
  background-image: $linear-blue-light;
  border: none;

  &:focus {
    outline: none !important;
  }

  &:hover {
    box-shadow: $shadow-8;
  }

  &:active {
    box-shadow: $shadow-active-4;
    background: $linear-blue-dark;
    outline: none !important;
    border: none !important;
  }

  &.disabled {
    background: $dark-grey;
  }
}

// BUTTON OUTLINE
.btn-outline {
  padding: 0.4rem;
  min-width: 8rem;
  border-radius: 8px;
  border: solid 2px $dark-blue;
  background-color: $white;
  font-family: $montserrat-semi;

  &:focus {
    outline: 0;
  }

  &:hover {
    box-shadow: 0 4px 14px 0 rgba(48, 55, 78, 0.4);
  }

  &:active {
    box-shadow: inset $shadow-5;
  }

  &.disabled {
    border: solid 2px $dark-grey;
  }
}

// BUTTON FILL
.btn-fill {
  color: $white;
  font-family: $montserrat-semi;
  padding: 0.25rem;
  min-width: 8rem;
  transition: all 0.4s;
  border-radius: 8px;
  box-shadow: $shadow-default-4;
  background-color: $medium-green;
  border-color: transparent;

  &:focus {
    outline: 0;
  }

  &:hover {
    box-shadow: $shadow-8;
  }

  &:active {
    box-shadow: $shadow-active-4;
    background-color: $dark-green;
  }

  &.disabled {
    background-color: $dark-grey;
  }
}

// BUTTON WITH TEXT + ICON
.btn-text-icon {
  color: $white;
  font-family: $montserrat-semi;
  padding: 0.4rem;
  min-width: 8rem;
  border-radius: 10px;
  box-shadow: $shadow-default-4;
  background: $linear-blue-light;
  border: none;
  transition: all 0.4s;
  margin: auto 2rem;
  outline: 0;
  background-color: transparent;

  svg {
    height: 1rem;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 3px;
  }

  &:focus {
    outline: 0;
  }

  &:hover {
    box-shadow: $shadow-8;
  }

  &:active {
    box-shadow: $shadow-active-4;
    background: $linear-blue-dark;
  }

  &.disabled {
    background-color: $dark-grey;
  }
}

// BUTTON ICON
.btn-icon {
  color: $white;
  font-family: $montserrat-semi;
  padding: 0.4rem;
  min-width: 3rem;
  border-radius: 4px;
  box-shadow: $shadow-default-4;
  border-color: transparent;
  background-color: $dark-blue;

  &:focus {
    outline: 0;
  }

  &:hover {
    box-shadow: $shadow-6;
  }

  &:active {
    background-color: $black;
  }

  &.disabled {
    background-color: $dark-grey;
  }
}

.back-btn {
  color: $pure-white;
	background-color: $dark-blue;
	font-family: $montserrat-regular;
	display: flex;
  align-items: center;
  justify-content: center;
	width: fit-content;
  cursor: pointer;
  min-width: 7rem;
  height: 40px;
  transition: all 0.4s;
  border-radius: 8px;
  box-shadow: $shadow-default-4;
  border-color: transparent;

  &:focus {
    outline: 0;
  }

  &:hover {
    box-shadow: $shadow-8;
  }

  &:active {
    box-shadow: $shadow-active-4;
    background-color: $very-dark-blue;
  }

  &.disabled {
    background-color: $dark-grey;
  }

	svg {
		width: 11px;
		margin-right: 5px;
		transform: rotate(90deg);
		path {
			stroke: transparent;
			fill: #fff;
		}
	}

	@include media-breakpoint-down(sm) {
		font-size: $font-size-14;
    min-width: 5rem;
    height: 30px;
		svg {
			height: 10px;
			width: 10px;
		}
	}
}

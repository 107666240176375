// COLOR GUIDE

// primary Flat Colors
$light-blue: #4addfa;
$medium-blue: #086788;
$dark-blue: #30374e;
$lightest-green: #e6fffb;
$light-green: #15e5c2;
$lightest-blue: #e1f5f2;
$medium-green: #26bba2;
$dark-green: #1c8976;
$darkest-green: #0c5548;
$disabled-color: #979797;
$gold: #f9d438;

// White's & Black's
$pure-white: #fff;
// $white: #f9f9f9;
$light-grey: #ebebec;
$medium-grey: #dfdfdf;
$dark-grey: #b4b4b4;
$black: #242424;

// form Colors
$pure-black: #000000;
$red: #d91a1a;

// Gradients
$linear-blue-green: linear-gradient(
	271deg,
	#7dc242 100%,
	#6bbf60 83%,
	#2ab9a0 50%,
	#39bcab 40%,
	#54c3ca 19%,
	#68cbea 0%
);
$linear-green-light: linear-gradient(to right, #15e5c2 0%, #22a246 100%);
$linear-blue-light: linear-gradient(to left, #13a0bd 0%, #086788 100%);

$linear-blue-dark: linear-gradient(to left, #08778d 0%, #034e68 100%);
$linear-green-dark: linear-gradient(to right, #086788 0%, #26bba2 100%);

// Shadows
$shadow-default-4: 0 6px 20px 0 rgba(48, 55, 78, 0.4);
$shadow-active-4: 0 3px 10px 0 rgba(48, 55, 78, 0.4);
$shadow-5: 0 0 6px 0 rgba(0, 0, 0, 0.5);
$shadow-6: 0 6px 20px 0 rgba(100, 100, 100, 0.6);
$shadow-8: 0 6px 20px 0 rgba(100, 100, 100, 0.8);
$content-card-default-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.2);

// font's
$montserrat-regular: "Montserrat-Regular";
$montserrat-bold: "Montserrat-Bold";
$montserrat-semi: "Montserrat-Semi";
$opensans-regular: "OpenSans-Regular";
$opensans-bold: "OpenSans-Bold";
$opensans-italic: "OpenSans-Italic";
$sofia: "Sofia-cursive";

// fonts Guide
$font-size-40: 40px;
$font-size-34: 34px;
$font-size-32: 32px;
$font-size-30: 30px;
$font-size-24: 24px;
$font-size-26: 26px;
$font-size-22: 22px;
$font-size-20: 20px;
$font-size-18: 18px;
$font-size-16: 16px;
$font-size-14: 14px;
$font-size-12: 12px;
$font-size-10: 10px;
$font-size-8: 8px;

//input's Background
$disabled-background-color: #e9e9e9;
$error-background-color: #fff6f6;

// form borders
$default-border: solid 1px #1c8976;
$focus-box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.1);
$focus-border: solid 1.5px #26bba2;
$disabled-border: solid 1px #979797;
$error-border: solid 1.5px #d91a1a;

@mixin page-base-layout {
	width: 100%;
	height: 100vh;
	overflow-y: auto;
	background-color: $lightest-blue;
	// padding-top: 90px;

	@media screen and (max-width: 768px) {
		padding-bottom: 60px;
		height: auto;
	}

	&.pricepopupOn {
		padding-top: 125px;
		@media screen and (max-width: 768px) {
			padding-top: 130px !important;
			padding-bottom: 60px;
		}
	}
}

@mixin page-subhead {
	font-family: $montserrat-bold;
	width: max-content;

	&::after {
		content: "";
		display: block;
		width: 45%;
		height: 0.5rem;
		opacity: 0.5;
		background-color: rgba(21, 229, 194, 0.5);
		border-radius: 25px;
		margin-top: -6px;
		margin-left: -6px;
	}
	@media only screen and (max-width: 767px) {
		max-width: 100%;
	}
}

// regular
@font-face {
	font-family: "Lato-Regular";
	src: url("/fonts/lato-regular-webfont.woff2") format("woff2"),
		url("/fonts/lato-regular-webfont.woff") format("woff"), url("/fonts/Lato-Regular.ttf") format("truetype");
}

// medium
@font-face {
	font-family: "Lato-Medium";
	src: url("/fonts/lato-medium-webfont.woff2") format("woff2"), url("/fonts/lato-medium-webfont.woff") format("woff"),
		url("/fonts/Lato-Medium.ttf") format("truetype");
}

// semi-bold
@font-face {
	font-family: "Lato-Semibold";
	src: url("/fonts/lato-semibold-webfont.woff2") format("woff2"),
		url("/fonts/lato-semibold-webfont.woff") format("woff"), url("/fonts/Lato-Semibold.ttf") format("truetype");
	font-weight: 600;
}

// bold
@font-face {
	font-family: "Lato-Bold";
	src: url("/fonts/lato-bold-webfont.woff2") format("woff2"), url("/fonts/lato-bold-webfont.woff") format("woff"),
		url("/fonts/Lato-Bold.ttf") format("truetype");
	font-weight: 800;
}

// regular
@font-face {
	font-family: "Montserrat-Regular";
	src: local("Montserrat-Regular"), url("/fonts/montserrat-regular-webfont.woff2") format("woff2"),
		url("/fonts/montserrat-regular-webfont.woff") format("woff"),
		url("/fonts/Montserrat-Regular.ttf") format("truetype");
}

@font-face {
	font-family: "OpenSans-Regular";
	src: local("OpenSans-Regular"), url("/fonts/opensans-regular-webfont.woff2") format("woff2"),
		url("/fonts/opensans-regular-webfont.woff") format("woff"),
		url("/fonts/OpenSans-Regular.ttf") format("truetype");
}

// bold
@font-face {
	font-family: "Montserrat-Bold";
	src: local("Montserrat-Bold"), url("/fonts/montserrat-bold-webfont.woff2") format("woff2"),
		url("/fonts/montserrat-bold-webfont.woff") format("woff"), url("/fonts/Montserrat-Bold.ttf") format("truetype");
	font-weight: 800;
}
@font-face {
	font-family: "OpenSans-Bold";
	src: local("OpenSans-Bold"), url("/fonts/opensans-bold-webfont.woff2") format("woff2"),
		url("/fonts/opensans-bold-webfont.woff") format("woff"), url("/fonts/OpenSans-Bold.ttf") format("truetype");
	font-weight: 800;
}

// semibold
@font-face {
	font-family: "Montserrat-Semi";
	src: local("Montserrat-SemiBold"), url("/fonts/montserrat-semibold-webfont.woff2") format("woff2"),
		url("/fonts/montserrat-semibold-webfont.woff") format("woff"),
		url("/fonts/Montserrat-SemiBold.ttf") format("truetype");
	font-weight: 600;
}

// italic
@font-face {
	font-family: "OpenSans-Italic";
	src: url("/fonts/opensans-italic-webfont.woff2") format("woff2"),
		url("/fonts/opensans-italic-webfont.woff") format("woff"), url("/fonts/OpenSans-Italic.ttf");
}

// handwriting
@font-face {
	font-family: "Sofia-cursive";
	src: url("/fonts/sofia-v9-latin-regular.woff2") format("woff2"),
		url("/fonts/sofia-v9-latin-regular.woff") format("woff"), url("/fonts/sofia-v9-latin-regular.ttf") format("truetype");
}

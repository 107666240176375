.job-container {
	overflow: hidden;
	@include page-base-layout;
	position: relative;
	min-height: 100vh;
	padding-top: 100px;
	@include media-breakpoint-down(sm) {
		padding-top: 120px;
	}
	&.pricepopupOn {
		padding-top: 170px !important;
	}
	.vector-job-top-left {
		position: absolute;
		height: 25rem;
		z-index: -4;
		left: 0px;
		top: 0px;
		@include media-breakpoint-down(md) {
			height: 20rem;
		}
	}
	.vector-jobs-bottom-right {
		@include media-breakpoint-down(sm) {
			position: absolute;
			height: 50%;
			bottom: 0px;
			right: 0px;
			z-index: -1;
		}
	}
}

// .sm-px-0 {
// 	@include media-breakpoint-down(sm) {
// 		padding-left: 0 !important;
// 		padding-right: 0 !important;
// 	}
// }

.card-course {
	@extend .card-style-container;
	border: 1px solid transparent;
	@include media-breakpoint-down(sm) {
		height: auto;
	}

	&:hover {
		box-shadow: 0 5px 10px 4px rgba(#666, 0.3);
		transition: 0.5s;
		border: 1px solid #25bba2;
	}
}

.jobs-card-btn {
	font-size: 13px;
	padding: 0.2rem;
	color: #4d5367;
	border: 1px solid #666;
	transition: all 0.2s;
	&:hover {
		box-shadow: 4px 0px 7px 0 rgba(48, 55, 78, 0.3);
	}
}

.jobs-company-logo-container {
	height: 85px;
	width: 85px;
	flex-basis: 30%;
	// border: 1px solid #979797;
	// border-radius: 50%;
	position: relative;
}

.jobs-company-name {
	font-family: $montserrat-semi;
	font-size: 14px;
	width: 170px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	color: #30364e;
}

.job-ad-source {
	display: flex;
	justify-content: flex-end;
	font-family: $montserrat-bold;
	font-size: 14px;
	text-align: right;
}

.jobs-location-des {
	font-size: 13px;
	color: #97999e;
}

.jobs-card-content-height {
	padding-left: 10px;
}

.recruiter_logo {
	right: 30px;
	height: 24px;
	width: 24px;
	opacity: 0.6;
}

.blur {
	filter: blur(2px);
}

.job-options-icon {
	height: 18px;
	padding-left: 10px;
	// transform-origin: 65% 75%;
	// transition: transform .25s, visibility .25s ease-in-out;
	@media only screen and (min-width: 320px) and (max-width: 360px) {
		height: 12px;
	}
	@media only screen and (min-width: 361px) and (max-width: 575.98px) {
		height: 15px;
	}
	&:hover {
		cursor: pointer;
		// transform: scale(1.2);
	}
}

.submenu {
	position: relative;
	display: inline-block;
}

.submenu-content {
	display: block;
	position: absolute;
	background-color: #f9f9f9;
	right: 0px;
	min-width: 130px;
	box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.2);
	padding: 12px 16px;
	z-index: 1;
	cursor: pointer;
}

.report-job-button {
	font-size: 14px;
	// color: #a52a2a ;
	color: black;
	cursor: pointer;
}

.report-icon {
	padding-top: 4px;
	height: 15px;
	width: 13px;
}
.report-job-button:hover {
	color: #a52a2a;
}
// OVERLAY CONTAINER STYLES FOR LOCKED SECTION

// .jobs-overlay-container {
// 	position: absolute;
// 	z-index: 1;
// 	height: 100%;
// 	width: 100%;
// 	top: 0px;
// 	overflow: hidden;
// 	display: flex;
// 	align-items: center;
// 	justify-content: center;
// }

// .jobs-overlay-message-div {
// 	color: #fff;
// 	padding: 20px 14px;
// 	width: 40%;
// 	height: auto;
// 	border-radius: 14px;
// 	text-align: center;
// 	background: rgba(#000, 0.85);

// 	.lockicon {
// 		height: 40px;
// 		width: 40px;
// 	}

// 	.locked-overlay-btn {
// 		color: $pure-white;
// 		font-family: $montserrat-semi;
// 		padding: 4px 7px;
// 		border-radius: 7px;
// 		font-size: 15px;
// 		background-color: $medium-green;
// 	}

// 	@include media-breakpoint-down(sm) {
// 		width: 60%;
// 	}
// }

.job-vector-img {
	height: 25%;
	position: absolute;
	right: 0;
	top: 10%;
	width: 25%;
}

.job-img-top {
	top: 8%;
	height: 21%;
}

.job-container h2 {
	margin-bottom: 4%;
	margin-top: 2.5%;
	font-family: $montserrat-bold;
	font-weight: 900;
	@include media-breakpoint-down(md) {
		margin-top: 15%;
	}
	@include media-breakpoint-down(sm) {
		margin-top: 0px;
	}
}

@media screen and (max-width: 540px) {
	.jobs-company-name {
		white-space: nowrap;
		width: 180px;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

.tab_slider {
  @extend .custom_card_slider;
  min-height: 3rem;
  display: flex;
  justify-content: center;
}

.profile-container .tab_slider {
  background-color: transparent;
}

.tab_slider_container {
  @extend .slider_container;
  height: 3rem;
  width: 85%;
  display: inline-flex;
}

.tab_slider_container::-webkit-scrollbar {
  height: 6px;
  @include media-breakpoint-down(md) {
    display: none;
  }
}

.tab_slider_container::-webkit-scrollbar-track {
  // background-color: transparent;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.tab_slider_container::-webkit-scrollbar-thumb {
  background-color: rgba(#000, 0.4);
  border-radius: 6px;
  outline: none;
}

.tab_slider_wrapper {
  @extend .slider_wrapper;
}

.tab_internal_slider {
  @extend .slider;
  width: 80%;
}

.tab_slider_forward_btn {
  min-width: auto;
  display: inline-flex;
  box-shadow: none;
  border: none;
  background: $pure-white;
}

.tab_slider_back_btn {
  min-width: auto;
  box-shadow: none;
  border: none;
  background: $pure-white;
  display: inline-flex;
}

.active {
  font-family: $montserrat-bold;
  font-weight: 800;
  &::after {
    content: "";
    display: block;
    height: 4px;
    width: 100%;
    background: $medium-green;
  }
}

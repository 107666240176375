@import "../1-tools/variables";

.custom_card_slider {
	background: $pure-white;
	&.featured {
		background: transparent;
	}
	&.dashboard {
		h4 {
			display: none;
		}
		.dashboard-slider-header {
			font-size: $font-size-16;
			font-family: $montserrat-semi;
		}
	}
}

.slider_container {
	bottom: 0px;
	width: 100%;
	overflow-x: auto;
}

.slider_container::-webkit-scrollbar {
	width: 4px;
	@media screen and (max-width: 767px) {
		display: none;
	}
}

.slider_container::-webkit-scrollbar-track {
	background-color: transparent;
	// box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.slider_container::-webkit-scrollbar-thumb {
	background-color: rgba(#000, 0.4);
	border-radius: 4px;
	outline: none;
}

.slider_btn_div {
	position: absolute;
	right: 0.5rem;
	color: $pure-white;
	@media screen and (max-width: 676px) {
		top: 60px;
	}
}

.jobs-in-header {
	@media screen and (max-width: 676px) {
		margin-bottom: 70px;
		margin-top: 27px !important;
	}
}

.slider_wrapper {
	height: 100%;
	width: max-content;
	max-width: 300%;
	transform: translateX(0%);
	clear: both;
	transition: transform 0.5s;
	transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
	will-change: transform;
	counter-reset: item;
	scroll-behavior: smooth;
	scroll-snap-type: x mandatory;
}

.slider {
	height: 100%;
	width: 100%;
	display: flex;
}

.slide {
	height: 100%;
	width: 300px;
	display: contents;
}

.slide_innerDiv {
	padding: 2rem;
	display: flex;
	height: 100%;
	white-space: nowrap;
	align-items: center;
	@media screen and (max-width: 767px) {
		padding: 1rem;
	}
}

.slider_back_btn,
.slider_forward_btn {
	background: $medium-green;
	float: right;
	margin: 0.5rem 0.8rem;
}

.slider_view_btn {
	@extend .slider_forward_btn;
	width: 8rem;
	border-radius: 15px;
}

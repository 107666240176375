@import "../1-tools/variables";

@mixin basic-card {
	width: 100%;
	box-shadow: $shadow-default-4;
	border-radius: 12px;
	min-height: 150px;
	padding: 1rem;
	margin: 1.5rem auto;
}

.vector-dashboard-top-right {
	position: absolute;
	height: 630px;
	z-index: -1;
	right: 0px;
	top: 50px;

	@include media-breakpoint-down(lg) {
		display: none;
	}
}

.mobile-vect-gradient {
	position: absolute;
	left: 0px;
	z-index: -1;
	top: -100px;
}

.dashboard-home-container {
	position: relative;

	@include media-breakpoint-down(sm) {
		padding-top: 0px;
		margin-top: 50px;
	}
}

.panel-main-div {
	padding-top: 5px;
	padding-bottom: 5px;
	position: relative;
	z-index: 1;
	background-color: white;
	margin-top: 40px;
	border-radius: 10px;
	border: 1px solid #26bba2;
}

.tasks-for-panel {
	padding-top: 0px;
	padding-bottom: 0px;
	margin-left: 30px;
	margin: 15px;
	margin-top: 10px;
}

.progress-days-steps-div {
	@media screen and (max-width: 767px) {
		img {
			width: 30px;
		}

		p {
			font-size: 10px;
		}
	}
}

.dashboard-container {
	overflow-x: hidden;
	@include page-base-layout;
	height: 100vh;
	padding-bottom: 0px;

	@include media-breakpoint-down(md) {
		padding-bottom: 0px;
	}
	// @include media-breakpoint-down(sm) {
	// 	padding-bottom: 60px;
	// }

	section {
		z-index: 0;
	}
}

.welcome-message-header {
	// margin-top: 30px;

	@include media-breakpoint-down(lg) {
		&::after {
			content: none !important;
		}
	}

	@include media-breakpoint-down(sm) {
		color: $dark-blue;
		margin-top: 20px;
	}
}

.dashboard-head {
	@include page-subhead;
	font-size: 20px;

	&::after {
		width: 38% !important;
	}

	@include media-breakpoint-down(lg) {
		font-size: 16px;
		font-weight: 500;
	}

	&.pricepopupOn {
		@include media-breakpoint-down(lg) {
			margin-top: 20px;
		}
	}
}

.next-card-play-button {
	height: 1.75rem;
	margin-right: 7px;
}

.dashboard-progress-text {
	width: 60%;
}

.dashboard-graph-component {
	width: 100%;
	border-radius: 12px;
	height: 400px;
	margin-top: 1.5rem;
	margin-bottom: 2.5rem;
	// background-color: $white;
	display: flex;
	align-items: center;
	justify-content: center;

	.graph-tile {
		flex-basis: 60%;
		margin-right: 3rem;
		height: 100%;

		.chart {
			height: 84%;
		}

		canvas {
			background-color: #fff;
			border-radius: 11.9px;
			box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.3);
			width: 100% !important;
			height: 100% !important;
		}
	}

	.streak-tile {
		flex-basis: 40%;
		width: 100%;
		height: 100%;
	}

	@include media-breakpoint-down(md) {
		width: 100%;
		height: 450px;
		flex-direction: column;

		.graph-tile {
			// padding-right: 0px;
			margin-right: 0px;
			width: 100%;
			.chart {
				height: 90%;
			}
		}

		.dashboard-md-pills-tile {
			display: flex;
			flex-direction: row;
			width: 100%;
			align-items: center;
			justify-content: center;
			padding-bottom: 1rem;
		}
	}

	@include media-breakpoint-down(sm) {
		height: auto;
		background-color: transparent;
		// padding-bottom: 30px;

		.graph-tile {
			background: #fff;
			border-radius: 11.9px;
		}
	}
}

.dashboard-graph-controls {
	display: flex;
	height: 3rem;
	margin-bottom: 14px;

	.dashboard-graph-details {
		width: 70%;
		display: flex;
		align-items: center;
		padding-left: 1.5rem;
		padding-right: 1.5rem;
		border-radius: 6px;
		background: $pure-white;
		box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2);
		cursor: default;

		#selected-week {
			font-family: $montserrat-semi;
			font-size: 18px;
			font-weight: 600;
			font-stretch: normal;
			font-style: normal;
			line-height: 0.61;
			letter-spacing: normal;
			color: #30374e;
		}

		#average-time {
			margin-left: auto;
			font-family: $opensans-regular;
			font-size: 14px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 0.47;
			letter-spacing: normal;
			color: #6c7b8a;
		}
	}
}

.coming-up-content-div .btn-div {
	display: flex;
	justify-content: center;

	button {
		height: 3.5rem;
		width: 15rem;
		margin-top: -1.75rem;
		background: $white;
		color: $black;
		font-family: $montserrat-semi;
		font-size: 18px;

		@media only screen and (max-width: 768px) {
			width: 8rem;
			height: 3rem;
			font-size: 16px;
		}

		@media only screen and (max-width: 375px) {
			width: 8rem;
			height: 3rem;
		}

		svg {
			margin-right: 0.5rem;
			height: 22px;

			@media only screen and (max-width: 768px) {
				height: 18px;
			}
		}

		&.active {
			background: $medium-green;
			color: $white;

			svg {
				path {
					stroke: $pure-white;
					fill: $pure-white;
				}
			}
		}
	}
}
.dashboard-analytics-section {
	padding-top: 110px;
	padding-bottom: 30px;
	@include media-breakpoint-down(md) {
		padding-top: 60px;
	}
}

.dashboard-container .dashboard-analytics-section .dashboard-sub-head {
	font-family: $montserrat-regular;
	font-size: $font-size-16;
	margin-bottom: 8%;

	@include media-breakpoint-down(md) {
		margin-bottom: 14%;
	}
}

.coming-up-content-div {
	position: relative;
	// background: $lightest-green;
	// background: linear-gradient(91.71deg,#042B41, 1.24%, #0e6D88, 65.61%, #2f6983 98.55%);
	background: linear-gradient(120.85deg, #198370 36.26%, #74d3c3 83.27%, rgba(143, 225, 211, 0.75) 98.47%);
	color: #fff;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-top: 30px;
	overflow: hidden;
	// margin-top: 20px;
	// margin-top: 3%;

	.stepper-btn-div {
		@extend .btn-div;
		width: 100%;
		position: relative;
		margin-bottom: 40px;
		z-index: 2 !important;

		@include media-breakpoint-down(md) {
			margin-bottom: 80px;
		}

		button {
			cursor: pointer;
			display: flex;
			align-items: center;
			justify-content: center;
			background: $linear-blue-light !important;
			height: auto;
			width: auto;
			border-radius: 10px;
			margin-top: auto;
			// margin-bottom: 1.4rem;
			font-family: $montserrat-semi;
			font-size: 18px;
			padding: 10px 18px;

			&::after {
				content: none;
			}

			svg {
				height: 1.1rem;
			}
		}
	}

	.upcoming-path-container {
		position: relative;
		padding-bottom: 3%;
	}

	@include media-breakpoint-down(md) {
		.upcoming-path-container {
			padding-top: 0%;
			padding-bottom: 25px;
		}
	}
}

.job-slider-section {
	position: relative;
	// padding-top: 60px;
	background: $pure-white;

	.btn-div {
		margin-bottom: 20px;
	}
}

.learning-path-button-section {
	position: relative;
	display: flex;
	justify-content: center;
	padding-top: 0px;
	padding-bottom: 40px;
	background: transparent;

	@include media-breakpoint-down(sm) {
		padding-bottom: 60px;
	}

	.dashboard-lp-btn {
		background: $white;
		color: $dark-green;
		border: 2px solid $dark-green;
		padding: 13px 10px;
		border-radius: 12px;
		box-shadow: none;

		@include media-breakpoint-down(sm) {
			padding: 10px 7px;
		}
		&:hover {
			box-shadow: $shadow-default-4;
		}

		&::after {
			content: none;
		}

		svg {
			height: 1.3rem;
			margin-top: -10px;
			overflow: visible;

			path {
				stroke: $dark-green;
			}

			@include media-breakpoint-down(sm) {
				height: 1rem;
			}
		}

		span {
			// margin-left: -16px;
			font-family: $montserrat-semi;
			font-size: 17px;
			font-weight: 600;
		}
	}
}

.day-plan-content-div {
	@extend .coming-up-content-div;
	// background: $dark-green;
	background: linear-gradient(89.11deg, #042b41 10.34%, #0e6d88 82.52%, #2f6983 97.89%);
	position: relative;
	// padding-top: 120px;
	// margin-top: 0px;
	// z-index: -1 !important;
	// overflow: hidden;

	@include media-breakpoint-down(md) {
		// border-radius: 15px;
		overflow: hidden;
		box-shadow: 0 6px 12px 0 #b3b5bc;
		// padding-top: 125px;
	}

	.dashboard-sub-head {
		font-family: $opensans-regular;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		margin-bottom: 14px;

		@include media-breakpoint-down(md) {
			opacity: 0.7;
			line-height: 1.5rem;
			margin-bottom: 0px !important;
			font-weight: 400;
		}

		@include media-breakpoint-down(sm) {
			opacity: 0.7;
			font-weight: 300;
			font-size: 14px;
		}
	}

	.content-pill {
		color: $dark-blue;
		font-size: 16px;
		font-family: $montserrat-semi;
		font-weight: 500;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		padding: 9px 12px;
		background: $dark-green;
		border-radius: 5px;
		width: fit-content;
		display: flex;
		align-items: center;
		cursor: pointer;

		@include media-breakpoint-down(sm) {
			font-size: 14px;
			padding: 7px 10px;
		}
	}

	.day-plan-section-vector {
		position: absolute;
		bottom: -20px;
		left: 0px;
		height: 35%;
		@include media-breakpoint-down(sm) {
			height: auto;
			max-height: 35%;
			width: 75%;
		}
	}

	.stepper-btn-div .btn-fill {
		border-radius: 12px;
		background: $dark-blue !important;
		height: 62px;
		width: 260px;

		@include media-breakpoint-down(sm) {
			height: 46px;
			width: 160px;
			font-size: 14px;
		}
	}
}

.day-plan-content-div .upcoming-path-container {
	@include media-breakpoint-down(md) {
		padding-bottom: 14px;
	}
}

// DAY PLAN MODAL

.day-plan-modal-div {
	// display: flex;
	// flex-direction: column;
	// align-items: center;
	// background: $dark-green;
	background: linear-gradient(89.11deg, #042b41 10.34%, #0e6d88 82.52%, #2f6983 97.89%);
	position: fixed;
	height: 100vh;
	width: 100vw;
	overflow-y: auto;
	top: 0px;
	left: 0px;
	z-index: 5;
	transition: all 0.3s ease-in-out;

	@include media-breakpoint-down(sm) {
		border-radius: 15px;
		box-shadow: 0 6px 12px 0 #b3b5bc;
	}

	&::-webkit-scrollbar {
		display: none;
	}

	@include media-breakpoint-down(sm) {
		overflow-x: auto;
	}
	@include media-breakpoint-down(md) {
		overflow-x: auto;
	}

	.dayplan-modal-close-btn {
		height: 18px;
		width: 18px;
		position: absolute;
		top: 30px;
		right: 30px;
		z-index: 2;
		cursor: pointer;
		path {
			stroke: transparent;
			fill: #fff;
		}
		@include media-breakpoint-down(sm) {
			top: 20px;
			right: 20px;
		}
	}

	.dayplan-modal-head {
		@include media-breakpoint-down(sm) {
			font-size: 28px;
		}
	}

	.upcoming-path-container .dashboard-sub-head {
		font-family: $opensans-regular;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		margin-bottom: 2rem !important;

		@include media-breakpoint-down(md) {
			opacity: 0.7;
			line-height: 1.5rem;
			margin-bottom: 0px !important;
			font-weight: 400;
		}

		@include media-breakpoint-down(sm) {
			opacity: 0.7;
			font-weight: 300;
			font-size: 14px;
		}
	}

	.calender-icon {
		height: 34px;
		opacity: 0.5;
		margin-right: 14px;

		@include media-breakpoint-down(sm) {
			height: 26px;
		}
	}

	.content-pill {
		cursor: pointer;
		color: #022b4d;
		font-size: 16px;
		font-family: $montserrat-semi;
		font-weight: 500;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		padding: 9px 12px;
		background: $dark-green;
		border-radius: 5px;
		width: max-content;
		text-align: center;
		// margin-right: 2%;

		@include media-breakpoint-down(sm) {
			font-size: 14px;
			padding: 7px 10px;
		}
	}

	.btn-outline {
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 12px;
		color: $pure-white;
		border-color: $pure-white;
		background-color: transparent;
		height: 62px;
		width: 260px;
		font-size: 20px;

		svg {
			height: 1rem;
			margin-right: 7px;
		}
	}

	.day-plan-modal-div > .upcoming-path-container {
		position: relative;
		padding-bottom: 3%;

		@include media-breakpoint-down(lg) {
			padding-top: 0%;
			padding-bottom: 12%;
		}
	}

	.day-plan-section-vector {
		position: fixed;
		bottom: -40px;
		left: 0px;
		height: 40%;
		max-height: 320px;
	}
}

.dayweek-plan-main-text {
	text-transform: capitalize;
	margin-top: 35px;
	@media screen and (max-width: 600px) {
		font-size: 20px;
		margin-top: 15px;
	}
}

.day-plan-modal-div.close {
	transform: scale(0, 0);
}

.day-plan-modal-div.open {
	transform: scaleX(1, 1);
	border-radius: 0px;
	overflow-y: auto;

	.day-plan-section-vector {
		@include media-breakpoint-down(sm) {
			left: auto;
			right: 0px;
			top: 0px;
			bottom: auto;
			height: 25%;
			transform: rotate(180deg);
			z-index: -1;
		}
	}
}

.day-planner-modal {
	padding: 0px !important;
	border-radius: 15px;
	background: transparent;
}

.modal1-header {
	width: 100%;
	height: 70px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: white;
	background: linear-gradient(to right, #086788 0%, #26bba2 100%);
	background-image: url("/img/dayweekplanheader.svg");
	background-size: cover;
	font-size: 22px;
	@media screen and (max-width: 600px) {
		height: 40px;
	}
}

// ----------------------------	VERSION 1 STYLES	--------------------------------------

a,
a:hover,
a:focus,
a:active {
	text-decoration: none;
}

.notification-bar-text {
	text-align: center;
	padding-bottom: 2px;
	@media screen and (max-width: 500px) {
		font-size: 9px;
	}
}

.notification-bar-close-btn {
	height: 15px;
	width: 15px;
	position: absolute;
	top: 10px;
	right: 10px;
	z-index: 2;
	cursor: pointer;
	path {
		stroke: transparent;
		fill: #fff;
	}

	@media screen and (max-width: 345px) {
		height: 10px;
		width: 10px;
	}

	@media screen and (max-width: 575px) {
		width: 12px;
		height: 12px;
	}
}

.smiley-row {
	margin-top: 10px;
	height: 50px;
	justify-content: space-evenly;

	span {
		height: 46px;
		width: 50px;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
	}

	.half-course-rating {
		background: #26bba2;
		border-radius: 20px;
	}
}

.smiley-span {
	img {
		width: 35px;

		@media screen and (max-width: 767px) {
			width: 32px;
		}
	}
}

.smiley-span:hover {
	transition: opacity 150ms linear 100ms, transform 150ms ease-in-out 100ms;
	opacity: 1;
	transform: scale(1.1);
}

.timer-div {
	position: fixed;
	top: 0;
	z-index: 1051;
	width: 100%;
	background-color: $medium-green;
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 41px;
	color: white;
	font-weight: 600;

	@include media-breakpoint-down(md) {
		flex-direction: column;
		min-height: 50px;
	}

	@include media-breakpoint-down(sm) {
		min-height: 60px;
	}

	@media screen and (min-width: 346px) and (max-width: 633px) {
		font-size: 14px;
	}

	@media screen and (max-width: 345px) {
		font-size: 12px;
	}
}

.notification-bar {
	@extend .timer-div;

	& > span {
		display: contents;
	}

	@include media-breakpoint-down(md) {
		align-items: flex-start;
		padding-left: 80px;
		z-index: 4 !important;
	}

	@include media-breakpoint-down(sm) {
		padding-left: 0px;
		align-items: center;
	}
}

.no-zindex {
	z-index: 3;
}

.promo-btn {
	box-shadow: 0 5px 18px 0 rgba(28, 25, 28, 0.2);
	background-color: #086788;
	color: white;
	border: none;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 600;
	height: 26px;
	font-size: 16px;
	border-radius: 8px;
	position: absolute;
	right: 70px;
	text-decoration: none !important;

	&:hover {
		color: #fff;
		box-shadow: 0 5px 18px 0 rgba(48, 45, 58, 0.4);
	}

	&:focus,
	&:active {
		outline: none;
	}

	@media screen and (max-width: 345px) {
		height: 23px;
		width: 98px;
		font-size: 11px;
	}

	@media screen and (max-width: 575px) {
		position: relative;
		right: auto;
		font-size: 11px;
		width: 113px;
		height: 21px;
	}

	@media screen and (min-width: 768px) and (max-width: 768px) {
		font-size: 14px;
		padding: 8px;
		width: 150px;
	}
}

.notification-bar-btn {
	width: 200px;
	padding: 5px 10px;
	height: auto;
	@media screen and (min-width: 768px) and (max-width: 768px) {
		padding: 4px;
		width: 180px;
	}
}

.timeBlock {
	height: 30px;
	width: 50px;
	margin-right: 5px;
	padding: 2px;
	background-color: $dark-green;
	text-align: center;

	@media screen and (min-height: 300px) and (max-width: 633px) {
		height: 20px;
		width: 37px;
	}

	// @media screen and (max-height: 500px) {
	// 	width: 37px;
	// }
}

.timespan {
	display: inline-flex;
	// background-color: #99b92f59;
	padding-left: 15px;
	padding-right: 15px;

	@media screen and (max-width: 912px) {
		margin-left: 0px;
		padding-right: 0px;
	}
}

.missText {
	padding-left: 0px;
	padding-right: 0px;
	font-size: 15px;
	text-align: left;
	@media screen and (max-width: 500px) {
		font-size: 10px;
		text-align: center;
	}

	@include media-breakpoint-down(md) {
		margin-left: -20%;
	}

	@include media-breakpoint-down(sm) {
		margin-left: 0px;
	}

	@media screen and (max-width: 310px) {
		padding-left: 14px;
		font-size: 11px;
	}
}

.time-left-course {
	font-size: 33px !important;
	bottom: 1px;
	position: relative;
}

.icon-onboarding-welcome {
	height: 180px;
	width: 300px;
}

.lifetime-access-btn-row {
	@media screen and (max-width: 767px) {
		margin-top: -20px;
	}
}

.hr-referral-modal {
	color: gray;
	background-color: gray;
	margin-bottom: 0px;
	margin-top: 1rem;

	@media screen and (max-width: 767px) {
		margin-top: 0rem;
	}
}

.navbar-hours-left {
	font-size: 17px !important;
	color: #f2542d;
	font-family: sans-serif;
	font-weight: 800;
	bottom: 3px;
}

.price-modal-button-row {
	margin-bottom: 10px;
}

.modal-button {
	@media screen and (max-width: 600px) {
		margin-top: 50px;
	}
}

.header-text-div {
	height: 70px;
}

.header-text {
	@media screen and (max-width: 600px) {
		font-size: 17px;
	}
}

.underline-refer {
	text-decoration: underline;
	cursor: pointer;
	font-size: 18px;
	color: black;
	padding: 2px;
}

.modal-welcome-row {
	width: 100%;
	justify-content: center;

	.space-between {
		width: 20px;
	}
}

.guide-btn-container {
	display: flex;
	justify-content: flex-end;
}

.guide-btn {
	justify-content: flex-end;

	@media screen and (max-width: 767px) {
		width: 100%;
		margin-bottom: 10px;
	}
}

.message-href {
	color: black;
}

.refer-small-text {
	font-size: 15px;
	margin-bottom: 0px !important;

	@media screen and (max-width: 767px) {
		font-size: 9px;
		margin-top: 5px;
	}

	span {
		font-weight: 900;
		font-family: inherit;
		color: #c13535;
	}
}

.refer-number-text {
	color: #053848;
	font-size: 21px;
	margin-bottom: 0px !important;

	@media screen and (max-width: 767px) {
		font-size: 17px;
	}
}

.price-modal-text {
	font-family: $lato-semibold;
	color: #30364e;
	font-size: 20px;

	@media screen and (min-width: 767px) {
		margin-bottom: 30px;
		font-size: 26px;
	}
}

.price-modal-bg {
	background-repeat: no-repeat;
	background-size: cover;
	overflow: auto !important;
	border-bottom: none !important;

	@media screen and (min-width: 767px) {
		padding: 8rem;
	}
}

.loading {
	background-image: url("/img/image-loader.gif");
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

// .card-animations {
// 	animation-duration: 2s;
// 	animation-name: slidein;
// 	animation-iteration-count: 1;
// }

@keyframes slidein {
	from {
		margin-top: 0;
		opacity: 1;
	}

	to {
		margin-top: -300px;
		opacity: 0;
		display: none;
	}
}

.blur-card {
	filter: blur(2px);
	display: block;
	pointer-events: none;
	user-select: none;

	button:hover {
		transition: none;
		box-shadow: none;
	}
}

.lime-green {
	color: $lime-green;
}

.card-style-container {
	&.quiz-card {
		cursor: default;
	}

	cursor: pointer;
	transition: 0.5s;

	.text-small {
		font-size: 16px;
		line-height: 30px;
		// font-family: $lato-bold;
		color: #4d5367;

		a {
			text-decoration: none;
			color: #000;
			font-weight: 600;

			&:hover {
				color: grey;
			}
		}

		@include media-breakpoint-down(lg) {
			font-size: 14px;
			// margin-top: 10px;
			line-height: 20px;
		}
	}

	.icon-watch-template {
		width: 16px;
		height: 16px;
	}

	.icon-check-forward {
		width: 20px;
		height: 20px;
	}

	.card-image {
		width: 100%;
		height: 17vw;
		object-fit: cover;
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;

		@include media-breakpoint-down(lg) {
			height: 60vw;
		}
	}

	&.bs-card {
		position: relative;
		box-shadow: 0 10px 20px 10px rgba(0, 0, 0, 0.2);
	}

	&:hover {
		// box-shadow: 0 7px 18px 10px rgba(191, 191, 191, 0.2) !important;
		box-shadow: 0px 20px 20px 20px rgba(0, 0, 0, 0.2);
		transition: 0.5s;

		&.locked {
			box-shadow: rgba(191, 191, 191, 0.4) 0px 2px 10px 2px;
		}
	}

	.course-image {
		width: auto;
		height: 200px;

		@include media-breakpoint-down(lg) {
			width: 100%;
			height: 70vw;
		}
	}

	&.completed {
		box-shadow: rgba(191, 191, 191, 0.4) 0px 0px 2px 0px !important;

		&:hover {
			box-shadow: 0 3px 10px 5px rgba(191, 191, 191, 0.2) !important;
			transition: 0.5s;
		}
	}
}

.phases-image {
	width: auto;
	height: 90px;

	@include media-breakpoint-down(lg) {
		margin-right: 10px;
		height: 60px;
	}
}

@include media-breakpoint-down(lg) {
	.remove-margin-title {
		margin-bottom: 10px;
		margin-top: 10px;
	}

	.vertical-stepper-mobile-pd {
		padding-left: 1px;
	}
}

.text-light-color {
	color: #ccc;
	font-size: 14px;
}

.text-title {
	font-family: $opensans-bold;
	color: #30364e;
	font-size: 20px;
	margin-bottom: 5px;

	@include media-breakpoint-down(lg) {
		font-size: 16px;
		margin-top: 10px;
		margin-bottom: 10px;
	}
}

.card-align {
	display: flex;
	align-items: center;
	justify-content: center;
}

.share-card {
	width: 29px;
}

.bookmark-card {
	width: 40px;
	margin-left: 3px;
	position: relative;
	z-index: 0;
}

.article-element {
	margin-bottom: 5px;
}

.panel-fade {
	animation-name: fadeInDown;
	animation-duration: 1s;
}

@keyframes fadeInDown {
	0% {
		opacity: 0;
		transform: translateY(-1.25em);
	}

	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

.tag {
	border: 1px solid rgba(45, 199, 109, 0.03);
	font-size: 14px;
	border-radius: 20px;
	padding: 3px 10px;
	max-width: fit-content;
}

.tag-one {
	@extend .tag;
	color: #4bcc68;
	background-color: #e6f8ea;
}

.tag-two {
	@extend .tag;
	color: #086788;
	background-color: #f0fcff;
}

.module-style-container {
	position: relative;
	cursor: pointer;

	.module-background {
		color: #999999;
		background-color: #fafafa;
		width: 108px;
		height: 108px;
		border-radius: 50%;
		position: absolute;
		top: 26px;
		left: 26px;
		line-height: 20px;
		transition: 0.5s;
		font-size: 16px;
		padding: 20px;

		@include media-breakpoint-down(lg) {
			width: 78px;
			height: 78px;
			font-size: 12px;
			padding: 5px;
			top: 21px;
			left: 21px;
		}

		&:hover {
			background-color: #e1f5f2;
			color: #1c8976;
			transition: 0.5s;
		}
	}

	.icon-crown-de {
		height: 32px;
		width: 32px;
		position: absolute;
		top: 5px;
		left: 25px;

		@include media-breakpoint-down(lg) {
			height: 23px;
			width: 23px;
			top: 3px;
			left: 22px;
		}
	}
}

.level-name-title {
	font-size: 18px;
	color: #30364e;
	font-family: $lato-semibold;

	@include media-breakpoint-down(lg) {
		font-size: 16px;
	}
}

.level-sub-title {
	font-size: 14px;
	color: #4d5367;

	@include media-breakpoint-down(lg) {
		font-size: 12px;
	}
}

.tag-for-level {
	color: #db3d15;
	font-size: 16px;
	background-color: #ffe3dd;
	padding: 5px 12px;
	border-radius: 35px;
}

.dashboard-inside-footer {
	padding: 15px 20px;
	background-color: #fafafa;
	box-shadow: 0 -2px 2px 0px rgba(0, 0, 0, 0.1);
}

.tooltip-style-container {
	background-color: #74d3c3 !important;

	.text-main {
		font-size: 16px;
		color: #30364e;
		font-family: $opensans-bold;
	}

	.text-sub {
		font-size: 14px;
		color: #30364e;
	}

	&:after {
		border-top-color: #74d3c3 !important;
	}
}

// TOASTR CSS
.Toastify__toast {
	border-radius: 9px !important;
	justify-content: center !important;
}

.Toastify__toast--success {
	background: black !important;
}

.Toastify__toast-body {
	text-align: center !important;
	font-size: 17px !important;
	// background-color: #0c0b0b !important;
	height: 40px !important;
	display: flex !important;
	align-items: center !important;
	justify-content: center !important;
	border-radius: 30px;
}

.Toastify__progress-bar {
	height: 0px !important;
}

.Toastify__close-button {
	margin: -3px;
}

.card-input-element {
	display: none;
}

.card-input {
	margin: 0px;
	padding: 25px;
	box-shadow: 1px 2px 6px 2px lightgrey;
	background-color: white;
}

.card-input:hover {
	cursor: pointer;
}

.card-input-element:checked + .card-input {
	box-shadow: 1px 2px 6px 2px red;
}

.panel-heading,
.panel-body {
	color: black;
}

// .fixed-bottom {
// 	top: 75%;
// }
@media screen and (max-width: 800px) {
	.share-modal {
		top: -125%;
		left: 46%;
	}
}

.up-next-title {
	margin-bottom: 10px;
	margin-top: 10px;

	@media screen and (max-width: 767px) {
		margin-top: 16px;
	}
}

.learning-path-text-hover {
	display: none;
	width: 300px;
	padding: 10px;
	z-index: 12;

	@media screen and (max-width: 767px) {
		left: 30px;
	}
}

.up-next-title:hover .learning-path-text-hover {
	transition: 0.5s;
	display: block;
}

.hover-me {
	display: none;
}

//Dashboard Expansion Panel For Rating
#dashboardContainerId {
	position: relative;
}

.rating-panel-div {
	width: 400px;
	position: fixed;
	right: 30px;
	z-index: 24;
	bottom: 100px;
	display: flex;
	align-items: center;
	justify-content: center;

	.rating-panel {
		background: linear-gradient(to right, #086788 0%, #26bba2 100%);
		height: 75px;
		width: 80%;
		border-radius: 20px;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 25px;
		transition: all 0.3s;

		p {
			color: white;
			text-align: center;
			font-size: 18px;
		}
	}

	.panel-cross {
		width: 15%;
		border-radius: 52px;
		background-color: #26bba2;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-left: 10px;
		font-size: 40px;
		color: white;
		height: 75px;
		cursor: pointer;
	}
}

.rating-expanded-panel {
	position: fixed;
	right: 30px;
	bottom: 100px;
	z-index: 10;
	display: flex;
	justify-content: center;
	align-items: flex-end;
	width: 600px;
	height: auto;
	// 	transition: top 0.8s left 0.8s ease-in;
	// 	-moz-transition: top 0.8s, left 0.8s;
	// -webkit-transition:top 0.8s, left 0.8s;
	// -o-transition: top 0.8s, left 0.8s;
	transition: width 0.8s linear 0.5s;
	// -o-transition: left 0.4s ease-in-out;
	// -ms-transition: left 0.4s ease-in-out;
	// -moz-transition: left 0.4s ease-in-out;
	// -webkit-transition: left 0.4s ease-in-out;
	// transiti

	.rating-panel {
		height: 700px;
		color: white;
		width: 80%;
		background: linear-gradient(to right, #086788 0%, #26bba2 100%);
		border-radius: 10px;
		padding: 10px;

		p {
			color: white;
			text-align: center;
			font-size: 18px;
		}
	}

	.panel-cross {
		width: 12%;
		border-radius: 52px;
		background-color: #26bba2;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-left: 10px;
		font-size: 35px;
		color: white;
		height: 70px;
		cursor: pointer;
		font-weight: 600;
		font-family: sans-serif;
	}
}

.upcoming-path-calender {
	display: flex;
	align-items: center;
}

.day-week-btn {
	justify-content: space-evenly !important;
	width: 420px !important;
	font-size: 18px !important;

	@media screen and (max-width: 600px) {
		width: 230px !important;
		font-size: 9.4px !important;
		svg {
			height: 0.78rem !important;
		}
	}

	@media screen and (max-width: 420px) {
		width: 230px !important;
		font-size: 9.8px !important;
		svg {
			height: 0.7rem !important;
		}
	}

	@media screen and (max-width: 375px) {
		width: 230px !important;
		font-size: 10px !important;
		svg {
			height: 0.8rem !important;
		}
	}

	@media screen and (max-width: 320px) {
		width: 230px !important;
		font-size: 9px !important;
		svg {
			height: 0.7rem !important;
		}
	}
}

.day-week-planner {
	border: 1px solid black;
	border-radius: 12px;
	height: auto;
	width: 47%;
	padding: 10px;
	cursor: pointer;
	height: 200px;

	@media screen and (max-width: 600px) {
		width: 100%;
		height: auto;
	}

	& > p,
	& > div {
		font-size: 15px;
		font-weight: 500;
		font-family: sans-serif;

		select {
			padding: 5px;
			border: 1px solid black;
			border-radius: 10px;
		}

		@media screen and (max-width: 768px) {
			font-size: 14px;
		}
	}

	h2 {
		@media screen and (max-width: 360px) {
			font-size: 22px;
		}
	}

	.day-week-planner-small-text {
		font-size: 12px;
		padding-top: 0px;

		@media screen and (max-width: 600px) {
			padding-top: 50px;
		}

		@media screen and (max-width: 360px) {
			padding-top: 30px;
		}
	}
}

.day-week-planner-own-pace-box {
	margin-bottom: 25px;
}

.highlight-box {
	box-shadow: -2px 5px 3px 2px #086788;
}

.day-week-plan-reset-counter {
	font-size: 11px;
	color: white;
	font-family: sans-serif;
	font-weight: 400;
	text-decoration: underline;
	padding-top: 3px;
	text-align: right;
}

.day-week-planner-personalised-text {
	padding-bottom: 15px;
}

.close-day-week-modal {
	@media screen and (max-width: 600px) {
		margin-top: 65px;
	}

	@media screen and (max-width: 375px) {
		margin-top: 90px;
	}

	@media screen and (max-width: 320px) {
		margin-top: 20px;
	}
}

.day-week-plan-progress {
	width: 35%;
	margin-left: 14px;

	p {
		padding-bottom: 3px;
		color: white;
		text-align: center;
	}
}

.day-week-completed-text {
	color: white;
	font-weight: 550;
}

.progress-bar-dayweek {
	height: 11px !important;
	position: relative;
	background: linear-gradient(rgba(#fff, 0.8) 0%, rgba(#c4c4c4, 0.8) 100%);
	background-color: transparent !important;
	// opacity: 0.8;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	// box-shadow: offset 0px 4px rgba(0, 0, 0, 0.25);
	left: 15px;

	&.black > .progress-bar {
		font-size: 10px;
		// color: $pure-black;
	}

	@include media-breakpoint-down(md) {
		width: 50%;
		left: 0px;
	}

	@include media-breakpoint-down(sm) {
		width: 75%;
	}
}

.day-week-modal-header {
	margin-top: 1.2em;
	// transition: all 3s;
}

.animate-enter {
	opacity: 0.01;
}

/* ending ENTER animation */
.animate-enter-active {
	opacity: 1;
	transition: all 300ms ease-in-out;
}

/* starting EXIT animation */
.animate-exit {
	opacity: 1;
}

/* ending EXIT animation */
.animate-exit-active {
	opacity: 0;
	transition: all 300ms ease-in-out;
}

// TOOL TIP CSS
.led-box {
	height: 30px;
	margin: 10px 0;
	float: left;
	position: absolute;
	top: 0px;
	right: 200px;
	z-index: 1;

	@media screen and (max-width: 768px) {
		top: 75px;
		right: 270px;
	}
	@media screen and (max-width: 500px) {
		top: 130px;
		right: 25px;
	}
	@media screen and (max-width: 375px) {
		top: 117px;
		right: 40px;
	}
}

.led-box p {
	font-size: 12px;
	text-align: center;
	margin: 1em;
	@media screen and (max-width: 600px) {
		font-size: 12px !important;
	}
}

.led-red {
	margin: 0 auto;
	width: 30px;
	height: 30px;
	background-color: #3ce2c6;
	border-radius: 100%;
	box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #26bba2 0 -1px 9px, #0b846f 0 2px 12px;
	animation: blinkRed 0.8s infinite;
	@include media-breakpoint-down(sm) {
		width: 28px;
		height: 28px;
	}
}

@-webkit-keyframes blinkRed {
	from {
		background-color: #29d5b8;
	}

	50% {
		background-color: #2adbbf;
		box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #249583 0 -1px 9px, #249583 0 2px 0;
	}

	to {
		background-color: #11816e;
	}
}

@-moz-keyframes blinkRed {
	from {
		background-color: #29d5b8;
	}

	50% {
		background-color: #2adbbf;
		box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #249583 0 -1px 9px, #249583 0 2px 0;
	}

	to {
		background-color: #11816e;
	}
}

@-ms-keyframes blinkRed {
	from {
		background-color: #29d5b8;
	}

	50% {
		background-color: #2adbbf;
		box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #249583 0 -1px 9px, #249583 0 2px 0;
	}

	to {
		background-color: #11816e;
	}
}

@-o-keyframes blinkRed {
	from {
		background-color: #29d5b8;
	}

	50% {
		background-color: #2adbbf;
		box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #249583 0 -1px 9px, #249583 0 2px 0;
	}

	to {
		background-color: #11816e;
	}
}

@keyframes blinkRed {
	from {
		background-color: #29d5b8;
	}

	50% {
		background-color: #2adbbf;
		box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #249583 0 -1px 9px, #249583 0 2px 0;
	}

	to {
		background-color: #11816e;
	}
}

.led-text-box {
	position: absolute;
	margin-top: 25px;
	background: black;
	width: 400px;
	border-radius: 10px;
	left: -30px;
	display: none;
	z-index: 2;

	@media screen and (max-width: 767px) {
		width: 300px;
	}

	@media screen and (max-width: 1440px) {
		width: 300px;
	}

	&.true {
		display: block;
	}

	p {
		font-size: 16px;
		font-family: sans-serif;
		font-weight: 400;
		color: white;
		margin: 10px;

		@media screen and (max-width: 400px) {
			font-size: 12px;
		}
	}
}

.led-text-box:after {
	content: " ";
	position: absolute;
	left: 35px;
	top: -15px;
	border-top: none;
	border-right: 15px solid transparent;
	border-left: 15px solid transparent;
	border-bottom: 15px solid black;

	@media screen and (max-width: 767px) {
		right: 35px;
		left: initial;
	}
}
.quiz-text-box {
	display: block;
}

.quiz-text-box::after {
	@media screen and (max-width: 767px) {
		left: 35px;
		right: initial;
	}
}

.mobile-led-text-box {
	@media screen and (max-width: 767px) {
		left: -230px;
		z-index: 12;
	}
}

.led-quiz-box {
	left: 20px;
	top: 55px;
	z-index: 2;
	float: none;
	right: inherit;
	cursor: pointer;
}

.day-week-planner-modes {
	display: flex;
	justify-content: space-around;

	@media screen and (max-width: 600px) {
		display: block;
	}
}

.black-text {
	color: black;
}

// content timer

.content-timer-div {
	position: absolute;
	top: -55px;
	width: 120%;
	background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 139, 0, 1) 100%);
	height: 50%;
	z-index: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;

	.time-elapsed-text {
		color: white;
		font-weight: 500;
		font-size: 20px;
		text-transform: capitalize;
		padding-right: 20%;
	}
}

.react-timer {
	padding: 0px;
	display: flex;

	button {
		display: none;
	}

	h3 {
		color: white !important;
		font-weight: 700 !important;
	}
}

.modal1 {
	width: 100%;
	background-color: white;
	// padding:2px;
	height: 100%;
}

.mentor-quiz-close {
	position: absolute;
	top: 0px;
	left: 0px;
	background-color: white;
	border-radius: 50px;

	p {
		color: black;
		font-family: sans-serif;
		font-weight: 600;
		font-size: 20px;
	}
}

.mentorship-btn-row {
	display: flex;
	justify-content: space-between;
}

.mentorship-btn {
	margin: 5px;
}

//Days Behind Modal CSS
.db-main-text {
	color: #0a4b61;
	font-size: 40px;
	letter-spacing: 0.5px;
	font-family: inherit;
	font-weight: 700;
	margin-bottom: 5px;

	@media screen and (max-width: 500px) {
		font-size: 20px;
	}
}

.db-main-desc {
	color: black;
	font-size: 20px;
	margin-bottom: 5px;
	font-family: inherit;
	letter-spacing: 0.5px;

	@media screen and (max-width: 500px) {
		font-size: 12px;
	}
}

.db-sub-desc {
	font-size: 15px;
	margin-bottom: 10px;
	letter-spacing: 0.5px;
	font-family: inherit;
	color: black;
}

.db-main-div {
	padding: 35px;
}

.db-modal-body {
	border-radius: 15px;
	justify-content: left;
	align-items: inherit;
}

.db-sad-smiley {
	width: 200px;
	display: flex;
	margin-bottom: 20px;
}

.db-btns {
	display: block;
	margin-top: 30px;

	.db-change-plan-text {
		color: #696262;
		margin-top: 10px;
		cursor: pointer;
		font-size: 15px;
	}
}

.stepper-btn-div.day-plan-section {
	@include media-breakpoint-down(md) {
		margin-bottom: 40px;
		margin-top: 40px;
	}
}

.learning-path-button-section {
	@include media-breakpoint-down(md) {
		margin-bottom: 60px;
	}
}

.close-btn-seminar-reminder {
	display: none;
	@media screen and (max-width: 500px) {
		display: block;
		padding-left: 10px;
		padding-right: 10px;
		background-color: #0a9e90;
		border-radius: 15px;
	}
}

._2-ZbH {
	float: right;
	// margin-top: 20px;
	// margin-bottom: 10px;
}

.lp-sidebar-learning {
	cursor: pointer;
	margin-top: 14px;
}

//dayweek plan css

.lp-sidebar-learning {
	position: absolute;
	top: 10px;
	right: 14px;
	cursor: pointer;
	color: $pure-white;

	.learning-goal {
		font-size: 13px;
		padding-bottom: 5px;
		letter-spacing: 0;
		cursor: pointer;
		margin-top: 5%;
		@media screen and (max-width: 600px) {
			font-size: 10px;
		}

		span {
			font-weight: 600;
			text-decoration: underline;
		}
	}

	.learning-goal-result {
		font-size: 15px;
		float: right;
		cursor: pointer;
		@media screen and (max-width: 600px) {
			font-size: 12px;
		}
	}
}

@media only screen and (min-device-width: 320px) and (max-device-width: 568px) {
	.Toastify__toast {
		border-radius: 0px !important;
	}
}

.upcoming-path-container > label > input[type="checkbox"]._3maKS {
	appearance: none !important;
	-webkit-appearance: none;
}

.progress-bar {
	&.progress-color {
		// background-color: #f2cd00;
		background-color: rgba($pure-white, 0.9);
		color: $dark-blue;
	}
}

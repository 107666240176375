.accordion {
	width: 100%;
	height: 60px;
	border-radius: 8px;
	box-shadow: 0 14px 26px 0 rgba(181, 181, 181, 0.4);
	background-color: #fff;
	// margin-bottom: 30px;
	margin-top: 10px;
	display: flex;
	position: relative;
	align-items: center;
	overflow: hidden;
	padding-left: 5%;
	font-family: $montserrat-semi;
	font-weight: 600;
	font-size: $font-size-24;
	color: $white;
	z-index: 1;
	cursor: pointer;

	span {
		cursor: pointer;
	}

	@include media-breakpoint-down(md) {
		font-size: 20px;
	}

	span {
		z-index: 1;
	}
}

.top-row-element:nth-child(1),
.top-row-element:nth-child(2),
.top-row-element:nth-child(3) {
	margin-top: 25px;

	@include media-breakpoint-down(md) {
		margin-top: 2%;
	}
}

.accordion-vector {
	position: absolute;
	height: 100%;
	left: -30px;
	z-index: 0;
	top: 0px;

	&.Discovery {
		width: 44%;
	}

	&.Learning {
		width: 55%;
	}

	&.Achieve {
		width: 90%;
	}

	@include media-breakpoint-down(md) {
		&.Discovery,
		&.Learning,
		&.Achieve {
			width: 90%;
		}
	}

	@media only screen and (max-width: 767px) {
		width: 90%;
	}
}

.accordion-card-container {
	max-height: 0px;
	transition: max-height 3s ease-in-out;
	overflow: hidden;
	width: 107%;
	border-radius: 11px;
	background-color: rgba(193, 193, 193, 0.3);
	backdrop-filter: blur(10px);
	height: auto;
	margin-bottom: 40px;

	@include media-breakpoint-down(md) {
		width: 106%;
	}

	@include media-breakpoint-down(sm) {
		width: 105%;
	}

	a {
		text-decoration: none;
	}

	a:hover {
		text-decoration: none;
	}
}

.accordion-card-container.false {
	padding: 0px;
	margin-top: 0px;
	height: 0px;

	@include media-breakpoint-down(sm) {
		margin-left: -2.6%;
	}
}

.accordion-card-container.true {
	padding: 1rem 2rem 1rem 2rem;
	margin-top: -2.5%;
	margin-left: -3%;
	height: auto;
	max-height: 5000px;

	@include media-breakpoint-down(md) {
		margin-top: -4.5%;
		margin-left: -2.6%;
		height: auto;
	}

	@include media-breakpoint-down(sm) {
		padding: 1rem 1.2rem 1rem 1.2rem;
		margin-top: -10%;
		margin-left: -2.6%;
		height: auto;
	}
}

.accordion-icon {
	position: absolute;
	height: 1.8rem;
	margin-left: 0px;
	z-index: 1;

	&.Discovery {
		margin-left: 25%;
	}

	&.Learning {
		margin-left: 39%;
	}

	&.Achieve {
		margin-left: 64%;
	}

	@include media-breakpoint-down(sm) {
		&.Discovery {
			margin-left: 55%;
		}

		&.Learning {
			margin-left: 60%;
		}

		&.Achieve {
			margin-left: 58%;
		}
	}
}

.accordion-arrow-icon {
	height: 16px;
	width: 19px;
	margin-left: auto;
	margin-right: 1.5rem;
	transition: transform 0.3s ease-in-out;

	&.false {
		transform: rotate(0deg);
	}

	&.true {
		transform: rotate(180deg);
	}
	&.center {
		margin: 0px;
	}

	@media (max-width: 600px) and (min-width: 412px) {
		margin-top: 35px;
	}
}

.progress-days-icon {
	position: absolute;
	right: 0;
	transform: rotate(0deg);

	@media screen and (max-width: 767px) {
		right: -20px;
	}
}

.icon-fade {
	transform: rotate(180deg);
	animation: 1s;
}

.task-div {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 90%;
	@media screen and (max-width: 676px) {
		display: block;
		width: auto;
	}
}

.progress-days-step-days {
	width: 60px;
	font-weight: 550;
	@media screen and (max-width: 767px) {
		width: 50px;
	}
}

.progress-days-step-img {
	width: 40px;
}

.last-task-row {
	padding-bottom: 0px;
}

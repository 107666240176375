@import "../1-tools/variables";

.learn-page-container {
	@include page-base-layout;
	padding-top: 100px;

	@include media-breakpoint-down(sm) {
		padding-top: 24%;
		padding-bottom: 60px;
		position: relative;
	}

	.vector-learn-top-left {
		position: absolute;
		height: 25rem;
		z-index: 0;
		left: 0px;
		top: 0px;
		@include media-breakpoint-down(md) {
			height: 20rem;
		}
	}

	.vector-learn-bottom-right {
		@include media-breakpoint-down(sm) {
			position: absolute;
			bottom: 0px;
			right: 0px;
			width: 60%;
			z-index: 0;
		}
	}

	.vector-green-circle {
		max-width: 30%;
		position: absolute;
		bottom: 0px;
		overflow: visible;
		right: -10%;
		transform: rotate(90deg);
		opacity: 0.7;

		@include media-breakpoint-down(sm) {
			display: none;
		}
	}

	section {
		z-index: 0;
	}
}
.learn-page-container h2 {
	margin-bottom: 4%;
	margin-top: 5%;
	font-family: $montserrat-bold;
	font-weight: 900;

	&.pricepopupOn {
		@include media-breakpoint-down(md) {
			margin-top: 18%;
		}
	}

	@include media-breakpoint-down(md) {
		margin-top: 16%;
	}
	@include media-breakpoint-down(sm) {
		margin-top: 9%;
	}
}
.learn-page-container h4 {
	font-size: 1.3rem;
}
.learn-sub-head {
	@include page-subhead;
}

p {
	margin-bottom: 0;
}

$small: 320px;

.profile-container {
	@include page-base-layout;
	@media screen and (max-width: 420px) {
		padding-top: 80px;
	}
	@media screen and (min-width: 1920px) {
		&.pricepopupOn {
			padding-top: 120px !important;
		}
	}
}

.ribbon {
	font-size: 10px;
	font-weight: bold;
	color: #fff;
	text-transform: uppercase;
	text-align: center;
	line-height: 20px;
	transform: rotate(-61deg);
	-webkit-transform: rotate(-61deg);
	width: 70px;
	display: block;
	padding-left: 8px;
	background: linear-gradient(#f70505 0%, #8f0808 100%);
	box-shadow: 0 3px 10px -5px black;
	position: absolute;
	top: 19px;
	height: 20px;
	left: -14px;
}

.f-container {
	display: inline-flex;
	flex-wrap: wrap;
	font-size: 16px;
	width: 307px;
	@media screen and (max-width: 360px) {
		width: 220px;
	}
}

.f-div {
	padding: 5px;
	border: 1px solid gainsboro;
	margin-left: 5px;
	margin-top: 3px;
	border-radius: 5px;
	margin-bottom: 2px;
	font-size: 12px;
	font-family: sans-serif;
	cursor: pointer;
}

.badge {
	background-color: #25bba2;
	width: 15px;
	height: 15px;
	border-radius: 21px;
	margin-left: 4px;
	color: white;
	text-align: center;
	font-size: 10px;
	display: inline-block;
	cursor: pointer;
}

.activef {
	margin-left: 5px;
	margin-top: 3px;
	border-radius: 5px;
	margin-bottom: 2px;
	padding: 5px;
	box-shadow: 0 5px 20px 5px rgba(191, 191, 191, 0.4);
	border: 1px solid #25bba2;
	transition: 0.5s;
}

.my-profile-container {
	position: relative;
}

.bubble-img {
	position: absolute;
	z-index: 0;
	right: 0;
	top: 800px;
	width: 30%;

	@media screen and (max-width: 768px) {
		top: 85%;
		width: 60%;
	}
}

.select-gender-right-side {
	width: 15%;
}

.edit-part {
	@media screen and (max-width: 768px) {
		height: 130px;
	}
	@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
		position: absolute;
		top: 20px;
	}
	@media only screen and (min-device-width: 812px) and (max-device-width: 812px) and (orientation: landscape) {
		position: absolute;
		right: 0px;
		top: 60px;
	}

	&.pricepopupOn {
		@media screen and (max-width: 768px) {
			top: 27px;
		}
	}
}

.profile-top-part {
	&::before {
		content: "";
		display: block;
		// height: 100%;
		width: 100%;
		position: absolute;
		background: rgba(grey, 0.3);
		top: 0px;
		left: 0px;
	}
	background-image: url("/img/default-cover-image.png");
	width: 100%;
	background-blend-mode: color;
	background-position: center;
	background-size: cover;
	position: relative;
	height: 400px;
	@media screen and (max-width: 1440px) and (min-width: 1440px) {
		height: 320px;
	}
	@media screen and (max-width: 768px) {
		flex-direction: column-reverse !important;
		height: auto;
		background: white;
		padding: 0px;
	}
}

.upper-part-row {
	justify-content: center;
	@media screen and (max-width: 768px) {
		flex-direction: column-reverse !important;
	}
}

.profile-username {
	font-weight: 700;
	font-size: 36px;
	font-family: $montserrat-bold;

	@media screen and (max-width: 768px) {
		font-size: 28px;
		margin-top: 5px;
	}
}

.profile-picture-column {
	display: flex;
	margin-top: 10%;
	justify-content: center;

	@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
		margin-top: 29%;
	}
	@media only screen and (min-device-width: 1024px) and (max-device-width: 1024px) and (orientation: portrait) {
		margin-top: 15%;
	}
	@media screen and (max-width: 1440px) and (min-width: 1440px) {
		margin-top: 6%;
	}
	@media screen and (max-width: 900px) and (min-width: 769px) {
		margin-top: 20%;
	}
	@media screen and (min-width: 576px) and (max-width: 767px) {
		margin-top: 30%;
	}
	@media screen and (max-width: 768px) {
		background: #edfffc;
	}
	@media screen and (max-width: 576px) {
		margin-top: 4%;
	}
	@media screen and (max-width: 700px) {
		// margin-top: 30px;
		background: #edfffc;
		justify-content: center;
	}
}

.edit-cover-image {
	position: absolute;
	bottom: 0px;
	border: 1px solid #fff !important;

	@media screen and (max-width: 768px) {
		font-size: 0px !important;
		bottom: auto;
		top: 20px;
		padding-right: 4px !important;
	}
}

.cover-text {
	display: block;
	margin-left: auto;
}

.error-text {
	margin-top: 8px;
	color: red;
	font-family: inherit;
	font-size: 13px;
}

.profile-center {
	width: 56%;
}

.user-profile-image {
	border-radius: 50%;
	height: 100px;
	width: 100px;
}

.user-details-div {
	margin-left: 1.5rem;
	color: white;
	padding-top: 60px;
	svg {
		height: 1.5rem;
		fill: #998;
		margin-right: 12px;
		@media screen and (max-width: 768px) {
			margin-right: 5px;
		}
	}
	@media screen and (max-width: 768px) {
		color: #000;
		padding-top: 80px;
		margin-left: 0px;
	}
	@media only screen and (max-width: 768px) and (min-width: 700px) {
		margin-left: auto;
		display: block;
		margin-right: auto;
	}
	@media screen and (max-width: 1440px) and (min-width: 1440px) {
		padding-top: 45px;
	}
	@media screen and (max-width: 900px) and (min-width: 769px) {
		padding-top: 20px;
	}
}

.background-light-grayish-cyan {
	background-color: #e1f5f2;
}

.icon-user-study {
	height: 68px;
	width: 68px;
}

.user-input-skills-row {
	justify-content: space-between;
	@media screen and (max-width: 700px) {
		height: 85px !important;
	}

	p {
		font-weight: 500;
		color: red;
		@media screen and (max-width: 700px) {
			font-size: 10.5px !important;
		}
	}
}

.work-place-type-text {
	font-weight: 400 !important;
	font-size: 13.5px !important;
}

.profile-down-container {
	margin-left: 10%;
	margin-right: 10%;
	@media screen and (min-width: 1280px) {
		margin-left: 8%;
		margin-right: 8%;
	}
	@media screen and (min-width: 700px) {
		margin-left: 5%;
		margin-right: 5%;
	}
	@media screen and (max-width: 700px) {
		margin-left: 0px;
		margin-right: 0px;
	}
}

.timeline-item-dateinner {
	@media screen and (max-width: 575px) {
		font-size: 14px !important;
	}
	@media screen and (max-width: 360px) {
		font-size: 12px !important;
	}
}

.bounce-in {
	@media screen and (max-width: 700px) {
		margin-left: 0px;
		padding-top: 15px;
	}
}

// MY PROFILE TAB CS
.my-profile-head {
	justify-content: space-between;
	align-items: center;
	margin-bottom: 12px;
	margin-left: 0 !important;
	margin-right: 0 !important;

	h5 {
		font-family: sans-serif;
		color: #30374e;
		font-size: 20px;
		font-weight: 500;
		letter-spacing: 1px;
		margin-bottom: 0px;
		@media screen and (max-width: 500px) {
			font-size: 18px;
		}
		@media screen and (max-width: 320px) {
			font-size: 15px;
		}
	}
}

.css-1hwfws3 {
	height: 30px;
}

.basic-information-main-div,
.social-profile-main-div,
.skills-main-div {
	margin-bottom: 40px;
}

.basic-row {
	justify-content: space-between;
	background-color: white !important;
	border-radius: 8px;

	textarea {
		height: 85%;
		border-radius: 10px;
		width: 100%;
		padding: 15px;
		margin: 10px;
		font-family: sans-serif;
		font-weight: 500;
		font-size: 14.5px;
	}
}

.objective-edit-div {
	background: #fff;
	width: 100%;
	margin-bottom: 15px;
	height: 70%;
	border-radius: 8px;

	p {
		font-family: sans-serif;
		font-weight: 500 !important;
	}
}

.objective-basic-row {
	box-shadow: none !important;
}

.save-obj-button {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

.obj-buttons {
	display: flex;
	align-items: center;
	justify-content: center;
}

.user-objective-row {
	height: 250px !important;

	.basic-row {
		height: 100% !important;
	}
}

.user-obj {
	height: auto !important;
	padding-top: 25px;
	padding-bottom: 25px;
}

.title-no-activity {
	font-family: sans-serif !important;
	font-size: 30px !important;
	font-weight: bold !important;
	padding: 10px;
}

#another-job-button {
	margin-top: 15px;
	margin-bottom: 15px;
}

.cover-letter-input {
	width: 90%;
	border-radius: 10px;
	height: 45px;
	padding: 10px;
	box-shadow: none;
	border: 1px solid black;
	@media screen and (max-width: 700px) {
		font-size: 14.5px;
		height: 35px;
	}
}

.cover-letter-div {
	height: 100px !important;
	display: flex;
	align-items: center;
	justify-content: center;
	@media screen and (max-width: 700px) {
		height: 80px !important;
	}
	.objective-basic-row {
		@media screen and (max-width: 700px) {
			padding-top: 0px !important;
		}
		.social-image-link {
			width: 22px;
			cursor: pointer;
		}
	}
}

.cover-letter-edit-div {
	margin-bottom: 0px;
	display: flex;
	align-items: center;
	a {
		display: flex;
		margin-left: 10px;
		margin-top: -3px;
	}
	img {
		width: 20px;
	}
}

.basic-information-div,
.social-profile-div,
.achievements-div,
.skills-div {
	background: #fff;
	height: auto;
	width: 102%;
	margin-left: -1%;
	box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.2);
	border-radius: 8px;
	background-color: white;

	.top-skills-head {
		color: #6a6a6a;
		font-size: 13px;
		margin-bottom: 15px;
	}

	.row {
		width: 100%;
		margin-left: auto;
		margin-right: auto;
		height: 55px;
		align-items: center;
		padding-left: 25px;
		padding-right: 25px;
		background-color: #cbcbcb05;
		box-shadow: inset 0px 0.5px 0 0px #cbcbcd;
		@media screen and (max-width: 700px) {
			height: auto;
			padding-top: 10px;
			padding-bottom: 10px;
		}

		.user-social-media {
			display: flex;
			align-items: center;

			img {
				width: 24px;
				height: 25px;
			}

			p {
				padding-left: 12px;
				padding-right: 12px;
				margin-bottom: 0px;
			}
			.social-link {
				text-decoration: underline;
				cursor: pointer;
			}
			.social-image-link {
				width: 22px;
				cursor: pointer;
			}
		}
		p {
			font-size: 14.5px;
			font-weight: bold;
			letter-spacing: 0.5px;
			font-family: sans-serif;
		}
		.right-side {
			font-weight: 500;
			font-size: 13.5px;

			// span {
			// 	font-weight: 800;
			// }

			img {
				margin-left: 10px;
			}
		}
	}
	.achievements-div {
		background: #fff;
		width: 100% !important;
		margin-left: 0% !important;
		.row {
			box-shadow: none;
		}
	}

	.k-widget,
	.k-dropdown,
	.k-header,
	.location {
		background-color: transparent;
		// height: 40px !important;
		width: 300px !important;
		margin-top: 9px;
		@media screen and (max-width: 500px) {
			background-color: transparent;
			height: 27px !important;
			width: 190px !important;
		}
	}

	.k-dropdown-wrap {
		border: none !important;
	}
	.k-dropdown {
		border-radius: 4px;
		padding-left: 7px;
		padding-right: 7px;
		letter-spacing: 0.5px;
		border: 1.5px solid $dark-green;
	}

	.k-input,
	.k-multiselect-wrap,
	.k-button {
		font-size: 13.5px !important;
		font-weight: 400 !important;
	}

	.k-multiselect-wrap.k-floatwrap {
		border-top-color: $dark-green !important;
	}

	.k-multiselect-wrap {
		border: 1.5px solid $dark-green !important;
		border-radius: 4px;
		padding-left: 7px !important;
		padding-right: 7px !important;
	}

	.achievement-title {
		padding: 10px 4px 10px 20px;
		font-family: sans-serif;
		font-weight: 550;
		font-size: 20px;
	}

	.achievement-first-row {
		height: 30px;
		padding: 20px 10px 10px 20px;

		.left-side {
			font-size: 14px;
			font-weight: 500;
			letter-spacing: 0.5px;
			font-family: sans-serif;
			color: gray;
		}

		.right-side {
			@extend .left-side;
			font-weight: 600;
		}
	}
}

.user-entered-skills-div {
	color: white;
	background-color: rgb(8, 103, 136);
	width: auto;
	height: 36px;
	border-radius: 10px;
	padding: 15px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 10px;
	position: relative;
	@media screen and (max-width: 700px) {
		margin-bottom: 5px;
		max-width: 100%;
		overflow-wrap: anywhere;
	}
}

.skill-close {
	position: absolute;
	top: -6px;
	right: -5px;
	background-color: rgb(48, 55, 78);
	padding: 0px;
	border-radius: 50px;
	width: 15px;
	height: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 11px !important;
	font-family: sans-serif;
	cursor: pointer;
}

#skill-input {
	border: none;
	border-bottom: 1px solid black;
	padding: 4px;
	font-size: 15px;
	padding-bottom: 2px;
}

.flex {
	display: flex;
}

.save-preview-buttons {
	display: flex;
	justify-content: space-between;
}

.input-social-link {
	font-size: 13px;
	height: 30px;
	width: 25%;
	border: 1.5px solid $dark-green;
	padding-left: 5px;
	border-radius: 5px;
	@media screen and (max-width: 700px) {
		width: 65%;
	}
}

.other-link {
	@media screen and (max-width: 700px) {
		margin-left: 10px;
	}
}

.skills-div {
	background: #fff;
	padding: 15px;

	.skills-row {
		display: flex;
		box-shadow: none;
		padding-bottom: 25px;

		.first-skill {
			width: 100%;
		}

		.second-skill {
			width: 20%;
		}
	}
}

#skill-name {
	font-size: 9.5px;
}

.left-side-skill {
	width: 45%;
	background-color: #30374e;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 40px;
	border-top-left-radius: 7px;
	border-bottom-left-radius: 7px;
	border: solid 1px #30374e;

	p {
		padding: 8px;
		font-size: 13px;
		color: white;
		font-family: sans-serif;
		letter-spacing: 0.5px;
	}
}

.right-side-skill {
	width: 55%;
	background-color: white;
	display: flex;
	align-items: center;
	justify-content: center;
	border: solid 1px #30374e;
	border-top-right-radius: 7px;
	border-bottom-right-radius: 7px;
	height: 40px;

	p {
		padding: 8px;
		font-size: 13px;
		color: black;
		letter-spacing: 0.5px;
		font-family: sans-serif;
	}

	img {
		width: 52%;
	}
}

.center-skill {
	@extend .left-side-skill;
	width: 100%;
	border-radius: 7px;
}

.social-profile-div {
	background: #fff;
	.row {
		padding-left: 18px;
	}
}

.skills-gap {
	width: 5%;
}

// WORK EXPERIENCE CSS
.work-experience-main-div {
	background: transparent;
	margin-bottom: 40px;
}

.add-job-button-row {
	display: flex;
	justify-content: center;

	button {
		width: 100px;
	}
}

.delete-button {
	float: right;
	padding: 5px;
	cursor: pointer;
}

.work-experience-div {
	background: #fff;
	width: auto;
	box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.2);
	border-radius: 5px;
	margin-bottom: 20px;
	display: flex;
	justify-content: center;

	@media screen and (max-width: 767px) {
		margin-right: 0px !important;
		margin-left: 0px !important;
	}

	.work-div {
		width: 100%;
		margin-top: 15px;
		margin-bottom: 15px;
		margin-left: 35px;
		margin-right: 35px;
		border: 1px solid #e0e0e0;
		border-radius: 5px;
		@media screen and (max-width: 700px) {
			margin-left: 11px;
			margin-right: 11px;
		}

		.work-company-name-date {
			width: 100%;
			margin-left: auto;
			margin-right: auto;
			justify-content: space-between;
			align-items: center;
			padding: 10px 15px 3px 15px;

			p {
				color: #1c8976;
				font-size: 14px;
				font-family: sans-serif;
				@media screen and (max-width: 600px) {
					font-size: 12px !important;
				}
			}

			.work-span {
				@extend p;
				font-weight: 900;
				display: flex;
				@media screen and (max-width: 600px) {
					font-size: 12px !important;
				}
			}
		}
	}
}

.work-title {
	color: #30374e;
	font-family: sans-serif;
	font-weight: 800;
	padding-left: 15px;
	font-size: 20px;
	letter-spacing: 0.4px;
	margin-bottom: 1rem;

	span {
		color: $light-blue;
	}
}

.cover-pic-real {
	position: absolute;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	background-size: cover;
}

.work-description {
	font-family: sans-serif;
	font-style: italic;
	font-size: 14px;
	padding: 0px 0px 10px 15px;
	color: gray;
	letter-spacing: 0.5px;
	@media screen and (max-width: 500px) {
		font-size: 9px;
	}
}

.timeline--wrapper {
	margin-top: -3%;
	width: 100% !important;
	padding-right: 25% !important;
	@media screen and (max-width: 767px) {
		margin-top: 0px;
		padding-top: 0px !important;
	}
}

.date-head {
	font-family: sans-serif;
	font-size: 22px;
	letter-spacing: 0.5px;
	color: #30374e;
	font-weight: 500;
	margin-bottom: 0px;
}

.college-title {
	font-size: 13.5px;
	font-family: sans-serif;
	letter-spacing: 0.5px;
	padding-bottom: 3px;
	margin-bottom: 5px;
	@media screen and (max-width: 700px) {
		margin-top: 7px;
	}
}

.work-edit-parents-div {
	width: 100%;

	.work-edit-div {
		width: auto;
		position: relative;

		img {
			position: absolute;
			right: 5px;
			top: 5px;
			cursor: pointer;
		}
	}
}

.words-left-div {
	display: flex;
	justify-content: space-between;

	.words-left {
		font-size: 11px;
		font-style: italic;
	}
}

.company-name select {
	border-radius: 4px;
	border-left: none;
	border-color: #1c8976;
	background-color: white;
	padding: 3px;
	text-align: center;
	&:focus,
	&:active {
		outline: none !important;
	}
	box-sizing: border-box !important;
}

.overall-score-value {
	width: 85% !important;
	@media screen and (max-width: 540px) {
		width: 100% !important;
	}
}

.button-column {
	display: flex;
	justify-content: center;
}

.timeline-item-date {
	z-index: 1 !important;
}

.another-job-button {
	background-color: #26bba2;
	height: 35px;
	min-width: 150px;
	color: white;
	font-family: sans-serif;
	font-weight: bold;
	font-size: 14px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	border-radius: 7px;
	margin-bottom: 14px;
	margin-top: 5px;
	border: none;
	outline-color: #26bba2;
	display: flex;
	align-items: center;
	justify-content: center;

	img {
		width: 15px !important;
		margin: 0px;
		margin-right: 3px;
	}
}

.achievement-button {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

.work-exp-row {
	padding: 10px;
	width: 100%;
}

.work-exp-row2 {
	@extend .work-exp-row;
	margin-bottom: 20px;
}

.work-exp-row3 {
	@extend .work-exp-row;
	margin-top: -25px;
}

.company-title {
	font-size: 13.5px;
	font-family: sans-serif;
	letter-spacing: 0.5px;
	margin-bottom: 5px;
	@media screen and (max-width: 700px) {
		margin-top: 10px;
	}
}

#input-text-field {
	min-height: 38px;
	border-radius: 5px;
	border: 1px solid #1c8976;
	width: 100%;
	padding-left: 7px;
	padding-right: 7px;
	opacity: 0.9;
	letter-spacing: 0.5px;
	border-top-color: #1c8976 !important;

	&:focus,
	&:active {
		outline: none !important;
	}
}

.css-yk16xz-control {
	border: none !important;
	min-height: 47px !important;
}

.css-1pahdxg-control {
	box-shadow: none !important;
}

.css-1okebmr-indicatorSeparator {
	background-color: transparent !important;
}

.css-1gtu0rj-indicatorContainer {
	color: #1c8976 !important;
}

.css-1pahdxg-control:hover {
	border-color: transparent !important;
}

.k-form {
	padding: 0px !important;
	display: flex !important;
}

.k-form-field {
	display: flex !important;
	align-items: center;
}
.k-form-field-row {
	display: flex;
	@media screen and (max-width: 700px) {
		margin-top: 20px;
	}
}

.k-checkbox:checked {
	border-color: #26bba2 !important;
	background-color: white !important;
}

.k-checkbox {
	border: 2px solid #b6b6b6 !important;
	border-radius: 4px !important;
	margin-right: 4px !important;
}

.k-checkbox-label {
	color: black !important;
	font-weight: 400;
}

.k-checkbox:checked::before {
	color: #26bba2 !important;
	max-width: 30px !important;
	transform: scale(2) !important;
}

.k-checkbox::before {
	top: 3px !important;
	left: 5px !important;
}

.k-datepicker {
	width: 100% !important;
	border: 1px solid $dark-green !important;
	border-radius: 6px !important;
}

.k-dateinput-wrap {
	display: flex;
	align-items: center;
	padding-left: 15px !important;
}

.k-picker-wrap {
	border: none !important;
}

.css-226l3qy-menu {
	position: relative;
	z-index: 55 !important;
}

// PROFILE ACTIVITY CSS
.timeline-div {
	box-shadow: 0px 1px 6px 1px grey;
	padding: 20px;
	border-radius: 4px;
	background: #fff;
	@media screen and (max-width: 700px) {
		padding: 10px;
	}
}

.basic-information-div {
	.language-multiselect {
		width: 500px !important;
		@media screen and (max-width: 700px) {
			margin-bottom: 10px;
		}
	}
}

.mt-110 {
	margin-top: 110px;
}
.mt-170 {
	margin-top: 170px;
}

.col-img-3 {
	padding-right: 0px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.timeline-headline {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 20px;

	.timeline-headline-title {
		font-size: 16px;
		font-family: sans-serif;
		font-weight: 540;
		@media screen and (max-width: 700px) {
			font-size: 12px;
		}
		@media screen and (max-width: 320px) {
			font-size: 10px;
		}
	}

	.timeline-headline-second {
		color: #086788;
		font-style: italic;
		font-size: 15px;
	}
}

.entry .body {
	margin: 0 0 3em !important;
	float: none !important;
	color: #333 !important;
	margin-left: 33% !important;
	width: 100% !important;
}

.image-row {
	padding-left: 15px;
	flex-wrap: nowrap;

	img {
		width: 5%;
		height: 5%;
		margin-right: 20px;
	}
}

.timeline-image-row-vertical-line {
	border-left: 6px solid #30374e;
	height: 50px;
}

.timeline-image-row-content {
	font-family: sans-serif;
	color: #030303;
	font-size: 16px;
	font-style: italic;
	padding-left: 10px;
}

.activity-summary-row {
	margin-bottom: 20px;
}

.activity-card {
	border-radius: 5px;
}

.seo-card {
	border: 0.1px solid #26bba2;
	box-shadow: 0px 0px 5px 0px #26bba2;
}

.points-card {
	border: 0.1px solid #ff7c00;
	box-shadow: 0px 0px 5px 0px #ff7c00;
}

.flag-card {
	border: 0.1px solid #00d559;
	box-shadow: 0px 0px 5px 0px #00d559;
}

.right-side-card {
	padding: 12px;
}

.small-text {
	opacity: 0.7;
	font-style: italic;
	font-size: 10px;
	color: black;
	padding-bottom: 3px;
}

.card-head {
	font-family: sans-serif;
	font-weight: bold;
	font-size: 16px;
	letter-spacing: 0.5px;
	padding-bottom: 3px;
}

.card-level {
	color: #26bba2;
	font-size: 10px;
	padding-bottom: 3px;
	font-family: sans-serif;
}

.flag-level {
	color: #00d559;
}

.points-level {
	color: #ff8431;
}

// EDUCATION details

.work-complete-div {
	width: 100%;
}

.education-details-row {
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	justify-content: space-between;
	align-items: center;
	display: inline-flex;
	padding: 10px 15px 3px 15px;

	p {
		color: #086788;
		font-size: 14px;
		font-family: sans-serif;
		@media screen and (max-width: 500px) {
			font-size: 11px;
		}
	}

	.work-span {
		@extend p;
		font-weight: 900;
		color: black;
		display: flex;
		@media screen and (max-width: 600px) {
			font-size: 12px !important;
		}
	}
}

.education-title {
	font-size: 20px;
	@media screen and (max-width: 500px) {
		font-size: 13px;
	}
}

.work-complete-div {
	.courses-div {
		border: none;
		display: flex;
		justify-content: space-between;
		align-items: center;

		.course-name {
			color: gray;
			font-size: 14px;
			font-family: sans-serif;
			letter-spacing: 0.6px;
			@media screen and (max-width: 500px) {
				font-size: 9px;
			}
		}

		.course-duration {
			@extend .course-name;
			font-weight: 550;
		}
	}

	.course-details-div {
		@extend .courses-div;
		margin-top: -15px;

		.course-detail-name {
			font-weight: bolder;
			font-family: sans-serif;
			font-size: 20px;
			letter-spacing: 0.6px;
			@media screen and (max-width: 500px) {
				font-size: 14px;
			}
		}

		.course-detail-file {
			color: grey;
			font-size: 16px;
		}
	}

	.no-course-div {
		margin-top: 15px;
	}
}

.education-complete-div {
	margin-bottom: 0px;
	margin-top: 0px;
}

// PROFILE summary

.insights-row {
	margin-right: -30px;
	margin-left: -30px;
	margin-bottom: 20px;
}

.insight-card {
	height: 100%;
	min-height: 64px;
	border-radius: 9px;
	box-shadow: 0px 1px 5px 0px grey;
	position: relative;
	padding: 20px;
	text-align: center;

	img {
		position: absolute;
		top: 0;
		right: 0;
		height: 60%;
	}
}

.total-hours {
	background-color: #1c8976;

	p {
		color: white;
		font-family: sans-serif;
	}

	.hours-studied {
		font-weight: bold;
		font-size: 26px;
		margin-bottom: 3px;
	}

	.hours-head {
		font-size: 15px;
		letter-spacing: 0.5px;
	}
}

.common-card {
	background-color: white;

	p {
		color: #1c8976;
		font-family: sans-serif;
	}

	.hours-studied {
		font-weight: bold;
		font-size: 26px;
		margin-bottom: 3px;
	}

	.hours-head {
		letter-spacing: 0.5px;
		font-size: 15px;
	}
}

.score-card {
	padding-top: 13px;
	padding-bottom: 1px;
	box-shadow: 0px 0px 9px 0px #80808087;
	padding-left: 15px;
	padding-right: 15px;
	border-radius: 10px;
	background-color: white;
}

.score-row {
	margin-left: 0px;
	margin-right: 0px;
	align-items: center;
	justify-content: space-between;
	padding: 5px;

	.avg-score {
		font-family: sans-serif;
		font-weight: bold;
		font-size: 13px;
		letter-spacing: 0.5px;
	}

	.score-level {
		font-family: sans-serif;
		font-size: 10px;
		letter-spacing: 0.2px;
		color: #6a6a6a;
	}
}

.progress-bar-style-average {
	border-radius: 30px !important;
	height: 8px;

	.progress-color-average {
		background-color: #26bba2;
	}

	&.nav-progress-bar {
		height: 14px !important;
	}

	margin-bottom: 20px;
}

.progress-bar-style-common {
	border-radius: 30px !important;
	height: 8px;

	.progress-color-common {
		background-color: #15e5c2;
	}

	&.nav-progress-bar {
		height: 14px !important;
	}

	margin-bottom: 20px;
}

.badge-row {
	padding: 18px 0px 18px 6px;
	box-shadow: 0px 0px 5px 0px grey;
	border-radius: 9px;
	background-color: white;
	position: relative;
	flex-direction: column;
	.col-badge-3 {
		flex: 0 0 100%;
	}
}

.badges-burn {
	display: flex;
	align-items: center;
	justify-content: space-around;
	padding: 12px;
	background-color: #ff7c00;
	border-radius: 10px;
	height: 60px;

	img {
		height: 80%;
	}

	.badge-content {
		color: white;
		font-weight: bold;
		font-family: sans-serif;
		font-size: 18px;
	}
}

.badge-flag {
	background-color: #00d559;
}

.block {
	display: block;
	padding: 18px 0px 18px 18px;
	margin-bottom: 20px;
}

.majors-head {
	color: #086788;
	font-size: 18px;
	font-family: sans-serif;
	font-weight: bold;
	letter-spacing: 0.5px;
}

.badges-row-head {
	padding: 15px;
}

.major-badges {
	background-color: #30374e;
	padding: 8px;
	padding-left: 15px;
	padding-right: 15px;
	border-radius: 6px;
	margin-right: 10px;

	p {
		color: white;
		font-family: sans-serif;
		font-size: 12px;
		letter-spacing: 0.4px;
		text-align: center;
	}
}

.minor-head {
	color: #26bba2;
}

.minor-badges {
	background-color: #26bba2;
	margin-bottom: 10px;
}

.resume-row {
	margin-top: -20px;
	justify-content: space-between;
}

.upload-resume {
	font-size: 14px;
	font-weight: 550;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #30374e;
	font-family: sans-serif;
	padding-left: 15px;
}

.resume-buttons {
	display: flex;
}

.view-resume-button {
	color: #30374e !important;
	margin-right: 10px;
}

.add-new {
	display: flex;
	justify-content: center;
	align-items: center;
}

.add-new-certificate-button {
	@extend .another-job-button;
}

.certificate-row {
	margin-top: 20px;
}

.editing-work-div {
	background: #fff;
	width: 93% !important;
}

.right-side-edit {
	justify-content: flex-end;
	display: flex;
	align-items: center;
}

.cancel-button {
	font-style: italic;
	font-size: 13px;
	margin-right: 10px;

	span {
		font-weight: 300;
	}
}

.view-cover-letter-button {
	width: 140px !important;
	color: #30374e !important;
}

.cover-letter-div-cancel {
	display: flex;
	align-items: center;
}

.profile-url-text {
	margin-bottom: 10px;
}

.change-profile-url {
	@media screen and (max-width: 700px) {
		position: absolute;
		top: 5px;
		margin-left: 30px;
	}
	// @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
	// 	position: absolute;
	// 	top: -40px;
	// }
}

input[type="file"] {
	@media screen and (max-width: 700px) {
		font-size: 13px;
	}
}

.profile-url-input {
	border-radius: 5px;
	height: 34px;
	width: 270px;
	outline-color: #26bba2;
	@media screen and (max-width: 700px) {
		width: 230px;
	}
	@media screen and (max-width: 360px) {
		width: 200px;
	}
	@media screen and (max-width: 320px) {
		width: 155px;
	}
}

.edit-basic-button {
	border: none;
	outline-color: #086788;
	cursor: pointer;
	z-index: 2;
	img {
		height: 1rem;
	}
	@media screen and (max-width: 768px) {
		height: 30px !important;
		font-size: 14px;
	}
}

.profile-edit-div {
	background-color: rgb(48, 55, 78);
	width: 40px;
	height: 40px;
	border-radius: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 10px;
	right: -34px;
	padding: 0px;
	cursor: pointer;

	@media screen and (max-width: 768px) {
		height: 30px;
		width: 30px;
		top: 0px;
		right: -30px;
	}
}

.profile-picture-span {
	position: relative;
	z-index: 1;
	display: flex;
	align-items: center;
	margin-right: 14px;

	@media screen and (max-width: 768px) {
		position: absolute;
		top: -40%;
	}

	@media only screen and (min-device-width: 768px) and (max-device-width: 768px) and (orientation: portrait) {
		top: -43%;
	}
}

.button-flex {
	display: flex;
}

.edit-icon-img {
	width: 12px !important;
}

.profile-pic {
	border-radius: 120px;
	height: 240px;
	width: 240px;
	min-height: 240px;
	min-width: 240px;
	border: 7px solid #fff;

	@media screen and (max-width: 1440px) and (min-width: 1440px) {
		height: 200px;
		width: 200px;
		min-height: 190px;
		min-width: 190px;
	}
	@media screen and (max-width: 768px) {
		height: 125px;
		width: 125px;
		min-height: 125px;
		min-width: 125px;
		border-radius: 62.5px;
		border: 4px solid #fff;
	}
	@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
		height: 140px;
		width: 140px;
		min-height: 140px;
		min-width: 140px;
		border-radius: 140px;
	}

	@media screen and (max-width: 320px) {
		height: 110px;
		width: 110px;
		min-height: 110px;
		min-width: 110px;
	}
}

.user-skill-span {
	width: 15%;
}

.start_date_work_pref {
	font-size: 13.5px;
	font-family: sans-serif;
}

.entry {
	.title:before {
		z-index: 1 !important;
	}
}

@media screen and (max-width: 800px) {
	.navlink-tab {
		padding: 0px;
		align-items: center;
	}

	.activity-card {
		margin-bottom: 15px;
	}

	.timeline-div {
		padding-top: 45px;
		background: #fff;
	}

	.save-preview-buttons {
		justify-content: flex-end;
	}

	.work-experience-div,
	.work-edit-div {
		background: #fff;
		width: auto;
	}

	.work-complete-div {
		background: #fff;
		width: 100%;
	}

	.basic-information-div {
		background: #fff;

		.row {
			p {
				font-size: 12px;
				margin-top: 5px;
			}
		}
	}

	.social-profile-div {
		background: #fff;

		.row {
			p {
				font-size: 12px;
			}
		}
	}

	.mgb-10 {
		margin-bottom: 10px;
	}

	.score-card {
		margin-bottom: 15px;
	}

	.badges-burn {
		justify-content: space-evenly;
		margin-bottom: 10px;

		.badge-content {
			font-size: 15px;
		}
	}

	.skills-div {
		background: #fff;
		.skills-row {
			.first-skill {
				width: 100%;
			}
		}
	}
}

.no-education-div {
	width: auto !important;
	border: none !important;
	background: #fff;
}

.education-div {
	width: auto !important;
	background: #fff;
}

.courses-div {
	width: auto !important;
	background: #fff;
}

.skills-div-row {
	height: auto !important;
}

.download-cv-btn {
	background-color: rgb(48, 55, 78);
	height: 35px;
	width: auto;
	border: none;
	color: white;
	font-size: 14px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 7px;
	padding-right: 10px;
	padding-left: 10px;
	margin-right: 1rem;
	cursor: pointer;
	margin-top: 12px;

	@media screen and (max-width: 768px) {
		padding-right: 7px;
		padding-left: 7px;
		img {
			height: 13px;
		}
		.certificate-btn {
			position: absolute !important;
			top: 0px !important;
			right: 0px !important;
		}
		margin-left: 10px;
		.download-btn-text {
			font-size: 0px;
		}

		position: absolute;
		top: 0px;
		left: 0px;
	}
}
.download-certificate-btn {
	background-color: rgb(48, 55, 78);
	height: 35px;
	width: auto;
	border: none;
	color: white;
	font-size: 14px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 7px;
	padding-right: 10px;
	padding-left: 10px;
	cursor: pointer;
	margin-top: 12px;

	@media screen and (max-width: 768px) {
		padding-right: 7px;
		padding-left: 7px;
		img {
			height: 13px;
		}
		margin-right: 10px;

		.download-btn-text {
			font-size: 0px;
		}

		position: absolute;
		top: 0px;
		right: 0px;
	}

	@media screen and (max-width: 320px) {
		font-size: 13px;
		padding-right: 5px;
		padding-left: 5px;
		img {
			height: 12px;
		}
	}
}
.cvModal-header {
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	background-repeat: no-repeat;
	background-position: top;
	background-position-x: right;
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	top: 0px;
	min-height: 70px;
	height: auto;
	width: 100%;
	right: 0px;
	background-color: #30374e;
	color: white;
	font-size: 20px;
	font-weight: 700;

	.close-btn {
		position: absolute;
		right: 20px;
		top: 4px;
		cursor: pointer;
		svg {
			height: 0.8rem;
			fill: #fff;
		}
		@media screen and (max-width: 991px) {
			right: 4px;
		}
	}
}

.cvModal {
	position: absolute;
	background-color: white;
	height: auto;
	left: 540px;
	width: 78%;
	padding: 5px;
	z-index: 2;
	color: black;
	display: flex;
	flex-direction: column;
	align-items: center;
	position: absolute;
	background-color: white;
	padding-bottom: 15px;
	box-shadow: 0 5px 17px 0 rgba(48, 55, 78, 0.4);
	border-radius: 10px;

	@media screen and (max-width: 769px) {
		height: auto;
		top: 71px !important;
		left: 0px;
		padding: 5px;
		z-index: 10;
		min-height: 306px;
		width: 95%;
		margin-left: 10px;
		margin-right: 10px;
	}

	@media screen and (min-width: 769px) and (max-width: 991px) {
		width: 61%;
		top: 206px !important;
		left: 288px;
	}

	@media screen and (min-width: 992px) and (max-width: 1030px) {
		top: 200px !important;
		left: 300px !important;
	}

	& > table {
		font-size: 14px;
		margin-top: 70px;
		width: 100%;
		td {
			padding: 10px 5px;
			width: 50%;
		}
	}

	.cv-cert-modal-btn-div {
		display: flex;
		width: 100%;
		justify-content: center;
		margin-top: 20px;
		// @media screen and (max-width: 767px) {
		// 	flex-direction: column;
		// }
		& > a {
			margin: auto;
			@media screen and (max-width: 767px) {
				margin: 0px;
			}
		}
	}

	.benefit1 {
		display: table-cell;
		padding: 5px;
		text-align: start;
		width: 50%;
	}

	.benefit2 {
		display: table-cell;
		padding: 5px;
		text-align: start;
	}

	.sample {
		display: flex;
		align-items: center;
		justify-content: space-around;
		margin-top: 5px;
		font-size: 14px;
		color: rgb(104, 100, 100);
		text-decoration-line: underline;
		cursor: pointer;
		width: 100%;
	}

	.btn-cont {
		justify-content: center;
		display: flex;
		margin-top: 10px;
		flex-wrap: wrap;
		a:hover {
			text-decoration: none;
		}
	}
	.purchase {
		color: white;
		font-size: 20px;
		border-radius: 7px;
		cursor: pointer;
		background: linear-gradient(295deg, #13a0bd -12%, #086788 88%);
		width: 215px;
		padding-top: 2px;
		padding-bottom: 2px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		border: none;
		outline: none;
		box-shadow: 0 5px 17px 0 rgba(48, 55, 78, 0.3);
		transition: box-shadow 0.3s;
		margin: auto;
		overflow: hidden;
		position: relative;

		&:hover {
			box-shadow: 0 5px 17px 0 rgba(48, 55, 78, 0.6);
		}
		@media screen and (max-width: 767px) {
			flex-direction: row;
			width: 80%;
			min-height: 50px;
		}
		@media screen and (max-width: 500px) {
			font-size: 17px;
		}
	}

	.purchaseCombo {
		@extend .purchase;
		background: #26bba2;
		height: 60px;
		@media screen and (max-width: 767px) {
			margin-bottom: 12px;
			height: 50px;
		}
	}

	.cv-cert-modal-ribbon {
		font-size: 10px;
		font-weight: bold;
		color: #fff;
		text-transform: uppercase;
		text-align: center;
		line-height: 20px;
		transform: rotate(-60deg);
		-webkit-transform: rotate(-60deg);
		width: 70px;
		display: block;
		padding-left: 12px;
		background: linear-gradient(#f70505 0%, #8f0808 100%);
		box-shadow: 0 3px 10px -5px black;
		position: absolute;
		top: 19px;
		height: 20px;
		left: -23px;
	}
}

.cvOnModal {
	@extend .cvModal;
	position: absolute;
	margin: auto;
	right: 0px;
	width: 59%;
	top: 109px;

	@media screen and (max-width: 769px) {
		height: auto;
		top: 71px !important;
		left: 0px;
		padding: 5px;
		z-index: 10;
		min-height: 306px;
		width: 95%;
		margin-left: 10px;
		margin-right: 10px;
	}

	@media screen and (min-width: 769px) and (max-width: 991px) {
		width: 61%;
		top: 206px !important;
		left: 288px;
	}

	@media screen and (min-width: 992px) and (max-width: 1030px) {
		top: 200px !important;
		left: 300px !important;
	}
}
.profile-details-container {
	@media (min-width: 576px) and (max-width: 767px) {
		max-width: none !important;
	}
	@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
		max-width: 100% !important;
	}
}

.active-side-links {
	padding-bottom: 10px;
	font-family: $montserrat-bold;
	font-size: 14px;
	text-decoration: none;
	transition: 0.5s;
	color: $medium-green !important;
	position: relative;
	border-bottom: 3px solid $medium-green;
	display: flex;
	align-items: center;
	font-weight: bold;

	@media screen and (max-width: 1280px) and (min-width: 1280px) {
		font-size: 12px;
	}
	@media screen and (max-width: 1440px) and (min-width: 1440px) {
		font-size: 13px;
	}
	svg {
		path {
			fill: $medium-green !important;
		}
	}
}

.profile-tab {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 20px;
	margin-bottom: 10px;
}

.side-links {
	font-family: $montserrat-regular;
	color: black;
	font-size: 14px;
	text-decoration: none;
	transition: all 0.5s;
	display: flex;
	align-items: center;
	white-space: nowrap;
	padding: 14px 25px;

	&:hover {
		text-decoration: none;
		color: #26bba2;
		transition: 0.3s;
		svg {
			path {
				fill: $medium-green;
			}
		}
	}

	svg {
		height: 15px;
		margin-right: 6px;
		padding: 0px;
		path {
			opacity: 1;
			fill: #000;
		}
	}
	@media screen and (max-width: 1024px) and (min-width: 1024px) {
		padding: 10px 18px;
	}
	@media screen and (max-width: 1280px) and (min-width: 1280px) {
		font-size: 12px;
	}
	@media screen and (max-width: 1440px) and (min-width: 1440px) {
		font-size: 13px;
	}
	@media screen and (max-width: 900px) {
		font-size: 14px;
	}
	@media screen and (max-width: 768px) {
		font-size: 14px;
		padding: 14px 5px;
		img {
			height: 14px !important;
		}
	}
}

//free access modal

.free-access-modal-body {
	height: 550px;
	padding-bottom: 0px;
	border-radius: 10px;
	@media screen and (max-width: 500px) {
		height: auto;
	}
	.cvModal-header {
		@media screen and (max-width: 500px) {
			font-size: 14px;
			top: 45px;
		}
		@media screen and (max-width: 400px) {
			top: 15px;
		}
	}
}

.free-access-modal-head {
	font-size: 20px;
	font-family: $montserrat-regular;
	margin-top: 5px;
	margin-bottom: 25px;
	@media screen and (max-width: 500px) {
		font-size: 15px;
	}
}

.free-access-modal-heading {
	margin-top: 55px;
	font-size: 22px;
	font-family: $montserrat-bold;
	@media screen and (max-width: 500px) {
		margin-top: 120px;
		font-size: 17px;
	}
}

.free-access-modal-points-row {
	align-items: center;
	justify-content: space-between;
	margin-bottom: 5px;
}

.free-access-modal-points {
	width: 75%;
}

.free-access-modal-desc {
	font-size: 15px;
	font-family: $montserrat-regular;
	margin-top: 5px;
}

.bold-desc {
	font-family: $montserrat-bold;
}

.purchase {
	color: white;
	font-size: 20px;
	border-radius: 7px;
	cursor: pointer;
	background: linear-gradient(295deg, #13a0bd -12%, #086788 88%);
	width: 230px;
	padding: 8px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	border: none;
	outline: none;
	box-shadow: 0 5px 17px 0 rgba(48, 55, 78, 0.3);
	transition: box-shadow 0.3s;
	margin: auto;
	overflow: hidden;
	position: relative;
	margin-left: 10px;
	margin-right: 10px;

	&:hover {
		box-shadow: 0 5px 17px 0 rgba(48, 55, 78, 0.6);
	}
	@media screen and (max-width: 767px) {
		flex-direction: row;
		width: 80%;
		min-height: 50px;
	}
	@media screen and (max-width: 500px) {
		font-size: 17px;
	}
}

.purchaseCombo {
	@extend .purchase;
	background: #26bba2;
	@media screen and (max-width: 767px) {
		margin-bottom: 12px;
		height: 50px;
	}
}

.upgrade-premium-btn {
	height: 75px;
}

.cv-cert-modal-btn-div {
	display: flex;
	margin-top: 20px;
	align-items: center;
}

.cv-cert-modal-ribbon {
	font-size: 10px;
	font-weight: bold;
	color: #fff;
	text-transform: uppercase;
	text-align: center;
	line-height: 20px;
	transform: rotate(-60deg);
	-webkit-transform: rotate(-60deg);
	width: 70px;
	display: block;
	padding-left: 12px;
	background: linear-gradient(#f70505 0%, #8f0808 100%);
	box-shadow: 0 3px 10px -5px black;
	position: absolute;
	top: 19px;
	height: 20px;
	left: -23px;
}

.free-access-cls-btn {
	top: 15px !important;
	svg {
		height: 1.3rem !important;
	}
}

.basic-information-div > .basic-row > .form-control.input {
	width: fit-content;
	width: -moz-fit-content;
	max-width: 50%;
	outline: none;
	border: 1.5px solid $dark-green;
}

button > .custom-btn-svg {
	margin-right: 4px;
	width: 12px;
	max-height: 12px;
}

@import '../1-tools/variables';

h1 {
	font-family: $montserrat-regular;
	&.bold {
		font-family: $montserrat-bold;
	}
	&.xl {
		font-size: $font-size-40;
	}
	&.s {
		font-size: $font-size-32;
	}
}

h2 {
	font-family: $montserrat-regular;
	&.bold {
		font-family: $montserrat-bold;
	}
	&.xl {
		font-size: $font-size-32;
	}
	&.s {
		font-size: $font-size-24;
	}
}

h3 {
	font-family: $montserrat-regular;
	&.bold {
		font-family: $montserrat-bold;
	}
	&.xl {
		font-size: $font-size-24;
	}
	&.s {
		font-size: $font-size-16;
	}
}

h4 {
	font-family: $montserrat-regular;
	&.bold {
		font-family: $montserrat-bold;
	}
}

h5 {
	font-family: $montserrat-regular;
	&.bold {
		font-family: $montserrat-bold;
	}
}

body {
	font-family: $opensans-regular;
	&.bold {
		font-family: $opensans-bold;
	}
	&.xl {
		font-size: $font-size-16;
	}
	&.s {
		font-size: $font-size-12;
	}
}

caption {
	font-family: $opensans-italic;
	font-style: italic;

	&.bold {
		font-family: $opensans-bold;
	}
	&.xl {
		font-size: $font-size-16;
	}
	&.s {
		font-size: $font-size-8;
	}
}

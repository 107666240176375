@import "../1-tools/variables";

.topic-card-next {
	width: 100%;
	box-shadow: 0 14px 26px 0 rgba(181, 181, 181, 0.4);
	height: auto;
	border-radius: 15px;
	background-color: $dark-blue;
	position: relative;
	margin: 2% auto;
	padding-left: 1.5rem;
	overflow: hidden;
	margin-top: 30px;
	cursor: pointer;
	div,
	span,
	p {
		cursor: pointer;
	}
	.excla-icon {
		display: none;
	}
	.topic-card-svg {
		right: 0px;
		width: auto;
		height: 100%;
		position: absolute;
		right: 0px;
		border-bottom-right-radius: 15px;
	}

	.level {
		width: 66px;
		height: 22px;
		border-radius: 6px;
		background-color: #26bba2;
		margin-bottom: 0.6rem;
		margin-top: 15px;
		.level-text {
			font-family: $montserrat-regular;
			font-size: $font-size-12;
			color: $pure-white;
			line-height: 1.83;
			text-align: center;
		}
	}
	.card-level-icon {
		width: 18px;
		height: 18px;
		margin-right: 9px;
	}

	.levels-completed-next {
		display: none;
	}
	.card-topic {
		font-family: $montserrat-bold;
		font-size: $font-size-20;
		font-weight: bolder;
		letter-spacing: 0.5px;
		color: $pure-white;
		margin-bottom: 0px;
		width: 95%;
	}
	.card-description {
		font-family: $opensans-regular;
		font-size: $font-size-14;
		opacity: 0.8;
		color: $pure-white;
		margin-bottom: 15px;
	}

	.module-details-text {
		font-family: $montserrat-semi;
		font-size: 12px;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		color: #1c8976;
		position: absolute;
		margin-left: 0px !important;
		margin-right: 0px !important;
		width: 90%;
		bottom: 1rem;
	}

	.iconClock {
		width: 18px;
		height: 18px;
		margin-right: 9px;
		@media only screen and (max-width: 568px) {
			// left: 36%;
		}
	}

	.time-text {
		font-family: $montserrat-semi;
		font-size: 12px;
		color: #1c8976;
		display: flex;
		align-items: center;
		@media only screen and (max-width: 568px) {
			// left: 46%;
		}
	}
}

.topic-card-upcoming {
	.level-completed-text {
		display: flex;
		align-items: center;
		padding-left: 0px;
	}
	@extend .topic-card-next;
	// --------------------------------------------------------------
	width: 100%;
	box-shadow: none;
	min-height: 155px;
	padding: 10px 7px 10px 14px;
	background: $pure-white;
	border: solid 0.3px #1c8976;
	box-shadow: 0 12px 24px 0 #b3b5bc;

	// @include media-breakpoint-down(md) {
	// 	min-height: 169px;
	// }

	.levels-completed-upcoming {
		@include media-breakpoint-down(md) {
			margin-top: 14px;
		}
		@include media-breakpoint-down(sm) {
			margin-top: 7px;
		}
	}

	.topic-card-svg {
		width: 20%;
		height: 70%;
		right: -7px;
		z-index: 0;
		position: absolute;
		bottom: -7px;
	}
	.card-description {
		font-size: $font-size-12;
		line-height: 1.83;
		margin-top: 40px;
		margin-left: 15px;
		position: absolute;
	}

	.topic-card-Major {
		width: 54px;
		height: 24px;
		border-radius: 6px;
		background-color: #0d705e;
		margin-top: 10px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.text-Major {
		color: white;
		font-family: $montserrat-bold;
		font-size: 12px;
	}

	.topic-card-Minor {
		width: 54px;
		height: 24px;
		border-radius: 6px;
		border: solid 1px #1c8976;
		margin-top: 10px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.text-Minor {
		color: #1c8976;
		font-family: $montserrat-bold;
		font-size: 12px;
	}

	.completed-card-topic {
		color: $dark-blue;
		bottom: 37px;
		font-family: $montserrat-bold;
		font-size: 20px;
		position: absolute;
		margin-bottom: 6px;
		font-weight: 800;
		width: 92%;
		@include media-breakpoint-down(md) {
			margin-bottom: 14px;
		}
		@include media-breakpoint-down(sm) {
			margin-bottom: 0px;
		}
	}

	.topic-card-title-div {
		width: 100%;
		overflow: hidden;
		height: 80px;
		position: relative;
		.mac-tooltip {
			border-radius: 4px;
			background-color: #333;
			color: #fff;
			position: relative;
			top: -1px;
			width: max-content;
			width: -moz-max-content;
			z-index: 1000;
			padding: 4px;
			font-size: 14px;
		.mac-tooltip-arrow {
			border-color: #333;
			border-left: 10px solid transparent;
			border-right: 10px solid transparent;
			position: absolute;
			z-index: 999 !important;
			border-top-width: 10px;
			border-top-style: solid;
			bottom: -9px;
			left: 50%;
			}
		}
	}

	.mac-tooltip {
		border-radius: 4px;
		background-color: #333;
		color: #fff;
		position: relative;
		top: -2px;
		width: max-content;
		width: -moz-max-content;
		z-index: 1000;
		padding: 4px;
		font-size: 12px;
		.mac-tooltip-arrow {
			border-color: #333;
			border-left: 10px solid transparent;
			border-right: 10px solid transparent;
			position: absolute;
			z-index: 999 !important;
			border-top-width: 10px;
			border-top-style: solid;
			bottom: -9px;
			left: 50%;
			}
	}

	.card-topic {
		color: $dark-blue;
		margin-bottom: 0px;
		margin-top: 14px;
		font-family: $montserrat-bold;
		font-size: 20px;
		position: relative;
		@include media-breakpoint-up(lg) {
			height: 80px;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
		}
	}

	.card-completed {
		font-family: $montserrat-semi;
		font-size: 12px;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		color: #1c8976;
		position: absolute;
		bottom: 17px;
		display: flex;
		align-items: center;
	}

	.tickIcon {
		width: 18px;
		height: 18px;
		margin-right: 5px;
	}
	.progress-bar {
		width: 90%;
		padding: 0px;
		height: 12px;
		border-radius: 5px;
		z-index: 1;
		position: relative;
		background-color: #efefef;
		@media only screen and (max-width: 812px) {
			width: 260px;
		}
		@media only screen and (max-width: 700px) {
			width: 150px;
		}
		@media only screen and (max-width: 570px) {
			margin-top: 0px;
			width: 240px;
		}
	}

	.progress {
		height: 12px;
		border-radius: 5px;
		background-color: #055245;
	}
	.excla-icon {
		position: absolute;
		right: 10px;
		top: 10px;
		display: block;
		height: 1rem;
		@media only screen and (max-width: 1024px) {
			display: none;
		}
	}
}

.topic-card-upcoming.completed {
	border: none;
	box-shadow: none;
}

.topic-card-locked {
	box-shadow: 0 14px 26px 0 rgba(181, 181, 181, 0.4);
	height: 155px;
	border-radius: 10px;
	background-color: #3e514d;
	width: 100%;
	margin-top: 30px;
	cursor: pointer;
	div,
	span,
	p {
		cursor: pointer;
	}
	// @include media-breakpoint-down(md) {
	// 	height: 169px;
	// }

	.excla-icon {
		display: none;
	}
	.card-description {
		display: none;
	}
	.topic-card-svg {
		display: none;
	}
	.card-topic {
		display: none;
	}

	.locked-topic-card {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		height: 100%;
		width: 100%;
	}

	.topic-card-title-div {
		width: 90%;
		overflow: hidden;
		height: 70px;
		margin-top: 10px;
		display: flex;
		justify-content: center;
	}
	.topic-lock {
		font-family: $montserrat-semi;
		font-size: 20px;
		text-align: center;
		color: #ffffff;
		width: 90%;
		margin-top: 10px;
		margin-bottom: 7px;
		@include media-breakpoint-up(lg) {
			margin: 0px;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
		}
		@include media-breakpoint-down(md) {
			font-size: 19px;
		}
	}
	.text {
		font-family: $montserrat-semi;
		font-size: 11px;
		position: relative;
		color: #ffffff;
		text-align: center;
		margin-top: 3px;
	}

	.unlock {
		font-family: $montserrat-regular;
		font-size: 10px;
		font-style: italic;
		text-align: center;
		color: #ffffff;
	}
	.lock {
		width: 10px;
		height: 13px;
	}
}

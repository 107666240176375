.onboarding-container {
	overflow-x: hidden;
	height: 100vh;
	overflow-y: auto;
	padding-top: 0px;
	@include media-breakpoint-down(sm) {
		padding-bottom: 30px;
	}
}

.signup-header {
	display: flex;
	padding: 14px;
	align-items: center;

	.icon-career-ninja {
		width: 120px;
		height: 50px;
		@include media-breakpoint-down(sm) {
			margin-left: 30%;
		}
	}

	.icon-onboarding-logout {
		position: relative;
		display: flex;
		margin-left: auto;
		padding: 10px;
		cursor: pointer;
		svg {
			width: 24px;
			height: 24px;
		}
	}

	@include media-breakpoint-down(md) {
		border-bottom: 1px solid #333333;
		margin-left: 20px;
		margin-right: 20px;
		padding: 12px 0px;
		justify-content: center;
	}
}

.onboarding-header-container {
	border-bottom: 1px solid $very-dark-gray-two;

	.onboarding-header {
		.mascot-image {
			width: 100px;
			height: 160px;
		}

		.main-header {
			font-family: $montserrat-bold;
			font-size: $font-size-34;
		}

		.sub-header {
			color: #4d5367;
			font-size: $font-size-22;
			line-height: 34px;
		}
	}
}

.invalid-feedback {
	display: unset !important;
}

.form-group {
	margin-bottom: 0 !important;
}

.react-tel-input {
	transition: all 0.5s;
	width: 80% !important;

	@media screen and (max-width: 768px) {
		width: 75% !important;	
	}
}

.form-control, .DayPickerInput {
	// z-index: -1!important;
	width: 100% !important;
}

select.form-control {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

.form-control:read-only,
.form-control:disabled {
	background-color: #fff !important;
}

.form-control.verified {
	background-color: $lightest-green !important;
	border-color: $dark-green;
}

.DayPickerInput-Overlay {
	bottom: 45px !important;
	right: 0px !important;
	left: unset !important;
}

.onboarding-steps-style-container {
	padding: 20px 100px 20px;

	// position: relative;
	@include media-breakpoint-down(lg) {
		padding: 20px;
	}

	.onboarding-subheader {
		font-family: $montserrat-semi;
		font-size: $font-size-26;
		color: #30364e;

		@include media-breakpoint-down(lg) {
			font-size: 20px;
		}
	}

	.card-onboarding {
		position: relative;
		padding: 10px;
		border-radius: 12px;
		width: 240px;
		height: 260px;
		border: 1px solid #25bba2;
		background-color: #ffffff;
		margin: 10px 20px;
		box-shadow: 10px 12px 31px 13px rgba(191, 191, 191, 0.4);
		// &:hover {
		// 	box-shadow: 0px 0px 0px 0px #25bba2;
		// }

		@include media-breakpoint-down(sm) {
			width: -webkit-fill-available;
			height: auto;
			padding: 10px;
			margin: 0 0 20px 0;
		}

		.icons-card {
			height: 140px;
			width: 200px;

			@include media-breakpoint-down(lg) {
				width: 100px;
				height: auto;
				margin-right: 10px;
				margin-bottom: 0px;
			}
		}

		.card-main-text {
			font-size: 18px;
			font-family: $montserrat-semi;
			@include media-breakpoint-down(lg) {
				font-size: 16px;
			}
			@include media-breakpoint-up(md) {
				text-align: center;
			}
		}

		.card-sub-text {
			font-size: 12px;
			padding: 0 4px;
			@include media-breakpoint-down(lg) {
				padding: 0;
			}
			@include media-breakpoint-up(md) {
				text-align: center;
			}
		}
	}

	.simple-card {
		@extend .card-onboarding;
		transition: 0.5s;
		cursor: pointer;
		display: flex;

		@include media-breakpoint-up(md) {
			flex-direction: column;
			align-items: center;
		}

		@media screen and (max-width: 767px) {
			height: auto;
		}
		& > picture {
			display: contents;
		}
	}

	.simple-card:hover {
		@extend .card-onboarding;
		// height: 222px;
		box-shadow: 15px 15px 40px 15px rgba(191, 191, 191, 0.8);
		border: 1px solid #25bba2;

		@media screen and (max-width: 767px) {
			height: auto;
		}
	}

	.error {
		border: 2px solid #ff0000;
	}

	.onboarding-interest {
		.interest-card {
			position: relative;
			width: 350px;
			background-color: #ffffff;
			padding: 20px 15px;
			border: 1px solid transparent;
			margin-bottom: 22px;
			border-radius: 12px;
			cursor: pointer;
			transition: 0.5s;
			border-radius: 12px;
			border: 1px solid #25bba2;
			// box-shadow: 0px 3px 0px 0px #25bba2;
			box-shadow: 10px 12px 31px 13px rgba(191, 191, 191, 0.4);
			// &:hover {
			// 	box-shadow: 0px 0px 0px 0px #25bba2;
			// }

			@include media-breakpoint-down(lg) {
				width: -webkit-fill-available;
			}
		}

		.interest-card.interest-card-active {
			// box-shadow: 0 5px 20px 5px rgba(191, 191, 191, 0.4);
			box-shadow: 0px 3px 1px 1px #25bba2 !important;
			width: 350px;
			border: 1px solid #25bba2;
			background-color: #ffffff;
			border-radius: 12px;
			padding: 20px 15px;
			transition: 0.5s;
			margin-bottom: 22px;

			@include media-breakpoint-down(lg) {
				width: -webkit-fill-available;
			}

			.icon-tick-interest-container {
				position: absolute;
				transition: 0.5s;
				top: 7px;
				right: 10px;

				@include media-breakpoint-down(lg) {
					right: 10px;
					top: 5px;
				}

				.icon-green-tick {
					height: 18px;
					width: 18px;
					transition: 0.5s;

					@include media-breakpoint-down(lg) {
						height: 14px;
						width: 14px;
					}
				}
			}
		}

		.interest-tag-container {
			position: absolute;
			transition: 0.5s;
			right: 15px;
			bottom: 17px;

			.tag {
				background-color: #ffa26b;
				padding: 3px 10px;
				border-radius: 30px;
				color: #933a05;
				font-size: 11px;
				font-family: $opensans-bold;
				transition: 0.5s;
				position: relative;
				top: 11px;
			}
		}

		.main-text {
			font-size: 18px;
			color: #011726;
		}

		.sub-text {
			font-size: 10px;
			color: #4d5367;
		}

		.interest-icon {
			width: 40px;
			height: 40px;
		}
	}

	// redundunt css

	.active-card {
		// padding-top: 10px;
		border-radius: 12px;
		width: 240px;
		// box-shadow: 0 5px 20px 5px rgba(191, 191, 191, 0.4) !important;
		border: 1px solid #25bba2 !important;
		background-color: #ffffff;
		box-shadow: 0px 3px 1px 1px #25bba2 !important;

		@include media-breakpoint-down(sm) {
			width: -webkit-fill-available;
			height: auto;
			padding: 10px;
			margin: 0 0 20px 0;
		}

		.icons-card {
			height: 140px;
			width: 200px;

			@include media-breakpoint-down(lg) {
				width: 100px;
				height: auto;
				margin-right: 10px;
				margin-bottom: 0px;
			}
		}

		.card-main-text {
			font-size: 18px;
			font-family: $montserrat-semi;

			@include media-breakpoint-down(lg) {
				font-size: 16px;
			}
		}

		.card-sub-text {
			font-size: 12px;

			@include media-breakpoint-down(lg) {
				padding: 0;
			}
		}
	}

	.step-two-text-container {
		// height: 280px;
		// overflow-y: auto;
		font-size: 20px;
		color: #4d5367;
		line-height: 40px;
		margin-bottom: 14%;
	}

	.onboarding-three {
		margin-bottom: 14%;
	}

	.icon-user-status {
		width: 160px;
		height: 120px;

		@include media-breakpoint-down(sm) {
			width: 100px;
			height: auto;
			margin-bottom: 0px;
			margin-right: 10px;
		}
	}
}

.onboarding-footer {
	height: $footer-height;
	padding: 20px;
	background-image: linear-gradient(to right, #4addfa, #25bba2 54%, #4bcc68);

	@include media-breakpoint-down(md) {
		padding: 10px;
	}

	.onboarding-link-btn {
		color: white;
		text-decoration: none;
		font-family: $montserrat-semi;
		font-weight: 500;
		cursor: pointer;
	}

	.onboarding-btn {
		background-color: #148682;
		color: #fff;
		font-family: $montserrat-bold;
		border-radius: 15px;
		border: 3px solid #fff;
		box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.3);
		outline: 0;
		font-size: 16px;

		@include media-breakpoint-down(lg) {
			font-size: 14px;
		}

		&:disabled {
			background-color: #e8e8e8;
			color: #888;
			box-shadow: none;
		}
	}

	.disabled {
		background-color: #e8e8e8;
		color: #888;
		box-shadow: none;
	}

	.icon-back {
		height: 14px;
		width: 20px;

		@include media-breakpoint-down(md) {
			height: 18px;
			width: 26px;
			margin-left: 20px;
		}
	}
}

.onboarding-btn-container {
	@include media-breakpoint-down(lg) {
		padding-right: 40px !important;
	}
}

// profile-details
.profile-details-container {
	.profile-icon {
		height: 250px;
		width: 300px;

		@include media-breakpoint-down(lg) {
			height: 200px;
			width: 250px;
		}
	}

	.profile-title {
		font-size: 26px;
		line-height: 30px;
		color: #30364e;

		@include media-breakpoint-down(lg) {
			font-size: 20px;
		}
	}

	.textbox-bottom-text {
		font-size: 11px;
		color: #999;
	}

	.link-btn-blue {
		color: #0880db;
		font-size: 12px;
	}
}

.footer-signup {
	font-size: 14px;
	color: #ccc;
}

.dot-container {
	// @include media-breakpoint-down(lg) {
	// 	position: absolute;
	// }
	.dot-cricle {
		height: 10px;
		width: 10px;
		border-radius: 50%;
		border: 1px solid #1c8976;

		@include media-breakpoint-down(lg) {
			height: 8px;
			width: 8px;
		}
	}

	.dot-circle-fill {
		background-color: #1c8976;
	}
}

.icon-tick-container {
	position: absolute;
	transition: 0.5s;
	right: 14px;

	@include media-breakpoint-down(lg) {
		right: 14px;
		font-size: 12px;
	}

	.icon-green-tick {
		height: 18px;
		width: 18px;
		transition: 0.5s;

		@include media-breakpoint-down(lg) {
			height: 14px;
			width: 14px;
		}
	}
}

.work-preference-tip-container {
	background: #23a28d;
	color: white;
	padding: 2px 10px;
	border-radius: 13px;

	@include media-breakpoint-down(lg) {
		right: 14px;
		font-size: 12px;
		background: #23a28d;
		color: white;
		padding: 2px 10px;
		border-radius: 13px;
	}
}

.onboarding-selector {
	align-self: start;

	input {
		width: 18px;
		height: 18px;
		box-shadow: 0px 0px 4px 0px #25bba2;
	}

	.error {
		box-shadow: 0px 0px 4px 0px #ff0000;
	}

	@media screen and (max-width: 768px) {
		margin-right: 10px;
	}
}

.overview-title {
	font-family: $lato-semibold;
	font-size: 26px;
	letter-spacing: 2.36px;
	color: #30364e;
}

.blog-card {
	width: 375px;
	border-radius: 4px;
	transition: 0.5s;

	@include media-breakpoint-down(lg) {
		margin-bottom: 20px;
	}

	img {
		border-top-right-radius: 4px;
		border-top-left-radius: 4px;
	}

	.blog-image {
		width: 375px;
		height: 200px;
	}

	.card-blog-title {
		font-size: 18px;
		font-family: $montserrat-bold;
		color: #30364e;
		margin-bottom: 10px;
	}

	.card-blog-subtitle {
		font-size: 16px;
		line-height: 30px;
		color: #4d5367;
		margin-bottom: 10px;
	}
}

.blog-card:hover {
	@extend .blog-card;
	transition: 0.5s;
	box-shadow: 0 0 2px 2px rgba(191, 191, 191, 0.4);
}

.company-logo-container {
	background-color: #e1f5f2;

	.company-logo-one {
		width: 125px;
		height: 50px;

		@include media-breakpoint-down(lg) {
			width: 75px;
			height: 50px;
		}
	}

	.company-logo-two {
		width: 180px;
		height: 70px;

		@include media-breakpoint-down(lg) {
			width: 110px;
			height: 50px;
		}
	}

	.company-logo-three {
		width: 190px;
		height: 70px;

		@include media-breakpoint-down(lg) {
			width: 115px;
			height: 50px;
		}
	}

	.company-logo-four {
		width: 170px;
		height: 70px;

		@include media-breakpoint-down(lg) {
			width: 115px;
			height: 50px;
		}
	}

	.company-logo-five {
		width: 175px;
		height: 70px;

		@include media-breakpoint-down(lg) {
			width: 105px;
			height: 50px;
		}
	}

	.company-logo-six {
		width: 215px;
		height: 70px;

		@include media-breakpoint-down(lg) {
			width: 125px;
			height: 50px;
		}
	}

	.company-logo-seven {
		width: 210px;
		height: 70px;

		@include media-breakpoint-down(lg) {
			width: 125px;
			height: 50px;
		}
	}

	.company-logo-eight {
		width: 180px;
		height: 70px;

		@include media-breakpoint-down(lg) {
			width: 110px;
			height: 50px;
		}
	}

	.company-logo-nine {
		width: 215px;
		height: 70px;

		@include media-breakpoint-down(lg) {
			width: 130px;
			height: 50px;
		}
	}
}

.overview-skill-card {
	border-radius: 4px;
	box-shadow: 0 0 2px 2px rgba(191, 191, 191, 0.4);
	// height: 150px;
	margin-bottom: 20px;
	max-width: 50vw;

	@include media-breakpoint-down(lg) {
		max-width: unset;
	}

	.card-header-text {
		font-size: 20px;
		font-family: $lato-semibold;
		color: #30364e;

		@include media-breakpoint-down(lg) {
		}
	}

	.icon-tick {
		width: 18px;
		height: 18px;
	}

	.text-des {
		color: #30364e;
		font-size: 16px;
		line-height: 60px;
	}

	.part-one {
		width: 25%;
		background-color: #e1f5f2;
		clip-path: polygon(0 0, 100% 0%, 84% 100%, 0% 100%);

		@include media-breakpoint-down(lg) {
			clip-path: polygon(0 1%, 100% 0%, 100% 94%, 0 65%);
			width: unset;
		}
	}

	.part-two {
		width: 30%;

		@include media-breakpoint-down(lg) {
			width: unset;
		}
	}
}

.overview-newbie-icon {
	// height: auto;
	height: auto;
	width: auto;

	@include media-breakpoint-down(lg) {
		height: auto;
		width: 90vw;
	}
}

.overview-main-image {
	height: 100vh;
	width: 85vw;

	@include media-breakpoint-down(lg) {
		height: unset;
	}
}

.icon-wave {
	height: 180px;
	width: 100vw;

	@include media-breakpoint-down(lg) {
		height: unset;
		width: 100vw;
	}
}

.icon-wave-two {
	height: 206px;
	width: 100vw;
}

// .profile-details-container-style {
// 	margin-bottom: 100px;
// }

//modal onboarding changes

.onboarding-footer-modal {
	width: 100%;
	height: auto;
	display: flex;
	justify-content: center;
	align-items: center;
	left: 0px;
	bottom: 0px;
	position: absolute;
	padding: 14px;

	// & > .onboarding-btn{}

	@include media-breakpoint-down(sm) {
		height: 10vh;
	}

	&.third {
		bottom: 150px;
		@include media-breakpoint-down(sm) {
			bottom: 0px;
		}
	}
}

.onboarding-work-exp-container {
	padding: 20px 350px 0px;

	@media screen and (max-width: 767px) {
		padding: 20px 70px 0px;
	}
}

#work-pref-card {
	width: 400px;

	.icons-card {
		width: 100%;
	}
}

.selected-tip {
	font-size: 12px;
	margin-bottom: 1px;
}

// .onboarding-work-pref-container {
// 	padding: 20px 350px 20px;

// 	@media screen and (max-width: 767px) {
// 		padding: 20px;
// 	}
// }

.work-preference-img {
	width: 45%;

	@media screen and (max-width: 767px) {
		width: 25%;
		height: 25%;
		margin-right: 10px;
	}
}

.react-tel-input .form-control.invalid-number {
	border-color: #cacaca !important;
	background-color: #fff !important;
}

.send-otp-button {
	font-size: 12px;
	font-weight: 900;
	font-family: $montserrat-bold;
	color: #fff;
	position: absolute;
	top: 0px;
	z-index: 1;
	right: 2px;
	cursor: pointer;
	letter-spacing: 0.5px;
	border: none;
	background: $dark-green;
	transition: all 0.3s;
	// opacity: 0;
	// pointer-events: none;
	border-radius: 6px;
	padding: 8px;

	&:disabled {
		background-color: #e8e8e8;
		color: #888;
		box-shadow: none;
	}
}

.mobile-verified-tick {
	position: absolute;
	top: 20%;
	z-index: 1;
	right: 25px;
	max-height: 1.1rem;
	max-width: 1.1rem;
}
// .react-date-picker__wrapper {
// 	width: 100% !important;
// 	border-color: #cacaca !important;

// 	&:read-only,
// 	&:disabled {
// 		background-color: #fff !important;
// 	}

// 	.react-date-picker__button__icon {
// 		stroke: #cacaca !important;
// 	}
// }

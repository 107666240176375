// Authentication Style

.authentication-container {
	overflow-y: hidden;
	@include media-breakpoint-down(lg) {
		overflow-y: auto;
	}
	.icon-career-ninja {
		width: 120px;
		height: $header-height;
		@include media-breakpoint-down(lg) {
			margin-bottom: 40px;
		}
	}
	.main-title {
		font-size: 34px;
		font-family: $opensans-bold;
		@include media-breakpoint-down(lg) {
			font-size: 20px;
			margin-bottom: 15px;
		}
	}
	.part-one {
		width: 50%;
		background-image: url("/img/auth-image.svg");
		background-color: #cccccc;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		height: 100vh;
		padding: 40px 50px 20px;
		color: #086788;
		.main-text {
			font-family: $opensans-bold;
			font-size: 22px;
		}
		.sub-text {
			font-size: 16px;
			line-height: 24px;
		}
	}
	.login-part-one {
		@extend .part-one;
		display: flex;
		flex-basis: 50;
		background-image: url("/img/login-image.svg");
	}
	.part-two {
		width: 50%;
		height: 100vh;
		@include media-breakpoint-down(md) {
			width: 100%;
			padding: 40px 20px 0 20px;
			height: unset;
		}
	}
	.sub-title {
		line-height: 34px;
		color: #4d5367;
		border-bottom: 1px solid #4d5367;
		@include media-breakpoint-down(lg) {
			font-size: 16px;
			line-height: 24px;
			text-align: center;
			padding-bottom: 30px;
		}
	}
	.icon-signup {
		height: 24px;
		width: 24px;
	}
	.onboarding-btn {
		background: white;
		border: none;
		// box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.3);
		box-shadow: 0 0 12px 1px rgba(0, 0, 0, 0.2);
		padding: 15px 20px;
		border-radius: 4px;
		width: 260px;
		font-family: $lato-semibold;
	}
	.link-signup-container {
		@include media-breakpoint-down(lg) {
			margin-top: 50px;
		}
	}

	.link-signup {
		color: #1c8976;
		font-family: $opensans-bold;
		// text-decoration: none;
		font-size: 14px;
		letter-spacing: -0.05px;
	}
	.link-combine {
		@extend .link-signup;
		color: #999999;
	}
}
.career-ninja-footer-text {
	font-size: 14px;
	color: #ccc;
	position: absolute;
	bottom: 10px;
}

.google-btn {
	border-radius: 6px;
	box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.2) !important;
	transition: all 0.2s;
	&:hover {
		box-shadow: 0 0px 15px 3px rgba(0, 0, 0, 0.2) !important;
	}
	&:focus {
		outline: none;
	}
	& > div,
	& > div > div {
		cursor: pointer;
	}
}

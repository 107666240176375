* {
	margin: 0;
	padding: 0;
}

p,
div,
span,
h1,
h2,
h3,
h4,
h5,
h6 {
	cursor: default;
}

button > span,
button > div,
button > p {
	cursor: pointer;
}

body {
	// font-family: $lato-regular;
	font-size: 18px;

	//v1
	overflow: hidden;
	height: auto;
	@media screen and (max-width: 991.98px) {
		overflow-y: auto;
	}

	//v2.1
	// overflow-y: hidden;
	//Supported only by Firefox
	scrollbar-width: thin;
}

.modal::-webkit-scrollbar {
	display: none;
}

.page-wrap {
	overflow-y: scroll;
	padding-bottom: 80px;
	// padding-top: 100px;
	scrollbar-color: dimgray white;
	@include media-breakpoint-down(md) {
		padding-top: 0px;
	}
}

//Firefox fix for bottom padding on pages
@-moz-document url-prefix() {
	.page-wrap > :last-child {
		margin-bottom: 150px;
	}
}

.page-wrap::-webkit-scrollbar {
	width: 8px;
	@include media-breakpoint-down(md) {
		display: none;
	}
}

.page-wrap::-webkit-scrollbar-track {
	background-color: transparent;
	// box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.page-wrap::-webkit-scrollbar-thumb {
	background-color: rgba(#000, 0.4);
	border-radius: 4px;
	outline: none;
}

a,
a:hover {
	text-decoration: none !important;
}

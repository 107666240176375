// @auther Dipesh sharma
// label
.form-label {
	font-size: 12px;
	color: #999999;
	position: relative;
	top: 17px;
	left: 7px;
	background-color: white;
	padding-left: 5px;
	padding-right: 5px;
	transition: 0.2s;
	transition-delay: 0.2s;
	font-weight: 600;
	z-index: 1;
}

// placeholder
::placeholder {
	font-size: 14px;
	color: #ccc !important;
}

//form-control
.form-control {
	background-color: #fff;
	border: 1px solid #cccccc;
	transition: 0.2s;
	transition-delay: 0.2s;
	font-size: 14px !important;
	&:focus,
	&:active {
		border: 1px solid $dark-cyan;
		box-shadow: unset;
	}
	&:hover {
		// box-shadow: 0 0 3px 4px rgba($color: $dark-cyan, $alpha: 0.2);
		border: 1px solid $dark-cyan;
	}
}

.form-control:disabled,
.form-control[readonly] {
	color: #999999;
}

.is-invalid {
	color: #dc3545;
}

// label color change
.form-group:focus-within,
.form-group:hover {
	.form-label {
		color: $dark-cyan;
		font-weight: 600;
	}
}

.form-control:disabled,
.form-control[readonly] {
	background-color: #fff;
}

// textarea
textarea {
	resize: none;
}

// Radio Buttons
/* The container */
.radio-container {
	display: block;
	position: relative;
	padding-left: 26px;
	margin: 6px 0;
	cursor: pointer;
	font-size: 14px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	color: #999999;
	transition: 0.2s;
	transition-delay: 0.2s;
}

/* Hide the browser's default radio button */
.radio-container input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	transition: 0.2s;
	transition-delay: 0.2s;
}

/* Create a custom radio button */
.radiomark {
	position: absolute;
	top: 0;
	left: 0;
	height: 18px;
	width: 18px;
	background-color: #fff;
	border: 2px solid #b1b3b8;
	border-radius: 50%;
	margin-top: 2px;
	transition: 0.2s;
	transition-delay: 0.2s;
}
.radiomark:hover {
	border: 2px solid #25bba2;
}

/* When the radio button is checked, add a blue background */
.radio-container input:checked ~ .radiomark {
	background-color: #fff;
	border: 2px solid #25bba2;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.radiomark:after {
	content: "";
	position: absolute;
	display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio-container input:checked ~ .radiomark:after {
	display: block;
}

/* Style the indicator (dot/circle) */
.radio-container .radiomark:after {
	top: 1px;
	left: 1px;
	width: 12px;
	height: 12px;
	border-radius: 50%;
	background: #25bba2;
}

// remove arrow from num field
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type="number"] {
	-moz-appearance: textfield;
}

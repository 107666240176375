// career-ninja Color Guide

// lime shades
$light-grayish-lime-green: #e6f8ea;
$soft-lime-green: #8cde9e;
$moderate-lime-green: #4bcc68;
$lime-green: #00d559;

// cyan shades
$very-pale-cyan: #f0fcff;
$light-grayish-cyan: #e1f5f2;
$very-soft-cyan: #9cecfc;
$bright-cyan: #4addfa;
$slightly-desaturated-cyan: #74d3c3;
$vivid-cyan: #16e5c2;
$strong-cyan: #25bba2;
$dark-cyan: #1c8976;

// blue shades
$dark-blue: #086788;
$very-dark-grayish-blue: #4d5367;
$very-light-desaturated-blue: #30364e;
$very-dark-desaturated-blue: #1d253d;
$very-dark-blue: #011726;

// gray shades
$very-light-gray-one: #fafafa;
$very-light-gray-two: #e5e5e5;
$light-gray: #cccccc;
$dark-gray: #999999;
$very-dark-gray-one: #666666;
$very-dark-gray-two: #333333;

// message show shades
$warning-color: #f2cd00;
$danger-color: #db3d15;
$information-color: #0880db;
$success-color: #5faf18;

// fonts Guide

$font-size-34: 34px;
$font-size-30: 30px;
$font-size-26: 26px;
$font-size-22: 22px;
$font-size-18: 18px;
$font-size-14: 14px;

$lato-regular: 'Lato-Regular';
$lato-light: 'Lato-Light';
$lato-medium: 'Lato-Medium';
$lato-semibold: 'Lato-Semibold';
$lato-bold: 'Lato-Bold';

$header-height: 50px;
$footer-height : 10vh;


// payment links css

#overlay {
    position: fixed; /* Sit on top of the page content */
    width: 100%; /* Full width (cover the whole page) */
    height: 100%; /* Full height (cover the whole page) */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.8); /* Black background with opacity */
    z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
    cursor: pointer; /* Add a pointer on hover */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    p {
        margin-bottom : 20px;
        color: white;
        font-family: sans-serif;
        // font-weight: 550;
        font-size: 30px;
    }
  }

.overlay-input {
    display: flex;
    align-items: center;
    justify-content: center;
}

.input-text-box {
    width: 450px;
    height: 60px;
    border-radius: 10px;
    padding-left: 10px;
    font-size: 25px;
}

.email-btn {

}

.link-page-container {
    height: 100vh;
    background-color: #E1F5F2;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
}

.link-inner-container {
    height: 93%;
    width: 95%;
    position: relative;
}

.details-container {
    height: 85%;
    background-image: linear-gradient(#16E0C9, #027F7A);;
    @media screen and (max-width: 320px) {
        height: 100%;
    }
}

.logo-img {
    position: absolute;
    top: 25px;
    left: 25px;
    width: 200px;
    @media screen and (max-width: 550px) {
        top: 0px;
        left: 0px;
        width: 120px;
        position: relative;
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: -50px;
        padding-top: 17px;
    }
}

.payment-details {
    height: 100%;
    align-items: flex-end;
    justify-content: center;
}

.image-column-side {
    display: flex;
    justify-content: flex-start;
    img {
        width: 100%;
    }
    @media screen and (max-width: 550px) {
        display: none;
    }
}

.details-col {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    @media screen and (max-width: 550px) { 
        justify-content: center;
    }
}

.details-div {
    border: 5px solid white;
    height: 75%;
    width: 80%;
    padding: 20px;
    box-shadow: 4px 6px 4px 3px #027f7a;
    @media screen and (max-width: 550px) {
        width: 90%;
        padding: 10px;
        height: 75%;
    }
}

.purchase-container {
    height: 10%;
    background-color: white;
    border-top: 1px solid white;
    margin-top: -1px;
    border-bottom: 1px solid #3A8976;
    @media screen and (max-width: 550px) {
        border-top: none;
    }
}
.purchase-row {
    height: 100%;
    align-items: center;
    @media screen and (max-width: 550px) {
        height: 10%;
    }
}

.purchase-right {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: 2px solid #3A8976;
}

.purchase-btn {
    text-transform: uppercase;
    background-color: #3A8976;
    color: white;
    letter-spacing: 0.5px;
    font-size: 30px;
    font-weight: 650;
    height: 100%;
    width: 100%;
    border: 1px solid #3A8976;
    font-family: sans-serif;
    box-shadow: 1px 6px 14px 0px black;
    margin-top: -2px;
    margin-left: -2px;
    background-image: linear-gradient(to right, #54CB2F, #1C8E0F);
    @media screen and (max-width: 550px) {
        font-size: 15px;
        height: 50px;
    }
    @media screen and (max-width: 550px) {
        font-size: 13px;
    }
}

.details-div-head {
    text-align: center;
    text-transform: uppercase;
    font-size: 32px;
    font-weight: 550;
    font-family: sans-serif;
    letter-spacing: 0.5px;
    margin-bottom: 0px;
    @media screen and (max-width: 550px) {
        font-size: 20px;
    }
}

.head-underline {
    width: 60%;
    // border: 1px solid black;
    border: 0.5px solid rgba(0, 0, 0, 0.2);
    margin: 0px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.details-div-plan-name {
    margin-top: 10px;
    font-size: 25px;
    font-weight: 500;
    font-family: sans-serif;
    letter-spacing: 0.5px;
    text-align: center;
    text-transform: uppercase;
    @media screen and (max-width: 550px) {
        font-size: 14px;
    }
}

.plan-details {
    margin-top: 25px;
    @media screen and (max-width: 550px) {
        margin-top: 15px;
        height: 210px;
        overflow-y: scroll;
    }
}

.plan-details-points {
    display: flex;
    justify-items: center;
    margin-bottom: 10px;
    font-size: 30px;
    letter-spacing: 0.5px;
    @media screen and (max-width: 550px) {
        text-align: center;
        margin-bottom: 10px;
    }
    p {
        @media screen and (max-width: 550px) {
            font-size: 15px;
        }
    }
}

.purchase-money {
    font-size: 40px;
    font-family: sans-serif;
    font-weight: 800;
    text-align: center;
    color: #3A8976;
    @media screen and (max-width: 550px) {
        font-size: 20px;
    }
}

.plan-text {
    text-align: center;
    font-size: 26px;
    color: black;
    font-family: sans-serif;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 550;
    @media screen and (max-width: 550px) {
        font-size: 13px;
        text-decoration: double;
    }
    @media screen and (max-width: 550px) {
        font-size: 11px;
    }
    hr {
        @media screen and (max-width: 550px) {
            margin-top: 0.5rem;
            margin-bottom: 0.5rem;
        } 
    }
}

.cross-btn {
    position: absolute;
    top: 10px;
    right: 20px;
    font-size: 60px !important;
    color: white !important;
    font-weight: bold !important;
    cursor: pointer !important;
}

.installment-text {
    font-size: 20px;
    color: #3A8976;
}

.tick-mark {
    width: 30px;
    height: 40px;
    padding-right: 5px;
}

.terms-condition-container {
    position: absolute;
    bottom: 0px;
    height: 5%;
    background-color: white;
    width: 100%;
}

.terms-text {
    text-align: center;
    color: #3A8976;
    font-size: 20px;
    span {
        font-family: sans-serif;
        font-weight: 550;
    }
}

.plan-name-mobile {
    font-size: 13px;
    text-transform: uppercase;
}

.email-btn {  
    font-size: 25px;
    width: auto;
    margin-left: 15px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 10px;
}


// DEFAULT CARD STYILING

.card-component {
	// min-height: 110px;
	margin-left: auto;
	margin-right: auto;
	position: relative;
	align-items: center;
	display: flex;
	transition: all 0.4s;
	// background: rgba(193, 193, 193, 0.5);
	// backdrop-filter: blur(20px);

	&:hover {
		border-radius: 15px;
		box-shadow: 0 10px 10px 10px rgba(70, 69, 69, 0.2) !important;
	}

	//   DEFAULT STYLES
	.card-video-div {
		cursor: pointer;
		p {
			visibility: hidden;
			padding-left: 1rem;
			position: absolute;
			bottom: 0.5rem;
		}
		.content-card-video-image {
			background-color: $dark-blue;
			position: absolute;
			top: 0px;
			left: 0px;
			object-fit: cover;
		}
		.topic-completed-card-div {
			position: absolute;
			width: 100%;
			height: 100%;
			border-radius: 8px;
			border: none;
			background: rgba(#525252, 0.7);
			padding-left: 1rem;
			vertical-align: bottom;
			align-items: flex-end;
			color: $pure-white;
			display: none;

			.topic-completed-card-text {
				font-size: $font-size-22;
				font-family: $montserrat-bold;
				font-weight: 600;
				display: flex;
				align-items: center;
				margin-bottom: 0.8rem;
				svg {
					height: 1.6rem;
				}
			}
		}
	}

	//   DEFAULT STYLES
	.card-content {
		cursor: pointer;
		h4,
		div,
		span,
		p {
			cursor: pointer;
		}
		.next-content-card-vector-right {
			position: absolute;
			height: 3.5rem;
			top: 0px;
			right: 0px;
		}
		.card-option-icon-div {
			height: auto;
			width: auto;
			margin-right: 4px;
			z-index: 1;
			position: relative;
		}
		.card-save-icon-div {
			height: auto;
			width: auto;
			z-index: 1;
			position: relative;
		}
		.card-share-icon-div {
			height: auto;
			width: auto;
			z-index: 1;
			position: relative;
		}
		svg {
			height: 1rem;

			&.card-option-icon {
				float: right;
				height: 1.2rem;
				padding: 0px 10px;
				cursor: pointer;
			}
			&.card-save-icon {
				float: right;
				height: 1.3rem;
				cursor: pointer;
			}
			&.card-share-icon {
				height: 1.3rem;
				float: right;
				cursor: pointer;
			}
		}
		p {
			margin-bottom: 0rem;
			font-family: $montserrat-regular;
		}
		& .card-video-time-details {
			vertical-align: middle;
			display: flex;
			align-items: center;
			svg {
				margin-right: 0.4rem;
				height: 1.1rem;
				width: 1.1rem;
			}

			.time-text {
				font-family: $montserrat-regular;
				font-size: 13px;
				letter-spacing: 1px;
			}
			&.bottom {
				display: none;
			}
		}
		.card-topic {
			line-height: 1.4;
			font-size: 20px;
			font-weight: 900;
			// color: $dark-blue;
			color: $pure-white;
			font-family: $montserrat-bold;
			margin-top: 7px;
			width: 85%;
			// height: 26px;
			// text-overflow: ellipsis;
			// overflow: hidden;
			// white-space: nowrap;
		}
		.card-description {
			width: 90%;
			text-align: justify;
			font-size: 12px;
		}
		.content-card-elapsed-time-div {
			display: inline-flex;
			justify-content: center;
			align-items: center;
			color: $pure-white;
			font-family: $montserrat-regular;
			font-size: 12px;
			padding: 4px 7px;
			margin-left: 7px;
			border-radius: 5px;
			background-image: linear-gradient(83deg, #ff9433 20%, #e67338 107%);
			.elapsed-time {
				font-family: $montserrat-bold;
			}
		}
		.mark-as-complete-div {
			font-family: $montserrat-bold;
			font-size: 12px;
			display: inline-flex;
			justify-content: center;
			align-items: center;
			// color: $dark-blue;
			margin-left: 14px;
			cursor: pointer;
			transition: all 0.2s;
			.mark-as-complete-icon {
				margin-right: 3px;
				height: 12px;
				path {
					fill: $pure-white;
					stroke: $pure-black;
				}
			}
			&:hover,
			&:active {
				background: rgba(255, 255, 250, 0.2);
				box-shadow: 0.5px 0.5px 4px rgba(250, 250, 250, 0.6);
				border-radius: 2px;
			}
		}
		.network__share-button {
			padding: 5px !important;
			svg {
				height: 26px;
				width: 26px;
				border-radius: 5px;
				display: flex;
				align-items: center;
				justify-content: center;
				// svg {
				height: 1.8rem;
				// }
			}
		}
		.share-modal {
			position: absolute;
			background-color: white;
			height: 40px;
			box-shadow: 0px 1px 4px 0px grey;
			right: 8%;
			bottom: 14%;
			border-radius: 6px;
			z-index: 10;
			display: flex;
			align-items: center;
			transition: 500ms ease-in-out;
			justify-content: space-between;
		}
		.share-modal.next {
			right: 4.5%;
			bottom: 18%;
		}
	}

	// options div
	.content-card-options-div {
		display: flex;
		position: absolute;
		height: 98.8%;
		width: 99.9%;
		justify-content: flex-end;
		z-index: 0;
		border-radius: 15px;
		border: none;
		// background-color: $dark-blue;
		transition: transform 0.5s ease-out;
		transition: opacity 0.4s linear;
		opacity: 0;

		&.show {
			opacity: 1;
		}

		.option-menu-item {
			float: right;
			width: 10%;
			display: flex;
			align-items: center;
			justify-content: center;
			svg {
				height: 2.2rem;
				cursor: pointer;
			}

			.did-not-understand {
				display: flex;
				align-items: center;
				justify-content: center;
				background-color: #535f81;
				transition: all 0.2s;
				cursor: pointer;
				flex-basis: 50%;
				width: 100%;
				color: $pure-white;
				svg {
					path {
						fill: #fff;
					}
				}
			}

			.disabled {
				display: flex;
				align-items: center;
				justify-content: center;
				background-color: dimgray;
				transition: all 0.2s;
				cursor: pointer;
				flex-basis: 50%;
				width: 100%;
				color: $pure-white;
				svg {
					margin: auto;
					path {
						fill: #fff;
					}
				}
			}
			.disabled:hover {
				background-color: dimgray;
			}

			.bookmark {
				display: flex;
				align-items: center;
				justify-content: center;
				background-color: #30374e;
				transition: all 0.2s;
				cursor: pointer;
				flex-basis: 100%;
				border-top-right-radius: 15px;
				border-bottom-right-radius: 15px;
				width: 100%;
			}
			.mac-tooltip {
				border-radius: 4px;
				background-color: #333;
				color: #fff;
				position: absolute;
				top: 0px;
				margin-left: 50px;
				width: max-content;
				width: -moz-max-content;
				z-index: 1000;
				padding: 4px;
				font-size: 14px;
				.mac-tooltip-arrow {
					border-color: #333;
					border-left: 10px solid transparent;
					border-right: 10px solid transparent;
					position: absolute;
					z-index: 999;
					border-top-width: 10px;
					border-top-style: solid;
					bottom: -9px;
					left: 50%;
				}
				.mac-tooltip-arrow-up {
					border-color: #333;
					border-left: 10px solid transparent;
					border-right: 10px solid transparent;
					position: absolute;
					z-index: 999;
					border-bottom-width: 10px;
					border-bottom-style: solid;
					top: -9px;
					right: 50%;
				}
			}

			.did-not-understand > .mac-tooltip {
				top: -20px;
				margin-left: 10px;
			}

			.discussion > .mac-tooltip {
				top: 138px;
				margin-left: 15px;
			}
		}

		.option-menu-item {
			flex-direction: column;
			align-items: auto;
			justify-content: auto;
		}

		.mark-as-complete {
			display: flex;
			align-items: center;
			justify-content: center;
			// background-color: #30374e;
			background-color: #3f4862;
			transition: all 0.2s;
			cursor: pointer;
			flex-basis: 100%;
			width: 100%;
			border-top-right-radius: 15px;
			border-bottom-right-radius: 15px;
		}

		.discussion {
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: #495471;
			transition: all 0.2s;
			cursor: pointer;
			flex-basis: 50%;
			width: 100%;
		}

		.discussion-icon {
			margin-top: 0.3rem;
			width: 34px;
		}

		.mark-as-complete:hover {
			background-color: #2cbba2;
		}
		.bookmark:hover,
		.discussion:hover,
		.did-not-understand:hover {
			background-color: rgba(#e8e8e8, 0.5);
		}
		@media (max-width: 600px) {
			.bookmark:hover,
			.discussion:hover,
			.did-not-understand:hover {
				background-color: #535f81 !important;
			}
		}
		.option-menu-item:focus {
			outline: none;
		}
		.option-menu-item:focus,
		.option-menu-item:active,
		.option-menu-item:visited,
		.bookmark:focus,
		.did-not-understand:focus,
		.bookmark:active,
		.did-not-understand:active,
		.bookmark:visited,
		.did-not-understand:visited {
			background-color: $darkest-green;
		}

		// .options-mark-as-complete-icon {
		// 	g {
		// 		path:nth-child(2) {
		// 			fill: #1b1e23;
		// 		}
		// 		path:nth-child(3) {
		// 			fill: #1b1e23;
		// 		}
		// 	}
		// }
	}

	.card-wrapper {
		height: 100%;
		width: 100%;
		display: flex;
		position: relative;
		align-items: center;
		z-index: 1;
		border-radius: 15px;
		// transition: transform 0.5s ease-in-out;
		// transform: translateX(0%);
		&.show {
			transform: translate(-20%);
		}
	}
}

// ===============  DEFAULT CARD STYLING END ==================

// UPCOMING CARD SPECIFIC STYILING START ---

.card-component.upcoming {
	margin-top: 80px;
	margin-bottom: 40px;

	.next-content-card-vector-left {
		display: none;
	}

	// VIDEO DIVISION FOR UPCOMING CARD
	.card-video-div {
		position: absolute;
		width: 26%;
		border-radius: 8px;
		height: 120%;
		overflow: hidden;
		z-index: 1;
		box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.2);
		background: url("/img/image-loader.gif");
		background-color: $dark-blue;
		background-position: center;

		.content-card-video-image {
			border-radius: 8px;
		}

		p {
			visibility: hidden;
			padding-left: 1rem;
			position: absolute;
			bottom: 0.5rem;
			display: flex;
			align-items: center;
			color: $pure-white;
		}

		svg {
			height: 1rem;
			margin-right: 0.4rem;
			path {
				stroke: $white;
			}
		}
	}

	// CONTENT DIVISION FOR UPCOMING CARD
	.card-content {
		border-radius: 15px;
		padding-top: 1rem;
		overflow: hidden;
		padding-left: 24%;
		margin-left: 4%;
		width: 96%;
		min-height: 135px;
		background-color: rgba(226, 255, 245, 0.31);
		backdrop-filter: blur(20px);
		border: none;
		color: $pure-white;
		box-shadow: $content-card-default-shadow;
		padding-bottom: 0.4rem;

		&::after {
			content: "";
			position: absolute;
			z-index: -1;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			border-radius: 15px;
			border: 1.5px solid transparent;
			background: radial-gradient(rgba(#f9f9f9, 0.5) 33%, rgba(#ffffff, 0.5) 100%);
			-webkit-mask: radial-gradient(#f9f9f9 33%, #ffffff 100%) content-box,
				radial-gradient(#f9f9f9 33%, #ffffff 100%);
			-webkit-mask-composite: destination-out;
			mask-composite: exclude;
		}

		svg {
			path {
				height: 1rem;
				stroke: $pure-white;
			}
		}

		// .card-save-icon-div,
		// .card-share-icon-div {
		// 	margin-top: 26px;
		// }

		.card-option-icon-div {
			margin-right: 7px;
			svg path {
				stroke: none;
				fill: #fff;
			}
		}

		.card-share-icon {
			margin-right: 14px;
			path {
				stroke: none;
				fill: #fff;
			}
		}

		.card-save-icon {
			margin-right: 14px;
		}

		.next-content-card-vector-right {
			z-index: 0;
			top: 0px;
			border-top-right-radius: 15px;
			circle {
				fill: $medium-blue;
			}
		}

		.card-description {
			font-size: $font-size-12;
			margin-bottom: 5px;
			width: 87%;
			@include media-breakpoint-down(lg) {
				width: 83%;
			}
		}
	}

	// CONTENT DIVISION FOR ONGOING CONTENT CARD
	.card-content.ongoing {
		border: solid 2px #1c8976;

		.card-video-time-details.top {
			display: flex;
		}

		// .card-save-icon-div,
		// .card-share-icon-div {
		// 	margin-top: 0px;
		// }
	}
}

// UPCOMING COMPLETED STAGE CARD STYLING
.card-component.upcoming.completed {
	// height: 100px;
	margin-top: 50px;
	margin-bottom: 30px;

	&:hover {
		border-radius: 15px;
		box-shadow: 0 5px 5px 5px rgba(70, 69, 69, 0.2) !important;
	}

	// VIDEO DIVISION FOR UPCOMING COMPLETED STAGE CARD
	.card-video-div {
		height: 100%;
		filter: blur(0.5px);
		box-shadow: none;
		border-radius: 15px;
		.topic-completed-card-div {
			display: flex;
		}
	}

	// CONTENT DIVISION FOR UPCOMING COMPLETED STAGE CARD
	.card-content {
		padding-top: 0px;
		border-top-left-radius: 0px;
		border-bottom-left-radius: 0px;
		min-height: 100px;
		box-shadow: none;
		border: solid 1px #8b9c9a;
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding-bottom: 0px;

		.card-topic {
			font-size: 22px;
		}
		.next-content-card-vector-right {
			display: none;
		}

		.card-video-time-details.top {
			margin-top: 10px;
			.time-text {
				font-size: 14px;
				font-family: $montserrat-semi;
				font-weight: 500;
			}
		}
		.card-description {
			display: none;
		}
		.card-option-icon-div {
			position: absolute;
			right: 14px;
			svg {
				height: 1.2rem;
			}
		}

		.card-save-icon-div {
			display: none;
		}

		.card-share-icon-div {
			position: absolute;
			right: 36px;
			margin-top: 0px;
			svg {
				height: 1.2rem;
			}
		}

		.card-share-icon {
			@include media-breakpoint-up(md) {
				margin-right: 24px;
			}
		}
	}

	.content-card-options-div {
		.option-menu-item > .mark-as-complete {
			background-color: $darkest-green;
			cursor: not-allowed;
			pointer-events: none;
		}
		.option-menu-item > .mark-as-complete.skipped {
			background-color: $darkest-green;
			cursor: pointer;
			pointer-events: auto;
		}
		.option-menu-item:hover {
			background-color: rgba(#e8e8e8, 0.5) !important;
		}
		.option-menu-item > .did-not-understand,
		.disabled {
			font-size: 32px;
			font-weight: 600;
		}
	}
}

// UPCOMING CARD SPECIFIC STYILING END ---

// LOCKED CARD STYLES
.card-component.upcoming.locked {
	@extend .card-component.upcoming.completed;
	&:hover {
		box-shadow: 0 4px 4px 4px rgba(70, 69, 69, 0.2) !important;
	}
	.card-video-div,
	.card-content {
		cursor: not-allowed;
		p,
		span,
		div,
		h4 {
			cursor: not-allowed;
		}
	}
}

// NEXT CARD SPECIFIC STYLING START
.card-component.next {
	height: 160px;
	color: $white;
	background-color: $dark-blue;
	border-radius: 15px;
	box-shadow: $shadow-default-4;

	.card-wrapper {
		background-color: $dark-blue;
		overflow: hidden;
		z-index: auto;
	}

	.next-content-card-vector-left {
		position: absolute;
		height: 3rem;
		bottom: 0px;
		left: 22%;
		z-index: 1;
	}

	// VIDEO DIVISION FOR NEXT CARD
	.card-video-div {
		position: relative;
		flex-basis: 27%;
		border-radius: 11%/50%;
		margin-right: 3%;
		border-bottom-left-radius: 15px;
		border-top-left-radius: 15px;
		height: 100%;

		.content-card-video-image {
			border-radius: 11%/50%;
			border-bottom-left-radius: 15px;
			border-top-left-radius: 15px;
		}

		&::after {
			content: "";
			display: flex;
			width: 100%;
			height: 100%;
			background: rgba($dark-blue, 0.6);
			border-bottom-left-radius: 15px;
			border-top-left-radius: 15px;
			position: absolute;
		}

		svg {
			path {
				stroke: #fff;
			}
		}
	}

	// CONTENT DIVISION FOR NEXT CARD
	.card-content {
		z-index: 1;
		flex-basis: 70%;
		width: 100%;
		padding: 0.5rem;
		height: 100%;
		display: flex;
		flex-direction: column;

		.card-video-time-details.top > .mark-as-complete-div {
			color: $pure-white;
			svg {
				path {
					fill: #fff;
					stroke: #000;
				}
			}
			&:hover,
			&:active {
				background: rgba(255, 255, 255, 0.2);
				box-shadow: 0.5px 0.5px 4px rgba(255, 255, 255, 0.6);
				border-radius: 2px;
			}
		}

		svg {
			height: 1.2rem;
			path {
				stroke: $white;
			}
		}

		.next-content-card-vector-right {
			height: 3.5rem;
		}

		.card-video-time-details.top > .video-icon {
			g > g {
				fill: none;
			}
		}

		.card-share-icon-div {
			margin: auto 7px 12px 0px;
			.card-share-icon > path {
				fill: #fff;
				stroke: transparent;
			}
		}

		.network__share-button > svg {
			height: 26px;
			width: 26px;
		}

		.card-option-icon-div {
			margin-top: 10px;
			margin-right: 0px;
			.card-option-icon {
				path {
					fill: #fff;
				}
			}
		}
		.card-save-icon {
			margin-right: 12px;
			width: 1.2rem;
		}

		.card-topic {
			color: $pure-white;
		}
	}
}

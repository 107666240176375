.bookmarks-container {
    @include page-base-layout;
    padding-top: 100px;
    background: linear-gradient(91.71deg, #042b41, 1.24%, #0e6d88, 65.61%, #2f6983 98.55%);
    height: 100vh;
    color: $pure-white;
    min-height: 100vh;

    hr {
        background-color: $dark-grey;
    }

    @include media-breakpoint-down(md) {
        padding-top: 120px;
    }

    &.pricepopupOn {
        @include media-breakpoint-down(md) {
            padding-top: 160px !important;
        }
    }
}

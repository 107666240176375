@each $size in (10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100, 150) {
	.m-#{$size} {
		margin: #{$size}px;
	}
	.mt-#{$size} {
		margin-top: #{$size}px;
	}
	.mb-#{$size} {
		margin-bottom: #{$size}px;
	}
	.ml-#{$size} {
		margin-left: #{$size}px;
	}
	.mr-#{$size} {
		margin-right: #{$size}px;
	}
	.mlr-#{$size} {
		margin-left: #{$size}px;
		margin-right: #{$size}px;
	}
	.mtb-#{$size} {
		margin-top: #{$size}px;
		margin-bottom: #{$size}px;
	}
	.p-#{$size} {
		padding: #{$size}px;
	}
	.pt-#{$size} {
		padding-top: #{$size}px;
	}
	.pb-#{$size} {
		padding-bottom: #{$size}px;
	}
	.pl-#{$size} {
		padding-left: #{$size}px;
	}
	.pr-#{$size} {
		padding-right: #{$size}px;
	}
	.plr-#{$size} {
		padding-left: #{$size}px;
		padding-right: #{$size}px;
	}
	.ptb-#{$size} {
		padding-top: #{$size}px;
		padding-bottom: #{$size}px;
	}
}

.h-100 {
	height: 100%;
}

.clickable {
	cursor: pointer;
}

.calculateViewHeight {
	height: calc(100vh - #{$header-height + 30px} - #{$footer-height});
}

.font-size-16 {
	font-size: 16px;
}

// .container-fluid{
// 	padding: 0px;
// 	margin: 0px;
// }

.common-main-title {
	font-size: 22px;
	font-family: $opensans-bold;
	color: #30364e;
}

.font-size-mini {
	font-size: 14px;
	color: #4d5367;
	font-family: $opensans-bold;
}

.max-width-1000 {
	max-width: 1000px;
}

.font-size-mini-light {
	font-size: 14px;
	line-height: 20px;
	color: #999;
}
